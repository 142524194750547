import React from 'react';

import { Text, useTheme } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';

import { useConversationMembers } from '../../hooks';
import { ManageMembersButton } from './ManageMembersButton';

import { themedStyles } from './styles';

export type ConversationMembersProps = {
  canInviteUsers?: boolean;
};

export const ConversationMembers: React.FC<ConversationMembersProps> = props => {
  const [styles] = useTheme(themedStyles);
  const { channel } = useChatContext();
  const members = useConversationMembers(channel);

  return (
    <>
      {members.map(member => {
        let { label } = member;

        if (member.isMe) {
          label = 'you';
        } else if (member.isPatient) {
          label = 'Patient';
        }

        return (
          <Text key={member.id} style={[styles.text, styles.textField]}>
            {member.name} ({label})
          </Text>
        );
      })}
      {props.canInviteUsers && <ManageMembersButton />}
    </>
  );
};

import React, { useState } from 'react';

import { useTheme } from '@almond/ui';

import { ModalListPreview } from '~/modules/documents';
import { ListModal } from '~/modules/ui';

import { useDocuments } from '../../hooks';
import { ConfirmDeleteModal } from '../ConfirmDeleteModal';

import { themedStyles } from './styles';

import type { ExtendedDocumentOut } from '~/modules/documents/types';
import type { ModalProps } from '~/modules/ui';

export type HealthRecordsModalProps = Pick<ModalProps, 'isVisible' | 'onRequestClose'> & {
  patientUuid: string | null;
  items: readonly ExtendedDocumentOut[];
  onOpenPreview: (item: ExtendedDocumentOut) => void;
};

export const HealthRecordsModal: React.FC<HealthRecordsModalProps> = props => {
  const { patientUuid } = props;
  const [styles] = useTheme(themedStyles);
  const [documentToDelete, setDocumentToDelete] = useState<ExtendedDocumentOut | null>(null);
  const { deleteDocument, isDeleting, documentDeleteError } = useDocuments(patientUuid, 'health_record');

  const onConfirmDelete = async (document: ExtendedDocumentOut) => {
    if (!deleteDocument) return;

    await deleteDocument(document);
  };

  if (documentToDelete) {
    return (
      <ConfirmDeleteModal
        error={documentDeleteError}
        document={documentToDelete}
        isDeleting={isDeleting}
        onConfirmDelete={onConfirmDelete}
        onCancel={() => setDocumentToDelete(null)}
      />
    );
  }

  return (
    <ListModal
      {...props}
      testID="HealthRecordsModal"
      title="Health Records"
      keyProp="uuid"
      scrollViewStyle={styles.items}
      items={props.items}
      renderItem={item => (
        <ModalListPreview
          onOpenPreview={props.onOpenPreview}
          patientUuid={patientUuid}
          item={item}
          onDelete={document => setDocumentToDelete(document)}
          displayUploadDate
          displayUploaderName
          testID={item.filename}
        />
      )}
    />
  );
};

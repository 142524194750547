import { useEffect } from 'react';

import { useUnreadMessages } from '../components/UnreadMessagesProvider';

import type { Channel } from 'stream-chat';

// As soon as a new provider is added to a channel, then we mark this channel as unread.
// Then, the backend sends an `notification.mark_as_unread` event which isn't handled by stream-chat by default.
// As a result, when this conversation is opened by a provider,
// then stream-chat doesn't mark is as read automatically.
// That's why we need to handle it manually.
export const useMarkAsReadForProvider = (channel: Channel | undefined) => {
  const { unreadMessagesMap, deleteUnreadMessages } = useUnreadMessages();

  useEffect(() => {
    if (!channel?.id || !unreadMessagesMap[channel.id] || channel.state.membership.user?.role !== 'provider') return;

    const markAsReadProvider = async () => {
      if (!channel?.id) return;

      await channel.markRead({});
      deleteUnreadMessages(channel.id);
    };

    markAsReadProvider();
  }, [channel, deleteUnreadMessages, unreadMessagesMap]);
};

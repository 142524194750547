import { useState } from 'react';
import { View } from 'react-native';

import { NoticeModal, useTheme } from '@almond/ui';

import { LocalImage } from '~/modules/ui';

import { themedStyles } from './styles';

import type { ExtendedDocumentOut } from '~/modules/documents/types';

export type ConfirmDeleteModalProps = {
  document: ExtendedDocumentOut;
  isDeleting: boolean;
  error: any;
  onConfirmDelete: (document: ExtendedDocumentOut) => Promise<void>;
  onCancel: () => void;
};

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = (props: ConfirmDeleteModalProps) => {
  const { onConfirmDelete, onCancel, document, isDeleting, error } = props;
  const [isDeleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [styles] = useTheme(themedStyles);
  const image = (
    <View style={styles.modalImage}>
      <LocalImage resizeMode="contain" height="100%" width="100%" source="universe3" />
    </View>
  );

  const onDelete = async () => {
    await onConfirmDelete(document);
    setDeleteSuccess(true);
  };

  const primaryButton = (() => {
    if (isDeleteSuccess) return 'Done';

    return 'Yes, delete';
  })();

  if (error) {
    return (
      <NoticeModal
        isVisible
        image={image}
        title="There was an issue deleting this file"
        subtitle="Please try again. If the issue persists, please contact engineering to get more help."
        primaryButtonText="Done"
        onPrimaryPress={onCancel}
        onRequestClose={onCancel}
      />
    );
  }

  return (
    <NoticeModal
      isVisible
      style={isDeleteSuccess ? styles.confirmModal : null}
      image={image}
      title={isDeleteSuccess ? 'Document deleted!' : 'Delete this document?'}
      subtitle={isDeleteSuccess ? undefined : "This action will remove this document from the patient's portal."}
      primaryButtonText={primaryButton}
      secondaryButtonText={isDeleteSuccess ? undefined : 'Cancel'}
      onPrimaryPress={isDeleteSuccess ? onCancel : onDelete}
      onSecondaryPress={onCancel}
      onRequestClose={onCancel}
      isLoading={isDeleting}
      testID="ConfirmDeleteModal"
    />
  );
};

import React, { useState } from 'react';

import { Searchbar, useTheme } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';
import useSWR from 'swr';

import { ListModal } from '~/modules/ui';

import { useMarkAsUnread } from '../../hooks';
import { getChannelPatient, sortUsersComparator } from '../../utils';
import { UserItem } from './UserItem';

import { themedStyles } from './styles';

import type { ModalProps } from '~/modules/ui';
import type { Channel } from 'stream-chat';

export type ManageMembersModalProps = Pick<ModalProps, 'isVisible' | 'onRequestClose'> & {
  channel: Channel;
  streamId: string;
};

export const ManageMembersModal: React.FC<ManageMembersModalProps> = props => {
  const [styles] = useTheme(themedStyles);
  const { channel, streamId, ...restProps } = props;
  const { client } = useChatContext();
  const [filter, setFilter] = useState('');
  const markAsUnread = useMarkAsUnread(channel);
  const { data, isLoading, error, mutate } = useSWR(props.isVisible ? ['users', filter] : null, async () => {
    const filterLowercased = filter.toLowerCase();

    const filterConditions = (() => {
      const defaultFilter = { role: { $in: ['careguide', 'provider'] } };

      if (filterLowercased) return { ...defaultFilter, name: { $autocomplete: filterLowercased } };

      return defaultFilter;
    })();

    const usersResponse = await client.queryUsers(
      filterConditions,
      { last_active: -1 },
      // TODO: Implement pagination in the future if we need it.
      { limit: 100 }
    );

    // We don't fetch any patients when querying users, so it should be added to the array with users.
    const patientUser = getChannelPatient(channel);
    const users = [...usersResponse.users];

    if (users.length && patientUser) users.unshift(patientUser);

    return users.sort((a, b) => sortUsersComparator(a, b, streamId));
  });

  const handleTextChange = (text: string) => {
    setFilter(text);
  };

  return (
    <ListModal
      {...restProps}
      title="Manage Members"
      testID="ManageMembersModal"
      result={{ data, isLoading, error }}
      renderItem={user => (
        <UserItem
          user={user}
          channel={channel}
          onMembersChange={async args => {
            if (args.userToMarkAsUnread) {
              await markAsUnread.markAsUnread([args.userToMarkAsUnread]);
            }

            mutate();
          }}
          streamId={streamId}
        />
      )}
    >
      <Searchbar placeholder="Search by name" onChangeText={handleTextChange} containerStyle={styles.searchbar} />
    </ListModal>
  );
};

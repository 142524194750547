import React from 'react';
import { View } from 'react-native';

import { useBrowserTypeMap, useTheme } from '@almond/ui';
import { Slot } from 'expo-router';

import { ChannelList } from '~/modules/messaging';
import { useIsOnChannelListPage } from '~/modules/routing';

import { themedStyles } from './styles';

const ConversationsLayout: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const { isDesktop } = useBrowserTypeMap();
  const isOnChannelListPage = useIsOnChannelListPage();

  const leftSidebarStyle = (() => {
    if (isDesktop) {
      return undefined;
    }

    if (!isOnChannelListPage) return styles.leftSidebarMobileHidden;

    return styles.leftSidebarMobile;
  })();

  return (
    <View style={styles.container}>
      <View style={[styles.leftSidebar, leftSidebarStyle, isDesktop && styles.higherZIndex]}>
        <ChannelList />
      </View>
      <Slot />
    </View>
  );
};

export default ConversationsLayout;

import { createContext, useCallback, useContext, useState } from 'react';
import { View } from 'react-native';

import { NoticeModal, useTheme } from '@almond/ui';
import { useTimeout } from '@almond/utils';
import { useChatContext } from 'stream-chat-react';

import { logErrorToSentry } from '~/modules/logging';
import { LocalImage } from '~/modules/ui';

import { useDocuments } from '../../hooks';
import { getChannelPatient } from '../../utils';

import { themedStyles } from './styles';

import type { CustomStreamAttachment } from '~/modules/documents/types';

type Message = {
  id: string;
  attachments?: CustomStreamAttachment[];
};

export type DeleteMessageContextType = {
  (message: Message): void;
};

const DeleteMessageContext = createContext<DeleteMessageContextType | null>(null);

export const useDeleteMessage = () => useContext(DeleteMessageContext);

type Status = { id: 'idle' | 'loading' | 'success' } | { id: 'error'; error: string };

export const DeleteMessageProvider = (props: React.PropsWithChildren) => {
  const { children } = props;
  const [styles] = useTheme(themedStyles);
  const { client, channel } = useChatContext();
  const [setTimeout] = useTimeout();

  const patientUuid = getChannelPatient(channel)?.patient_uuid as string | undefined;

  const [messageToDelete, setMessageToDelete] = useState<Message | null>(null);
  const { deleteDocument: deleteHealthRecordDocument } = useDocuments(patientUuid, 'health_record');
  const { deleteDocument: deleteMediaDocument } = useDocuments(patientUuid, 'media');
  const [status, setStatus] = useState<Status>({ id: 'idle' });
  const image = (
    <View style={styles.modalImage}>
      <LocalImage resizeMode="contain" height="100%" width="100%" source="universe3" />
    </View>
  );
  const anyAttachmentsWithoutId = messageToDelete?.attachments?.some(a => !a.almondDocumentId);
  const deleteConfirmationMessage = anyAttachmentsWithoutId
    ? // eslint-disable-next-line max-len
      'This action will delete this message from the conversation. NOTE: any attached documents WILL NOT be deleted from from Health Records and/or Shared Media'
    : // eslint-disable-next-line max-len
      'This action will delete this message and any attached files from the conversation (as well as from Health Records and/or Shared Media).';

  const confirmDeleteMessage = async () => {
    if (!messageToDelete || !deleteHealthRecordDocument || !deleteMediaDocument) return;
    setStatus({ id: 'loading' });

    try {
      await Promise.all(
        messageToDelete.attachments?.map(attachment => {
          if (!attachment.almondDocumentId) {
            return;
          }

          const documentToDelete = {
            uuid: attachment.almondDocumentId,
            category: attachment.isAlmondHealthRecord ? 'health_record' : 'media',
          } as const;

          return attachment.isAlmondHealthRecord
            ? deleteHealthRecordDocument(documentToDelete)
            : deleteMediaDocument(documentToDelete);
        }) || []
      );
      await client.deleteMessage(messageToDelete.id);
      setStatus({ id: 'success' });
    } catch (e) {
      const error = 'There was an error deleting the message';

      setStatus({ id: 'error', error });
      logErrorToSentry(error, {
        messageId: messageToDelete.id,
        attachments: JSON.stringify(messageToDelete.attachments?.map(a => ({ ...a, fallback: undefined }))),
      });
    }
  };

  const value = useCallback(
    (message: Message) => {
      setMessageToDelete(message);

      // Prevent a flash of the un-submitted modal as it closes
      if (message === null) {
        setTimeout(() => {
          setStatus({ id: 'idle' });
        }, 500);
      }
    },
    [setTimeout]
  );

  return (
    <DeleteMessageContext.Provider value={value}>
      {children}

      <NoticeModal
        image={image}
        isLoading={status.id === 'loading'}
        error={status.id === 'error' ? status.error : undefined}
        isVisible={!!messageToDelete}
        title={status.id === 'success' ? 'Message deleted!' : 'Delete this message?'}
        subtitle={status.id === 'success' ? undefined : deleteConfirmationMessage}
        primaryButtonText={status.id === 'success' ? 'Done' : 'Yes, delete'}
        secondaryButtonText={status.id === 'success' ? undefined : 'Cancel'}
        onPrimaryPress={() => {
          if (status.id === 'success') {
            setMessageToDelete(null);
            setStatus({ id: 'idle' });
          } else {
            confirmDeleteMessage();
          }
        }}
        onSecondaryPress={() => setMessageToDelete(null)}
        onRequestClose={() => setMessageToDelete(null)}
      />
    </DeleteMessageContext.Provider>
  );
};

import React from 'react';

import { Button } from '@almond/ui';

import type { ButtonProps } from '@almond/ui';

export type SidebarButtonProps = Omit<ButtonProps, 'mode' | 'size' | 'fixedWidth'>;

export const SidebarButton = React.forwardRef<typeof Button, SidebarButtonProps>((props, _) => {
  const { children, ...restProps } = props;

  return (
    <Button {...restProps} size="s" fixedWidth={false}>
      {props.children}
    </Button>
  );
});

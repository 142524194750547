import React from 'react';
import { Pressable, View } from 'react-native';

import { MaterialIcon, sizes, Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { MaterialIconProps, TextProps } from '@almond/ui';
import type { StyleProp, TextStyle, ViewStyle } from 'react-native';

export type BottomSheetAction = {
  icon?: MaterialIconProps['source'];
  isIconCrossed?: boolean;
  isIconTilted?: boolean;
  title: string;
  onPress: () => void;
  isDisabled?: boolean;
};

export type ActionProps = BottomSheetAction &
  Pick<TextProps, 'fontStyle'> & { style?: StyleProp<ViewStyle>; textStyle?: StyleProp<TextStyle> };

export const Action: React.FC<ActionProps> = props => {
  const [styles] = useTheme(themedStyles);

  return (
    <Pressable role="button" onPress={props.onPress} style={[styles.action, props.style]} disabled={props.isDisabled}>
      <View style={[styles.container, props.isDisabled && styles.disabled]}>
        {props.icon && (
          <MaterialIcon
            source={props.icon}
            color="primary"
            size={sizes.SPACING_L}
            style={[styles.icon, props.isIconCrossed && styles.crossed, props.isIconTilted && styles.tilted]}
          />
        )}
        <Text style={props.textStyle} fontStyle={props.fontStyle || 'medium'} size="l">
          {props.title}
        </Text>
      </View>
    </Pressable>
  );
};

import { createContext, forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { useEvent, useEventSubscriber } from '@almond/utils';

import { useUploadDraftAttachment } from './useUploadDraftAttachment';

import type { DraftAttachment } from '../../types';
import type { Dispatch, ForwardedRef, PropsWithChildren, SetStateAction } from 'react';

export type DraftAttachmentContextType = {
  attachments: DraftAttachment[];
  setAttachments: Dispatch<SetStateAction<DraftAttachment[]>>;
  commitAttachments: () => void;
  revertAttachments: () => void;
  registerChildSetter: (fn: (attachments: DraftAttachment[]) => void) => () => void;
  deleteAttachment: (toDelete: DraftAttachment) => void;
  retryAttachmentUpload: (toRetry: DraftAttachment) => void;
  getCompletedFileUploads: () => Promise<DraftAttachment[]>;
  clearOnMessageSend: () => void;
  attachDocumentIds: (docs: { uuid: string }[]) => void;
};

const DraftAttachmentContext = createContext<DraftAttachmentContextType | null>(null);

// Extract function so ESLint doesn't complain about too many nested closures
const findWithId = <T extends { id: string }>(array: T[], id: string) => array.find(a => a.id === id);

export const useDraftAttachmentContext = () => {
  const context = useContext(DraftAttachmentContext);

  if (!context) {
    throw new Error('useDraftAttachmentContext() must be used inside an appropriate context provider');
  }

  return context;
};

type DraftAttachmentProviderProps = PropsWithChildren<{
  patientUuid?: string;
}>;

/**
 * This provider stores the list of attachments the user has added to a message.
 * It allows the user to add new files, and delete files before the files are sent
 * in a message. The files are also uploaded to the server via the
 * `useUploadDraftAttachment()` hook, called within this component.
 * If 2 providers are nested inside each other, the outer state is propagated to the
 * inner state, but the inner state is only propagated to the outer state by calling
 * `commitAttachments()`. This enables the UX of the Upload Modal, where attachments
 * can be added/deleted, but don't get applied to the message until the "Apply" button
 * is pushed, and if the modal is closed the changes are undone.
 * Only files added to the outermost context are uploaded to the server, so files
 * aren't uploaded until the "Apply" button is pressed
 */
export const DraftAttachmentProvider = forwardRef(function DraftAttachmentProvider(
  props: DraftAttachmentProviderProps,
  ref: ForwardedRef<DraftAttachmentContextType>
) {
  const { children, patientUuid } = props;
  const parentContext = useContext(DraftAttachmentContext);

  const [attachments, setAttachments] = useState<DraftAttachment[]>([]);
  const { trigger, addListener } = useEventSubscriber<DraftAttachment[]>();

  // ///////////////////
  // When `clearOnMessageSend()` is called, clear the list of attachments.
  // Also, propagate down to all child providers to clear their state
  // as well
  // ///////////////////
  const registerChildSetter = addListener;
  const clearOnMessageSend = useEvent(() => {
    setAttachments([]);
    trigger([]);
  });

  useEffect(() => {
    if (!parentContext) return;

    return parentContext.registerChildSetter(setAttachments);
  }, [parentContext, clearOnMessageSend]);

  /**
   * If this is an inner context, propagate the value of the inner context out to the
   * parent context.
   */
  const commitAttachments = useEvent(() => {
    if (!parentContext) {
      throw new Error('commitAttachments can only be called from an inner provider context.');
    }

    // Be sure not to overwrite any upload state the parent context may have, since only
    // the outermost context actually uploads the files and changes the attachment item
    // properties (attachment.cdn values)
    // For each item, see if there is existing cdn data. If so, attach it to the new item
    // before replacing.
    parentContext.setAttachments(parentAttachments => {
      return attachments.map(attachment => {
        const existingAttachment = findWithId(parentAttachments, attachment.id);

        if (!existingAttachment) {
          return attachment;
        }

        return { ...attachment, cdn: existingAttachment.cdn };
      });
    });
  });

  /**
   * Reset an inner context state to the value from the outer context. Used when the Upload
   * modal is "cancelled"
   */
  const revertAttachments = useEvent(() => {
    if (!parentContext) {
      throw new Error('commitAttachments can only be called from an inner provider context.');
    }

    setAttachments(parentContext.attachments);
  });

  const attachDocumentIds = useEvent((docs: { uuid: string }[]) => {
    setAttachments(prevAttachments => {
      if (prevAttachments.length !== docs.length) {
        throw new Error(
          [
            `Trying to attach document IDs but the length of the lists don't match.`,
            `Local list is ${prevAttachments.length} items, Network list is ${docs.length} items.`,
          ].join(' ')
        );
      }

      return prevAttachments.map((attachment, index) => ({
        ...attachment,
        documentUuid: docs[index].uuid,
      }));
    });
  });

  /**
   * Delete an attachment from the current context
   */
  const deleteAttachment = useEvent((item: DraftAttachment) => {
    const updatedAttachments = attachments.filter(attachment => attachment.id !== item.id);

    setAttachments(updatedAttachments);
    trigger(updatedAttachments);
  });

  const { getCompletedFileUploads, retryAttachmentUpload } = useUploadDraftAttachment(
    !parentContext,
    patientUuid,
    attachments,
    setAttachments
  );
  const getCompletedFileUploadsNoop = useEvent(() => Promise.resolve([]));

  const value = useMemo(
    () => ({
      attachments,
      setAttachments,
      commitAttachments,
      revertAttachments,
      deleteAttachment,
      retryAttachmentUpload,
      getCompletedFileUploads: getCompletedFileUploads || getCompletedFileUploadsNoop,
      registerChildSetter,
      clearOnMessageSend,
      attachDocumentIds,
    }),
    [
      attachments,
      commitAttachments,
      revertAttachments,
      deleteAttachment,
      retryAttachmentUpload,
      getCompletedFileUploads,
      getCompletedFileUploadsNoop,
      registerChildSetter,
      clearOnMessageSend,
      attachDocumentIds,
    ]
  );

  useImperativeHandle(ref, () => value);

  return <DraftAttachmentContext.Provider value={value}>{children}</DraftAttachmentContext.Provider>;
});

import React from 'react';
import { View } from 'react-native';

import { BaseModal, useTheme } from '@almond/ui';

import { Action } from './Action';

import { themedStyles } from './styles';

import type { BottomSheetAction } from './Action';
import type { BaseModalProps } from '@almond/ui';

export type BottomSheetProps = BaseModalProps & {
  actions: BottomSheetAction[];
};

export const BottomSheet: React.FC<BottomSheetProps> = props => {
  const { actions, ...restProps } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <BaseModal {...restProps}>
      <View style={styles.contentContainer}>
        <View style={styles.border}>
          {actions.map((action, index) => (
            <Action key={index} {...action} />
          ))}
        </View>
        <Action
          title="Cancel"
          onPress={() => restProps.onRequestClose?.()}
          style={[styles.cancel, styles.border]}
          textStyle={styles.cancelText}
          fontStyle="bold"
        />
      </View>
    </BaseModal>
  );
};

export { BottomSheetAction };

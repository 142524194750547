import { useRerender } from '~/modules/ui';

import { useUnreadMessages } from '../components/UnreadMessagesProvider';
import { useStreamEventListener } from './useStreamEventListener';

import type { Channel } from 'stream-chat';

export const useChannelUnreadCount = (channel: Channel, streamId: string | undefined, unread?: number) => {
  const { unreadMessagesMap, setUnreadMessages } = useUnreadMessages();
  const channelId = channel.id;
  const rerender = useRerender();

  useStreamEventListener(channel, event => {
    if (event.type === 'notification.mark_unread') {
      const unreadMessages = event.unread_messages as number;

      if (channelId && unreadMessages) {
        setUnreadMessages(channelId, unreadMessages);
      }
    } else if (event.type === 'message.new' && event.message?.type === 'system') {
      // Stream system messages are classified differently, and they don't change the unread count
      // unless we reload the application.
      // We manually handle such messages and update our internal unread counter.
      if (channelId) {
        setUnreadMessages(channelId, (unreadMessagesMap[channelId] ?? 0) + 1);
      }
    } else {
      // TODO: Probably it's better to investigate all the events that affect the Status component and listen to them
      // instead of the using the global listener.
      rerender();
    }
  });

  // We specifically don't want to show the unread indicator for providers
  // if they aren't members of the chat. Otherwise, we will want to show the unread indicator.
  if (!streamId || !channel.state.members[streamId]) return;

  return unread || (channelId ? unreadMessagesMap[channelId] : undefined);
};

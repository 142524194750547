import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

type UnreadMessagesContext = {
  unreadMessagesMap: Record<string, number>;
  setUnreadMessages: (id: string, unreadMessages: number) => void;
  deleteUnreadMessages: (id: string) => void;
};

const UnreadMessagesContext = createContext<UnreadMessagesContext>({
  unreadMessagesMap: {},
  setUnreadMessages: () => {
    // no op
  },
  deleteUnreadMessages: () => {
    // no op
  },
});

// When we mark a channel as unread, then the backend sens a custom `notifications.mark_as_unread` event.
// It's not handles by `stream-chat` and the UI isn't updated. That's why we need to handle it manually.
export const UnreadMessagesProvider: React.FC<React.PropsWithChildren> = props => {
  const [unreadMessagesMap, setUnreadMessagesMap] = useState<Record<string, number>>({});
  const setUnreadMessages = useCallback((id: string, unreadMessages: number) => {
    setUnreadMessagesMap(prevState => ({ ...prevState, [id]: unreadMessages }));
  }, []);
  const deleteUnreadMessages = useCallback((id: string) => {
    setUnreadMessagesMap(prevState => {
      const updatedState = { ...prevState };

      delete updatedState[id];

      return updatedState;
    });
  }, []);
  const contextValue = useMemo(
    () => ({
      unreadMessagesMap,
      setUnreadMessages,
      deleteUnreadMessages,
    }),
    [deleteUnreadMessages, setUnreadMessages, unreadMessagesMap]
  );

  return <UnreadMessagesContext.Provider value={contextValue}>{props.children}</UnreadMessagesContext.Provider>;
};

export const useUnreadMessages = () => useContext(UnreadMessagesContext);

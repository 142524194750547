import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

export type SectionProps = React.PropsWithChildren<{
  title: string;
  rightAction?: React.ReactNode;
  testID?: string;
}>;

export const Section = (props: SectionProps) => {
  const { title, rightAction, children, testID } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.container} testID={testID}>
      <View style={styles.header}>
        <Text size="l">{title}</Text>
        {rightAction}
      </View>
      <View style={styles.contentContainer}>{children}</View>
    </View>
  );
};

import { useChatContext } from 'stream-chat-react';

import { useAlmondApiMutation } from '~/modules/api';

import { useChannelData } from './useChannelData';

import type { Channel } from 'stream-chat';

/**
 * Get information on the status of a given channel, for the current user.
 * Note - this hook does not use the channel object from useChatContext()
 *        because it is used for arbitrary channels in the ChannelList. It
 *        must be able to operate on any channel, not just the selected one
 * @param channel The channel to get the status information for
 * @returns Information on status, and method to toggle value
 */
export const useChannelStatus = (channel?: Channel | undefined) => {
  const { client } = useChatContext();
  const channelData = useChannelData(channel);
  const status = channelData?.status;
  const { trigger } = useAlmondApiMutation(
    'post',
    channel?.id ? `/messaging/channels/${channel.id as '{channel_id}'}/status/` : null
  );

  const toggleStatus = async () => {
    if (!channel) {
      return;
    }

    await trigger({ status: status === 'ongoing' ? 'resolved' : 'ongoing' });
  };

  return { status, toggleStatus, statusVisibleToUser: client?.user?.role === 'careguide' };
};

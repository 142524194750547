import { useEffect, useState } from 'react';
import { Image } from 'react-native';

/**
 * When swapping between 2 image URLs, keep the first URL around, load the second
 * in the background, and only swap out the URLs once the second has loaded (or errored)
 * @param imageUrl Image URL to fetch before updating
 * @returns Image URL that is already in the cache (if not the first image passed)
 */
export const usePrefetchImageUrl = (imageUrl?: string) => {
  const [prefetchedUrl, setPrefetchedUrl] = useState<string | null>(imageUrl || null);

  useEffect(() => {
    if (imageUrl && prefetchedUrl !== imageUrl) {
      Image.prefetch(imageUrl).finally(() => {
        setPrefetchedUrl(imageUrl);
      });
    }
  }, [imageUrl, prefetchedUrl]);

  if (!imageUrl) return null;

  return prefetchedUrl;
};

import React from 'react';
import { View } from 'react-native';

import { Chip, useTheme } from '@almond/ui';

import { themedStyles } from './filterStyles';

import type { Filter } from '../../types';

type ChipValue<T extends string> = {
  key: T;
  value: string;
  testID: string;
};

const chips: ChipValue<Filter>[] = [
  { key: 'ongoing', value: 'Ongoing', testID: 'ChannelList-Ongoing' },
  { key: 'resolved', value: 'Resolved', testID: 'ChannelList-Resolved' },
];

export type FiltersProps = {
  filter: Filter;
  setFilter: (filter: Filter) => void;
};

export const Filters: React.FC<FiltersProps> = props => {
  const { filter, setFilter } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.chipsContainer}>
      {chips.map(chip => (
        <Chip key={chip.key} onPress={() => setFilter(chip.key)} isSelected={filter === chip.key} testID={chip.testID}>
          {chip.value}
        </Chip>
      ))}
    </View>
  );
};

import { dateAndTimeParsers } from '@almond/date-and-time';

import type { Channel } from 'stream-chat';

export const getLatestMessageUpdatedAt = (channel: Channel) => {
  const latestMessage = channel.state.messages.at(-1);

  if (!latestMessage) return;

  return dateAndTimeParsers.parseUpdatedAt(latestMessage.updated_at, 'Yesterday');
};

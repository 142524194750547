/// ///////////////////////////////////////////////////////////
// This component was created by
// 1. Copying the default QuotedMessagePreview source code from stream-chat-react
//    https://github.com/GetStream/stream-chat-react/blob/master/src/components/MessageInput/QuotedMessagePreview.tsx
// 2. Updating imports
// 3. Removing QuotedMessagePreviewHeader component
// 4. Removing quotedMessageAttachment variable
// 5. Updating the default UI
//
// We will likely modify this component more in the future. These are the initial
// edits, but check the file's git history for more recent edits.
/// ///////////////////////////////////////////////////////////

import './quoted-message-preview.css';

import React from 'react';

import { CloseIcon, useChannelActionContext, useTranslationContext } from 'stream-chat-react';

import { directStyles } from './styles';

import type { TranslationLanguages } from 'stream-chat';
import type { StreamMessage } from 'stream-chat-react';

export type QuotedMessagePreviewProps = {
  quotedMessage: StreamMessage;
};

export const QuotedMessagePreview = ({ quotedMessage }: QuotedMessagePreviewProps) => {
  const { userLanguage } = useTranslationContext('QuotedMessagePreview');

  const quotedMessageText =
    quotedMessage.i18n?.[`${userLanguage}_text` as `${TranslationLanguages}_text`] || quotedMessage.text;

  const { setQuotedMessage } = useChannelActionContext('QuotedMessagePreview');

  if (!quotedMessageText) return null;

  return (
    <div
      className="quoted-message-preview-container"
      style={directStyles.quotedMessageContainer}
      data-testid="quoted-message-preview-container"
    >
      <div style={directStyles.quotedMessageItem}>
        <div style={directStyles.quotedMessageTitle}>
          {`Replying to ${quotedMessage.user && quotedMessage.user.name}`}
        </div>
        <div
          className="quoted-message-preview-btn"
          aria-label="Cancel Reply"
          style={directStyles.quotedMessageCloseContainer}
          onClick={() => setQuotedMessage(undefined)}
        >
          <CloseIcon />
        </div>
      </div>
      <div style={directStyles.quotedMessageItem}>
        <div style={directStyles.quotedMessageText}>{quotedMessageText}</div>
      </div>
    </div>
  );
};

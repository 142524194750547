import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ensureConversationMemberSchema } from '@almond/extension-utils';
import { useTheme } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';

import { useExtensionMessageListener } from '~/modules/extension';
import { SidebarButton } from '~/modules/ui';
import { useCurrentUser } from '~/modules/user';

import { useConversationMembers, useToggleMember } from '../../hooks';
import { Channel } from '../Channel';
import { DeleteMessageProvider } from '../DeleteMessageProvider';
import { MessageInput } from '../MessageInput';
import { MessageList } from '../MessageList';

import { themedStyles } from './styles';

type AdminConversationProps = {
  showJoinInThread?: boolean;
};
export const AdminConversation = (props: AdminConversationProps) => {
  const [styles] = useTheme(themedStyles);
  const { channel, client } = useChatContext();
  const conversationMembers = useConversationMembers(channel);
  const currentUserIsChannelMember = conversationMembers.some(member => member.isMe);
  const currentUser = useCurrentUser();
  const currentUserRole = client.user?.role;

  const { buttonTitle, buttonType, toggleMember, isChannelMember } = useToggleMember({
    user: { id: currentUser?.streamId, role: currentUserRole },
    channel,
    streamId: currentUser?.streamId,
  });

  useExtensionMessageListener(ensureConversationMemberSchema, async () => {
    // Join the conversation, if not already a member
    if (isChannelMember === false) {
      await toggleMember();
    }
  });

  return (
    channel && (
      <Channel channel={channel} role="admin">
        <View style={styles.channelContent}>
          <View style={StyleSheet.absoluteFill}>
            <DeleteMessageProvider>
              <MessageList />
            </DeleteMessageProvider>
            {currentUserIsChannelMember && <MessageInput />}
            {!currentUserIsChannelMember && isChannelMember === false && props.showJoinInThread && (
              <SidebarButton type={buttonType} onPress={toggleMember} style={styles.joinButton}>
                {buttonTitle}
              </SidebarButton>
            )}
          </View>
        </View>
      </Channel>
    )
  );
};

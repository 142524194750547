import { useBrowserType } from '@almond/ui';

import { useSidebar } from '~/modules/ui';

import { useIsOnChannelListPage } from './useIsOnChannelListPage';

export const useAdminSidebar = () => {
  const sidebar = useSidebar();
  const browserType = useBrowserType();
  const isOnChannelListPage = useIsOnChannelListPage();
  const isSidebarOpen = browserType === 'mobile' && sidebar.isSidebarOpen;
  const canOpenSidebar = !isOnChannelListPage && browserType === 'mobile' && !sidebar.isSidebarOpen;

  return { ...sidebar, isSidebarOpen, canOpenSidebar };
};

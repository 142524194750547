import { useMemo } from 'react';
import { View } from 'react-native';

import { Text } from '@almond/ui';

import { CATEGORIES } from '../../config';
import { safeParseValidation } from '../../validations';
import { BaseTodoItem } from '../BaseTodoItem';
import { unstable_styles as cssUnstableStyles } from './DraftTodoItem.module.css';
import { ErrorIcon } from './ErrorIcon';
import { ValidationMessage } from './ValidationMessage';

import type { PendingTodoItem } from '../../types';
import type { TodoCtaButton } from '../BaseTodoItem';

type DraftTodoItemProps = {
  item: PendingTodoItem;
  isExpanded: boolean;
  onExpand: (isExpanded: boolean) => void;
  ctaButtons?: TodoCtaButton[];
};

export const DraftTodoItem = (props: DraftTodoItemProps) => {
  const { item, isExpanded, onExpand, ctaButtons } = props;

  const validation = useMemo(() => safeParseValidation(item), [item]);
  const category = useMemo(() => CATEGORIES.find(c => c.id === item.category), [item]);

  return (
    <View style={cssUnstableStyles.wrapper}>
      {!validation.success &&
        validation.error.issues.map(i => <ValidationMessage key={i.code} type="error" message={i.message} />)}
      {category && category.title.toLowerCase() !== item.initialCategory?.toLowerCase() && (
        <ValidationMessage
          type="info"
          message={`Entered category was ${item.initialCategory}, auto-corrected to ${category.title}`}
        />
      )}
      <BaseTodoItem
        title={
          <>
            <Text style={cssUnstableStyles.newLabel} fontStyle="medium">
              {'NEW '}
            </Text>
            {item.title}
          </>
        }
        id={item.uuid}
        description={
          <div>
            <p>{item.description}</p>
          </div>
        }
        leftIcon={!validation.success && <ErrorIcon color="lightError" />}
        isExpanded={isExpanded}
        onExpand={onExpand}
        ctaButtons={ctaButtons}
        style={!validation.success && cssUnstableStyles.invalid}
        dataSet={{ testclass: 'TodoItem', uuid: item.uuid }}
      />
    </View>
  );
};

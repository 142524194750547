import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  modalImage: {
    position: 'absolute',
    resizeMode: 'contain',
    left: '65%',
    right: '-15%',
    top: 0,
    bottom: 0,
  },
  modalImageError: {
    left: '55%',
    right: '5%',
    top: 0,
    bottom: '50%',
  },
}));

import { useEffect, useRef, useState } from 'react';

import { useChatContext } from 'stream-chat-react';

import { useRunAfterStreamInit } from './useRunAfterStreamInit';

import type { Channel } from 'stream-chat';

export const useChannel = (id: string | undefined, setAsActiveChannel = false) => {
  const { client, channel: streamChannel, setActiveChannel } = useChatContext();
  const [channel, setChannel] = useState<Channel | undefined>();
  const [isReady, setIsReady] = useState(false);

  const alreadySetAsActiveChannel = useRef(false);

  useEffect(() => {
    if (setAsActiveChannel && channel && channel !== streamChannel && !alreadySetAsActiveChannel.current) {
      setActiveChannel(channel);
      alreadySetAsActiveChannel.current = true;
    }
  }, [channel, streamChannel, setAsActiveChannel, setActiveChannel]);

  useRunAfterStreamInit(async () => {
    if (!id) {
      setIsReady(true);

      return;
    }

    setIsReady(false);
    const c = await client.queryChannels({ id: `auth0-${id}` });

    setChannel(c[0]);
    setIsReady(true);
  }, [id]);

  return { channel, isReady };
};

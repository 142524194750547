import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    ...getShadow(),
    backgroundColor: theme.background,
    padding: sizes.SPACING_L,
    borderRadius: sizes.SPACING_M,
    rowGap: sizes.SPACING_S,
  },

  content: {
    flex: 1,
    flexDirection: 'row',
    columnGap: sizes.SPACING_XL,
    alignItems: 'center',
  },

  rightContainer: {
    flex: 1,
    rowGap: sizes.SPACING_M,
  },

  image: {
    width: 180,
    height: 148,
  },

  title: {
    color: theme.accent,
  },

  titleMobile: {
    textAlign: 'center',
  },
}));

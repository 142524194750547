import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  channelListContainer: {
    height: '100%',
  },
  channelListItemsContainer: {
    flex: 1,
  },
  channelListItems: {
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
    overflowY: 'auto',
  },
  channelListLoading: {
    justifyContent: 'flex-start',
    marginTop: sizes.SPACING_XL,
  },
  channelListErrorText: {
    color: theme.background,
  },
  channelListEmpty: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  channelListEmptyText: {
    marginHorizontal: sizes.SPACING_M,
    textAlign: 'center',
  },
  loadingText: {
    gap: sizes.SPACING_XS,
  },
  previewContainer: {
    height: 58,
    width: '100%',
    backgroundColor: theme.lightSecondaryBackground,
    flexDirection: 'row',
    paddingHorizontal: sizes.SPACING_S,
    alignItems: 'center',
  },

  previewContainerSelected: {
    backgroundColor: theme.info,
  },

  center: {
    flex: 1,
    marginStart: sizes.SPACING_S,
  },

  nameContainer: {
    flexDirection: 'row',
  },

  pinContainer: {
    transform: [{ rotate: '45deg' }],
    marginEnd: sizes.SPACING_XS,
  },

  latestMessage: {
    color: theme.placeholder,
    lineHeight: sizes.SPACING_M,
  },

  latestMessageBoldBlack: {
    fontWeight: '600',
    color: theme.text,
  },

  latestMessageBoldBlue: {
    fontWeight: '600',
    color: theme.primary,
  },

  right: {
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingVertical: sizes.SPACING_S,
  },

  loadMoreButton: {
    width: '100%',
    paddingVertical: sizes.SPACING_M,
    backgroundColor: theme.lightSecondaryBackground,
  },
}));

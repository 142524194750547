import { useEffect, useMemo, useState } from 'react';

import { z } from 'zod';

import { useCurrentUser } from '~/modules/user';

import { sortUsersComparator } from '../utils';
import { useStreamEventListener } from './useStreamEventListener';

import type { Channel, ChannelMemberResponse } from 'stream-chat';

export const useConversationMembers = (channel: Channel | undefined) => {
  const [members, setMembers] = useState(Object.values(channel?.state.members || {}));
  const currentUser = useCurrentUser();

  useEffect(() => {
    setMembers(Object.values(channel?.state.members || {}));
  }, [channel]);

  useStreamEventListener(channel, {
    'member.removed': event => {
      const { member } = event;

      if (member) setMembers(m => m.filter(mItem => mItem.user_id !== member.user_id));
    },

    'member.added': event => {
      const { member } = event;

      if (member) setMembers(m => [...m, member]);
    },
  });

  return useMemo(() => {
    if (!channel) return [];

    return sortAndFilterMembers(members, currentUser?.streamId);
  }, [channel, currentUser?.streamId, members]);
};

export const sortAndFilterMembers = (inputMembers: ChannelMemberResponse[], streamId?: string) => {
  return inputMembers
    .map(
      member =>
        member.user && {
          role: member.user.role,
          label: typeof member.user.label === 'string' ? member.user.label : undefined,
          id: member.user.id,
          name: member.user.name,
          isMe: streamId === member.user.id,
          isPatient: member.user.role === 'patient',
          patientUuid: member.user.role === 'patient' ? z.string().parse(member.user.patient_uuid) : undefined,
        }
    )
    .filter(<T>(member: T | undefined): member is T => {
      return !!member;
    })
    .sort((a, b) => sortUsersComparator(a, b, streamId));
};

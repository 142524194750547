import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  item: {
    paddingVertical: sizes.SPACING_S,
    rowGap: sizes.SPACING_XS,
  },

  itemTitle: {
    color: theme.darkText,
  },

  itemSubtitle: {
    color: theme.secondaryText,
  },

  link: {
    flexDirection: 'row',
  },
}));

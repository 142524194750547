import React, { useState } from 'react';

import { useChatContext } from 'stream-chat-react';

import { SidebarButton } from '~/modules/ui';
import { useCurrentUser } from '~/modules/user';

import { ManageMembersModal } from './ManageMembersModal';

export const ManageMembersButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const currentUser = useCurrentUser();
  const { channel } = useChatContext();

  const handlePress = () => {
    setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!channel || !currentUser) return null;

  return (
    <>
      <SidebarButton onPress={handlePress}>{'Manage Members'}</SidebarButton>
      <ManageMembersModal
        isVisible={isVisible}
        onRequestClose={handleClose}
        channel={channel}
        streamId={currentUser.streamId}
      />
    </>
  );
};

import React from 'react';
import { View } from 'react-native';

import { Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { LocalImage } from '~/modules/ui';

import { ReferButton } from '../ReferButton';

import { themedStyles } from './styles';

export const Referral = () => {
  const [styles] = useTheme(themedStyles);
  const { isDesktop, isMobile } = useBrowserTypeMap();

  return (
    <View style={styles.container} testID="Referral">
      <View style={styles.content}>
        {isDesktop && <LocalImage resizeMode="contain" source="universe2" style={styles.image} />}
        <View style={styles.rightContainer}>
          <Text style={[styles.title, isMobile && styles.titleMobile]} fontStyle="bold" size="xl" testID="Title">
            {'Get $100 off next year’s membership'}
          </Text>
          <Text>
            {[
              'Get $100 towards your next membership renewal for every friend who signs up for Almond.',
              'They will receive $100 off their first year of membership when they book.',
            ].join(' ')}
          </Text>
          <ReferButton />
        </View>
      </View>
    </View>
  );
};

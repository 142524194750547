import './channel-list-overrides.css';

import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { ActivityIndicator, Searchbar, useBrowserType, useTheme } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';
import { useChatContext } from 'stream-chat-react';

import { BottomSheet } from '~/modules/ui';
import { useCurrentUser } from '~/modules/user';

import { useChannel, useChannelStatus, useConversationMenu } from '../../hooks';
import { ChannelListItems } from './ChannelListItems';
import { Filters } from './Filters';

import { themedStyles } from './styles';

import type { Filter } from '../../types';
import type { ConversationDetailsParams } from '~/types';

const AlmondChannelList = () => {
  const [styles] = useTheme(themedStyles);
  const searchParams = useLocalSearchParams<ConversationDetailsParams>();
  const [initialConversationId] = useState(searchParams.conversationId);
  const shouldSetActiveChannelFromURL = !!initialConversationId;
  const { isReady } = useChannel(searchParams.conversationId, shouldSetActiveChannelFromURL);
  const { client } = useChatContext();
  const { statusVisibleToUser } = useChannelStatus();
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<Filter | undefined>(undefined);
  const currentUser = useCurrentUser();
  const browserType = useBrowserType();
  const { openConversationMenu, isVisible, actions, onDismiss } = useConversationMenu();

  // Make sure providers don't see filters
  useEffect(() => {
    const role = client?.user?.role;

    if (role === 'careguide') {
      setFilter('ongoing');
    } else if (role === 'provider') {
      setFilter('member-of');
    } else if (role === 'admin') {
      setFilter('all');
    }
  }, [client?.user?.role]);

  if (!isReady) return <ActivityIndicator />;
  if (!currentUser) return null;

  return (
    <View testID="ChannelList" style={styles.channelListContainer}>
      <ChannelListItems
        filter={filter}
        search={search}
        openConversationMenu={openConversationMenu}
        renderAbove={({ isLoading }) => (
          <>
            <Searchbar onChangeText={text => setSearch(text)} placeholder="Name or DOB" isLoading={isLoading} />
            {!search && statusVisibleToUser && filter && <Filters filter={filter} setFilter={setFilter} />}
          </>
        )}
      />
      {browserType === 'mobile' && <BottomSheet isVisible={isVisible} actions={actions} onRequestClose={onDismiss} />}
    </View>
  );
};

export { AlmondChannelList as ChannelList };

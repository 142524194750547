import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  placeholder: {
    color: theme.darkText,
  },
}));

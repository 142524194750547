import { useEffect, useMemo, useState } from 'react';

export const useDoesFileExist = (fileLocation: string | null | undefined) => {
  const [exists, setExists] = useState<boolean | null>(null);

  const isBlob = fileLocation && fileLocation.startsWith('blob:');

  const cacheBustedFileLocation = useMemo(() => {
    if (!fileLocation || isBlob) {
      return null;
    }

    const randomCacheValue = `random=${String(Math.random()).substring(3)}`;
    const joiner = fileLocation.includes('?') ? '&' : '?';

    return fileLocation + joiner + randomCacheValue;
  }, [fileLocation, isBlob]);

  useEffect(() => {
    if (!cacheBustedFileLocation) {
      return;
    }

    const controller = new AbortController();

    setExists(null);
    fetch(cacheBustedFileLocation, {
      method: 'HEAD',
      credentials: 'include',
      mode: 'cors',
      signal: controller.signal,
    })
      .then(res => {
        setExists(res.ok);
      })
      .catch(e => {
        if (e.name === 'AbortError') {
          return;
        }

        setExists(false);
      });

    return () => {
      controller.abort();
    };
  }, [cacheBustedFileLocation]);

  if (!fileLocation) {
    return { doesFileExist: false };
  }

  if (isBlob) {
    return { doesFileExist: true };
  }

  if (exists === null) {
    return { isLoading: true };
  }

  return { doesFileExist: exists };
};

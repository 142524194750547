.str-chat__message--me .attachment-container {
  background-color: var(--str-chat__primary-surface-color);
  border-radius: var(--str-chat__border-radius-md) var(--str-chat__border-radius-md) 0 var(--str-chat__border-radius-md);
}

.str-chat__message--other .attachment-container {
  background-color: var(--str-chat__secondary-surface-color);
  border-radius: var(--str-chat__border-radius-md) var(--str-chat__border-radius-md) var(--str-chat__border-radius-md) 0;
}

.str-chat__message--patient.str-chat__message--other .attachment-container {
  background-color: var(--str-chat__admin-other-patient-surface-background);
  border-radius: var(--str-chat__border-radius-md) var(--str-chat__border-radius-md) var(--str-chat__border-radius-md) 0;
}

/* eslint-disable @typescript-eslint/naming-convention */

import { z } from 'zod';

type IntegrationValues = {
  GTM_KEY: string;
  STATSIG_CLIENT_ID: string;
  STATSIG_ENVIRONMENT: string;
};

type Auth0Values = {
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
};

type Env = IntegrationValues &
  Auth0Values & {
    STREAM_API_KEY: string;
    API_BASE_URL: string;
    APEX_DOMAIN: string;
    BOOK_URL: string;
  };

const prodEnv: Env = {
  AUTH0_CLIENT_ID: 'tqNEZGUTeOu6jKX9qODH3SmdO7PBzNZX',
  AUTH0_DOMAIN: 'auth.almondobgyn.com',
  STREAM_API_KEY: 'qycvvbk2t7st',
  API_BASE_URL: 'https://api.almondobgyn.com',
  APEX_DOMAIN: '.almondobgyn.com',
  BOOK_URL: 'https://book.almondobgyn.com',
  GTM_KEY: 'GTM-MV243PZ',
  STATSIG_CLIENT_ID: 'client-DTv9oJ14MEwRz9gC7PwxdyQfdfoW99WRgV75FBdx0wr',
  STATSIG_ENVIRONMENT: 'production',
};

const stageEnv: Env = {
  AUTH0_CLIENT_ID: 'Gr196YOvuJZ8Jhb82jCNoGyWW8HjGl5l',
  AUTH0_DOMAIN: 'auth.stage-almondobgyn.com',
  STREAM_API_KEY: 'uzzyc4xarn4x',
  API_BASE_URL: 'https://api.stage-almondobgyn.com',
  APEX_DOMAIN: '.stage-almondobgyn.com',
  BOOK_URL: 'https://book.stage-almondobgyn.com',
  GTM_KEY: 'GTM-STAGE-ENV',
  STATSIG_CLIENT_ID: 'client-CHX3a0jBCoHP56XZz4NE8cRiPwxal6v57XPfgGIteFM',
  STATSIG_ENVIRONMENT: 'staging',
};

const devEnv: Env = {
  ...stageEnv,
  BOOK_URL: 'http://localhost:19006',
  GTM_KEY: 'GTM-DEV-ENV',
  STATSIG_ENVIRONMENT: 'development',
};

const mode = z
  .enum([
    'dev', // Local development against a local API server
    'stage', // Staging, or local development against a staging API server
    'prod', // Production
  ])
  .parse(process.env.EXPO_PUBLIC_ENV);

// eslint-disable-next-line import/no-mutable-exports
let env: Env = devEnv;

if (process.env.EXPO_PUBLIC_API_ENV === 'local') {
  env.API_BASE_URL = 'https://localhost:8000';
  env.STREAM_API_KEY = 'tdxbvpq3x8et';
}

if (mode === 'stage') env = stageEnv;
else if (mode === 'prod') env = prodEnv;

export { devEnv, env };

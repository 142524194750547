import { useBrowserType } from '@almond/ui';
import { useSegments } from 'expo-router';

import { useSidebar } from '~/modules/ui';

export const usePatientSidebar = () => {
  const sidebar = useSidebar();
  const browserType = useBrowserType();
  const segments = useSegments();
  const isSidebarOpen = browserType === 'mobile' && segments.at(-1) === 'messages' && sidebar.isSidebarOpen;
  const canOpenSidebar = browserType === 'mobile' && segments.at(-1) === 'messages' && !isSidebarOpen;

  return { ...sidebar, isSidebarOpen, canOpenSidebar };
};

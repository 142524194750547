import { useState } from 'react';

import { dateAndTimeParsers } from '@almond/date-and-time';

import { useAlmondApiMutation } from '~/modules/api';
import { parseApiResponseError } from '~/modules/errors';

import { checkShouldVerifyPhoneNumber } from './checkShouldVerifyPhoneNumber';

import type { DefaultDemographicFormValues, DemographicFormValues, Writeable } from './types';
import type { PatientUpdateIn } from '@almond/api-types';

export const useUpdatePatient = (
  patientUuid: string,
  defaultValues: DefaultDemographicFormValues,
  isAdmin: boolean | undefined,
  mutate: () => void,
  onRequestClose: () => void,
  onPhoneChange?: () => void
  // eslint-disable-next-line max-params
) => {
  const { trigger, isMutating } = useAlmondApiMutation('put', `/patients/${patientUuid as '{patient_uuid}'}`);
  const [errorMessage, setErrorMessage] = useState<string>();
  const onSubmit = async (values: DemographicFormValues) => {
    try {
      const input: Writeable<PatientUpdateIn> = {
        firstName: values.firstName,
        lastName: values.lastName,
        birthday: dateAndTimeParsers.toRemoteDate(values.birthday),
        email: values.email,
        smsOptInStatus: values.isOptedIntoSms ? 'opted_in' : 'opted_out',
      };

      // Don't opt the patient in or out of SMS, because admin users
      // cannot verify a user's phone number.
      if (isAdmin) {
        input.smsOptInStatus = undefined;
      }

      // Updating `preferredFirstName` only if it's not equal to the `firstName`.
      if (values.preferredFirstName !== values.firstName) {
        input.preferredFirstName = values.preferredFirstName;
      }

      // Updating phone only if it's different from the default one,
      // otherwise the user will need to confirm it one more time.
      if (values.phone !== defaultValues.phone) {
        input.phone = values.phone;
      }

      await trigger(input);
      mutate();
      onRequestClose();

      const shouldVerifyPhoneNumber = checkShouldVerifyPhoneNumber(
        values.phone,
        !!values.isOptedIntoSms,
        defaultValues
      );

      if (shouldVerifyPhoneNumber) {
        onPhoneChange?.();
      }
    } catch (error) {
      setErrorMessage(parseApiResponseError(error));
    }
  };

  return { onSubmit, isMutating, errorMessage };
};

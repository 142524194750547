import React from 'react';
import { View } from 'react-native';

import { Button, MaterialIcon, ScrollView, sizes, Text, useTheme } from '@almond/ui';

import { Modal } from '~/modules/ui';

import { themedStyles } from './styles';

import type { ModalProps } from '~/modules/ui';

export type MessagePromptModal = Pick<ModalProps, 'isVisible' | 'onRequestClose'> & {
  text: string;
  attachmentsLength: number;
  onConfirmSend: () => void;
};

export const MessagePromptModal = (props: MessagePromptModal) => {
  const { text, attachmentsLength, onConfirmSend, ...restProps } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <Modal
      {...restProps}
      title="Send Message"
      // eslint-disable-next-line max-len
      subtitle="This message may be sent to the patient as a text message. It cannot be deleted or edited. Please double check message for accuracy!"
      footer={
        <View style={styles.modalFooter}>
          <Button
            size="m"
            type="secondary"
            style={styles.button}
            onPress={restProps.onRequestClose}
            testID="EditMessage"
          >
            {'Edit Message'}
          </Button>
          <Button size="m" onPress={onConfirmSend} style={styles.button} testID="ConfirmSend">
            {'Confirm Send'}
          </Button>
        </View>
      }
      testID="MessagePromptModal"
    >
      <View style={styles.modalContent}>
        <ScrollView containerStyle={styles.modalContentContainer}>
          <View style={styles.messageContainer}>
            <Text size={text.length <= 200 ? 'xxl' : 'xl'}>{text}</Text>
          </View>
        </ScrollView>
      </View>
      {!!attachmentsLength && (
        <View style={styles.attachments}>
          <MaterialIcon source="attach-file" size={sizes.FONT_SIZE_M} />
          <Text>{`${attachmentsLength} document${attachmentsLength > 1 ? 's' : ''} attached`}</Text>
        </View>
      )}
    </Modal>
  );
};

import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  notFoundImage: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: theme.border,
  },
  notFoundText: {
    color: theme.darkText,
  },
}));

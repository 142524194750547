import './applyStreamChatPolyfills';
import '~env';
import 'stream-chat-react/dist/css/v2/index.css';
import 'expo-router/entry';

import { initMaterialIcons } from '@almond/ui';

import { materialIcons } from '~assets';

initMaterialIcons(materialIcons);

import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  button: {
    width: 131,
  },

  buttonMobile: {
    alignSelf: 'center',
  },
}));

import { useAlmondApiMutation } from '~/modules/api';

import type { Channel } from 'stream-chat';

export const useMarkAsUnread = (channel: Channel | undefined) => {
  const isAvailable = channel?.state.membership.user?.role === 'careguide';
  const { trigger, isMutating } = useAlmondApiMutation(
    'post',
    channel ? `/messaging/channels/${channel.data?.id as '{channel_id}'}/unread/` : null
  );
  const hasPatientMessage = channel?.state.messages.some(message => message.user?.role === 'patient');
  const isDisabled = !hasPatientMessage || isMutating;
  const markAsUnread = (userIds: string[]) => trigger({ userIds });

  return { isAvailable, isDisabled, markAsUnread };
};

import { ScrollView, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { PropsWithChildren } from 'react';

export const TodoPageWrapper = (props: PropsWithChildren) => {
  const [styles] = useTheme(themedStyles);

  return (
    <ScrollView
      containerStyle={styles.background}
      style={styles.scrollContainer}
      contentContainerStyle={styles.wrapper}
    >
      {props.children}
    </ScrollView>
  );
};

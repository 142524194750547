import './message-list-overrides.css';

import React from 'react';

import { MessageList } from 'stream-chat-react';

import { useCustomRenderText } from './useCustomRenderText';

import type { MessageListProps as StreamMessageListProps } from 'stream-chat-react';

export type MessageListProps = StreamMessageListProps & {
  addParamsToLabLink?: boolean;
};

const AlmondMessageList: React.FC<MessageListProps> = props => {
  const { addParamsToLabLink = false, ...restProps } = props;

  const customRenderText = useCustomRenderText(addParamsToLabLink);

  // Point Stream to the custom render function
  return <MessageList {...restProps} renderText={customRenderText} />;
};

export { AlmondMessageList as MessageList };

type Error = {
  error_code: string;
  message: string;
};

type ErrorResponse = { errors: Error[] };

type ApiResponseError = {
  info: ErrorResponse;
};

const defaultError =
  "We've run into an unexpected issue. Please call us at 424-237-8406 and we’ll help you out by phone!";

const isApiResponseError = (error: unknown): error is ApiResponseError =>
  error != null &&
  typeof error === 'object' &&
  'info' in error &&
  error.info != null &&
  typeof error.info === 'object' &&
  'errors' in error.info &&
  Array.isArray(error.info.errors);

export const parseApiResponseError = (apiResponseError: unknown): string => {
  if (!isApiResponseError(apiResponseError)) {
    return defaultError;
  }

  const filteredErrors = apiResponseError.info.errors.filter(error => error.error_code === 'display_to_user');

  // Display all the errors that should be displayed to the user, otherwise display the default error.
  if (!filteredErrors.length) {
    return defaultError;
  }

  return filteredErrors.map(error => error.message).join('; ');
};

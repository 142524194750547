import type { Channel } from 'stream-chat';

export type UseToggleMemberArgs = {
  user?: { id?: string; role?: string };
  streamId?: string;
  channel?: Channel;
  onMembersChange?: (arg: { userToMarkAsUnread?: null | string }) => void;
  joinTitle?: string;
  leaveTitle?: string;
};

export const useToggleMember = (args: UseToggleMemberArgs) => {
  const { user, streamId, channel, onMembersChange, joinTitle = 'Join', leaveTitle = 'Leave' } = args;

  if (!user || !user.id || !streamId || !channel || (user.role && user.role !== 'provider')) {
    return { buttonTitle: undefined, buttonType: undefined, toggleMember: undefined, isChannelMember: undefined };
  }

  const channelMemberIds = Object.keys(channel.state.members);
  const isChannelMember = channelMemberIds.includes(user.id);

  // TODO: Replace with permissions as soon as we configure them.
  const buttonTitle = (() => {
    if (streamId === user.id) {
      return isChannelMember ? leaveTitle : joinTitle;
    }

    return isChannelMember ? 'Remove' : 'Invite';
  })();

  const buttonType = isChannelMember ? ('accent' as const) : ('primary' as const);

  const toggleMember = async () => {
    if (!user.id) return;

    let userToMarkAsUnread: null | string = null;

    if (isChannelMember) {
      await channel.removeMembers([user.id]);
    } else {
      await channel.addMembers([user.id]);
      if (user.id !== streamId) userToMarkAsUnread = user.id;
    }

    onMembersChange?.({ userToMarkAsUnread });
  };

  return { buttonTitle, buttonType, toggleMember, isChannelMember };
};

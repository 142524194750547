import type { DraftAttachment } from '../types';

export const filterUniqueFiles = (items: DraftAttachment[]) => {
  const seenFiles = new Set();
  const seenElationDocuments = new Set();

  return items.filter(item => {
    if ('file' in item) {
      const key = `${item.file.name}-${item.file.size}-${item.file.lastModified}`;

      if (seenFiles.has(key)) {
        return false;
      }

      seenFiles.add(key);
    } else {
      if (seenElationDocuments.has(item.elationDocumentId)) {
        return false;
      }

      seenElationDocuments.add(item.elationDocumentId);
    }

    return true;
  });
};

import type { HealthRecordTypesEnum } from '@almond/api-types';
import type { SelectOption } from '@almond/ui';

export const CDN_COOKIE_NAME = 'Cloud-CDN-Cookie';

export const HEALTH_RECORD_TYPES: SelectOption<HealthRecordTypesEnum>[] = [
  { value: 'care_plan', label: 'Care Plan' },
  { value: 'imaging_result', label: 'Imaging Results' },
  { value: 'lab_result', label: 'Lab Results' },
  { value: 'medical_record', label: 'Medical Records (e.g. IUD card)' },
  { value: 'insurance_card', label: 'Insurance Card' },
  { value: 'legal', label: 'Legal Documents (e.g. Consent to Treat)' },
  { value: 'invoice_or_receipt', label: 'Invoices & Receipts' },
  { value: 'order', label: 'Orders (e.g. Rx, Labs)' },
  { value: 'other', label: 'Other' },
];

export const MAX_ALLOWED_ATTACHMENTS = 4;

import type { Channel } from 'stream-chat';

export const initialSortChannelsComparator = (a: Channel, b: Channel) => {
  const aLastMessageAt = a.state.last_message_at;
  const bLastMessageAt = b.state.last_message_at;

  if (aLastMessageAt && bLastMessageAt) return aLastMessageAt < bLastMessageAt ? 1 : -1;
  if (aLastMessageAt) return -1;
  if (bLastMessageAt) return 1;

  return 0;
};

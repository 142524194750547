import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    alignItems: 'center',
    padding: sizes.SPACING_XXL,
  },
  containerFullScreen: {
    backgroundColor: theme.lightAccent,
    minHeight: '100%',
  },
  title: {
    textAlign: 'center',
    marginBottom: sizes.SPACING_XS,
  },
  subtitle: {
    textAlign: 'center',
    color: theme.secondaryText,
    marginBottom: sizes.SPACING_L,
  },
}));

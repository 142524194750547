import React from 'react';

import { useTheme } from '@almond/ui';

import { PatientVisitItem } from '~/modules/patient';
import { ListModal } from '~/modules/ui';

import { themedStyles } from './styles';

import type { AppointmentInfoOut } from '@almond/api-types';
import type { ModalProps } from '~/modules/ui';

export type PatientVisitsModalProps = Pick<ModalProps, 'isVisible' | 'onRequestClose'> & {
  items: readonly AppointmentInfoOut[];
};

export const PatientVisitsModal: React.FC<PatientVisitsModalProps> = props => {
  const [styles] = useTheme(themedStyles);

  return (
    <ListModal
      {...props}
      title="Patient Visits"
      scrollViewStyle={styles.items}
      keyProp="appointmentTime"
      items={props.items}
      renderItem={item => <PatientVisitItem item={item} />}
    />
  );
};

import React from 'react';

import { useTheme } from '@almond/ui';
import { EventComponent as StreamEventComponent, useChatContext } from 'stream-chat-react';

import { getChannelPatient } from '../../utils';
import { almondTodoEventSchema, TodoMessage } from './TodoMessage';

import { themedStyles } from './styles';

import type { StreamMessage } from 'stream-chat-react';

export type EventComponentProps = {
  /** Message object */
  message: StreamMessage;
};

const EventComponent = (props: EventComponentProps) => {
  const { message } = props;
  const { client, channel } = useChatContext();
  const isPatient = client.user?.role === 'patient';
  const channelPatient = getChannelPatient(channel);
  const [styles] = useTheme(themedStyles);

  const { type } = message;

  if (type !== 'system') {
    return <StreamEventComponent {...props} />;
  }

  const almondTodoEvent = almondTodoEventSchema.safeParse(message.almond_todo_event);

  if (!almondTodoEvent.success || !message.user) {
    return <StreamEventComponent {...props} />;
  }

  return (
    <div className="str-chat__message--system" data-testid="message-system">
      <TodoMessage
        event={almondTodoEvent.data}
        eventCausedByPatient={message.user.role === 'patient'}
        currentUserIsPatient={isPatient}
        patientName={String(channelPatient?.preferred_first_name || channelPatient?.first_name)}
        style={styles.text}
        linkStyle={styles.link}
        channelId={channel?.id}
      />
    </div>
  );
};

const MemoizedEventComponent = React.memo(EventComponent) as typeof EventComponent;

export { MemoizedEventComponent as EventComponent };

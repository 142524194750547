import React from 'react';

import { Button, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

export type LoadMoreButtonProps = {
  onClick: () => void;
  isLoading?: boolean;
  refreshing?: boolean;
};

export const LoadMoreButton = React.memo((props: LoadMoreButtonProps) => {
  const [styles] = useTheme(themedStyles);
  const { isLoading, onClick, refreshing } = props;
  const loading = typeof isLoading === 'undefined' ? refreshing : isLoading;

  return (
    <Button
      aria-label="Load More Channels"
      data-testid="load-more-button"
      isDisabled={loading}
      isLoading={loading}
      onPress={onClick}
      mode="text"
      style={styles.loadMoreButton}
      fixedWidth={false}
    >
      Load More
    </Button>
  );
});

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  mobileTabs: {
    height: 62,
    backgroundColor: theme.lightSecondaryBackground,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  right: {
    flexDirection: 'row',
    gap: sizes.SPACING_L,
    justifyContent: 'flex-end',
  },

  tabs: {
    flexDirection: 'row',
    gap: sizes.SPACING_M,
    alignItems: 'center',
    justifyContent: 'center',
  },

  tab: {
    height: sizes.SPACING_L,
    borderRadius: sizes.SPACING_M,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: sizes.SPACING_M,
    flexDirection: 'row',
    gap: sizes.SPACING_XS,
  },

  tabsNarrow: {
    gap: sizes.SPACING_S,
  },

  tabNarrow: {
    paddingHorizontal: sizes.SPACING_S,
  },

  selectedTab: {
    backgroundColor: theme.accent,
    opacity: 0.7,
  },

  selectTabText: {
    color: theme.lightText,
  },
  indicator: {
    width: sizes.SPACING_S,
    height: sizes.SPACING_S,
    borderRadius: sizes.SPACING_S,
    backgroundColor: theme.accent,
  },

  disabled: {
    opacity: 0.5,
  },
}));

// https://cseducators.stackexchange.com/questions/4425/should-i-teach-that-1-kb-1024-bytes-or-1000-bytes
// Seems like using 1000 will match macOS Finder-displayed file sizes
// Using 1024 will show a different file size than Finder
const orderOfMagnitude = 1000;

const units = ['B', 'KB', 'MB', 'GB'];

export const formatFileSize = (sizeInBytes: number | string): string => {
  if (typeof sizeInBytes === 'string') {
    return sizeInBytes;
  }

  let order = 0;
  let size = sizeInBytes;

  while (size > orderOfMagnitude) {
    order += 1;
    size /= orderOfMagnitude;
  }

  return `${size.toFixed(0)} ${units[order]}`;
};

import { useEffect } from 'react';

import { useEvent } from '@almond/utils';

import { useAlmondApiMutation } from '~/modules/api';

import type { TodoFormActionProps } from '../types';
import type { validationSchema } from '../validations';
import type { TodoDetailOut } from '@almond/api-types';
import type { z } from 'zod';

export const useTodoUpsert = (
  patientUuid: string | undefined,
  model?: Partial<TodoDetailOut> | null
): TodoFormActionProps<TodoDetailOut> => {
  const insert = useAlmondApiMutation(
    'post',
    patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/todos/` : null
  );
  const update = useAlmondApiMutation(
    'put',
    patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/todos/{todo_uuid}/` : null
  );

  const insertReset = insert.reset;
  const updateReset = update.reset;

  useEffect(() => {
    if (model) {
      insertReset();
      updateReset();
    }
  }, [insertReset, updateReset, model]);

  return {
    error: insert.error || update.error,
    isLoading: insert.isMutating || update.isMutating,
    onSubmit: useEvent(async (values: z.infer<typeof validationSchema>) => {
      let data: TodoDetailOut;

      if (model?.uuid) {
        data = await update.trigger({
          ...values,
          todo_uuid: model.uuid,
        });
      } else {
        data = await insert.trigger(values);
      }

      return data;
    }),
  };
};

/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useState } from 'react';

import { useChatContext } from 'stream-chat-react';

import { userIdToConversationId } from '~/modules/user';

import { useChannel } from './useChannel';
import { useStreamEventListener } from './useStreamEventListener';

import type { Event } from 'stream-chat';

export const useUnreadCount = () => {
  const { client } = useChatContext();
  const conversationId = userIdToConversationId(client.user?.id);
  const { channel } = useChannel(conversationId);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (!channel) return;

    const { unreadCount: unread } = channel.state;

    if (typeof unread === 'number') {
      setUnreadCount(unread);
    }
  }, [channel]);

  const processEvent = useCallback((event: Event) => {
    if (typeof event.total_unread_count !== 'number') return;

    setUnreadCount(event.total_unread_count);
  }, []);

  useStreamEventListener(channel, processEvent);

  return unreadCount;
};

import { formatPersonName } from './formatPersonName';

import type { TypingUser } from '../types';
import type { Channel } from 'stream-chat';

export const getPreviewMessageText = (channel: Channel, isTyping: boolean, typingUsers: TypingUser[]) => {
  const latestMessage = channel.state.messages.at(-1);
  const latestMessageText = latestMessage?.text;
  const latestMessageUser = formatPersonName(latestMessage?.user);

  // The latest message isn't specified. There shouldn't be such cases since a welcome message will always be sent.
  if (!latestMessage) return '';
  // Somebody is typing a message,
  if (isTyping && typingUsers.length > 0) return `${formatPersonName(typingUsers[0])} is typing...`;
  // The latest message was deleted.
  if (latestMessage.deleted_at) return 'Message deleted';
  // The latest message has some text.
  if (latestMessageText) return `${latestMessageUser}: ${latestMessageText}`;
  // The latest message includes an attachment,
  if (latestMessage.attachments?.length) return '🏙 Attachment...';

  return 'Empty message...';
};

import { useEvent } from '@almond/utils';
import { v4 } from 'uuid';

import { MAX_ALLOWED_ATTACHMENTS } from '../config';
import { filterUniqueFiles } from '../utils';

import type { DraftAttachment } from '../types';
import type { HealthRecordTypesEnum } from '@almond/api-types';
import type { Dispatch, SetStateAction } from 'react';

export const useAddAttachments = (setAttachments: Dispatch<SetStateAction<DraftAttachment[]>> | undefined) => {
  /**
   * Add attachments to this context's list of attachments
   * @param toAdd The file or files to add as attachments
   * @param shouldUploadToHealthRecords True if these are health records, false if they're shared media
   * @param healthRecordType If health record, the type of health record
   */
  return useEvent(function addAttachment(
    toAdd: File | File[],
    shouldUploadToHealthRecords: boolean,
    healthRecordType?: HealthRecordTypesEnum
  ) {
    if (!setAttachments) {
      throw new Error('Cannot add attachments with no state setter');
    }

    const maxFiles = shouldUploadToHealthRecords ? 1 : MAX_ALLOWED_ATTACHMENTS;

    const toAddArr = (Array.isArray(toAdd) ? toAdd : [toAdd]).map(
      (file): DraftAttachment => ({
        id: v4(),
        file,
        isHealthRecord: shouldUploadToHealthRecords,
        healthRecordType,
      })
    );

    setAttachments(f => filterUniqueFiles([...f, ...toAddArr].filter((_, i) => i < maxFiles)));
  });
};

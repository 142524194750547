.quoted-message-preview-container {
  padding: var(--str-chat__spacing-1) var(--str-chat__spacing-2) var(--str-chat__spacing-2) var(--str-chat__spacing-2);
}

.quoted-message-preview-btn svg {
  transform: scale(0.75);
}

.quoted-message-preview-btn svg path {
  fill: #5b6d8b;
}

import type React from 'react';

const Root: React.FC = () => {
  // We have 2 groups in the app: admin and patient. And they both represent the root of the app.
  // We want to render either admin or patient based on user permissions. But, there always be an initial render
  // of the root route before this check is started. And, the admin one will be picked up as a default root since
  // the pages are alphabetically sorted under the hood of the expo-router.
  // As a result, an additional render of the admin group always exists.
  // We need to prevent it, so the application should include a root route that will be rendered before the user check.
  return null;
};

export default Root;

import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  content: {
    width: '100%',
  },
  category: {
    marginTop: 20,
  },
  categoryTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 12,
  },
  categoryTitleWithItems: {
    marginBottom: 12,
  },
  circleButton: {
    backgroundColor: theme.selectedOption,
    borderRadius: 100,
    alignSelf: 'flex-start',
  },
  items: {
    gap: 20,
  },
}));

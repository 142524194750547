import { useState } from 'react';

import { formatPersonName } from '../utils';
import { useStreamEventListener } from './useStreamEventListener';

import type { TypingUser } from '../types';
import type { Channel } from 'stream-chat';

export const useIsTyping = (channel: Channel) => {
  const membershipId = channel.state.membership.user?.id;
  const [typing, setTyping] = useState<TypingUser[]>([]);

  useStreamEventListener(channel, {
    'typing.start': event => {
      const { user } = event;

      if (!user || user.id === membershipId) return;

      const formattedName = formatPersonName(user);

      setTyping(prevState => [
        ...prevState.filter(typingUser => typingUser.id !== user.id),
        { id: user.id, name: formattedName, role: user.role },
      ]);
    },
    'typing.stop': event => {
      const { user } = event;

      if (!user) return;

      setTyping(prevState => prevState.filter(typingUser => typingUser.id !== user.id));
    },
  });

  // If somebody is typing, but it's not a current stream user.
  return { isTyping: !!typing.length, typingUser: typing };
};

import { useEffect, useMemo, useState } from 'react';

import { useChatContext } from 'stream-chat-react';
import { v4 } from 'uuid';

import { getChannelPatient } from '~/modules/messaging';
import { Error as ErrorUi } from '~/modules/ui';

import { useGetTodos } from '../../hooks';
import { DraftTodoItem } from '../DraftTodoItem';
import { LiveTodoItem } from '../LiveTodoItem';
import { TodoFormModal } from '../TodoFormModal';
import { LoadingTodoList, TodoList } from '../TodoList';
import { useImportTodosFromElation } from './useImportTodosFromElation';

import type { PendingTodoItem } from '../../types';
import type { TodoDetailOut } from '@almond/api-types';

type BulkTodoPageProps = {
  visitNoteId: string;
};

export const BulkTodoPage = (props: BulkTodoPageProps) => {
  const { channel } = useChatContext();
  const patientUuid = getChannelPatient(channel)?.patient_uuid as string | undefined;
  const { active, archived, error, isLoading } = useGetTodos({ patientUuid });
  const [formModel, setFormModel] = useState<Partial<PendingTodoItem> | null>(null);
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});
  const {
    pendingTodos,
    isLoading: isLoadingVisitNote,
    error: pendingError,
  } = useImportTodosFromElation(props.visitNoteId);

  const [itemsToRender, setItemsToRender] = useState<{
    activeTodos?: { dirty: boolean; item: TodoDetailOut }[];
    pendingTodos?: PendingTodoItem[];
  }>({});

  useEffect(() => {
    setItemsToRender(p => ({
      activeTodos: p.activeTodos ?? active.map(item => ({ dirty: false, item })),
      pendingTodos: p.pendingTodos ?? pendingTodos,
    }));
  }, [active, pendingTodos]);

  const listOfTodos = useMemo(
    () => [...(itemsToRender.activeTodos ?? []).map(p => p.item), ...(itemsToRender.pendingTodos ?? [])],
    [itemsToRender]
  );

  if (error || pendingError) {
    return (
      <ErrorUi
        error={
          new Error(
            // eslint-disable-next-line max-len
            `There was an error fetching the to do list. Let us know in #bugs`,
            { cause: error || pendingError }
          )
        }
      />
    );
  }

  let content: React.JSX.Element;

  if (isLoading || isLoadingVisitNote) {
    content = <LoadingTodoList />;
  } else {
    content = (
      <>
        <TodoList
          todos={listOfTodos}
          archivedTodos={archived}
          showAllCategories
          onClickAddTodo={category => {
            setFormModel({ category });
          }}
          renderItem={({ item }) => {
            const onExpand = (isExpanded: boolean) => {
              if (isExpanded) {
                setExpandedItems(e => ({ ...e, [item.uuid]: true }));
              } else {
                setExpandedItems(e => ({ ...e, [item.uuid]: false }));
              }
            };

            if ('isPending' in item) {
              return (
                <DraftTodoItem
                  item={item}
                  isExpanded={expandedItems[item.uuid]}
                  onExpand={onExpand}
                  ctaButtons={[
                    {
                      onPress: () => setFormModel(item),
                      label: 'Edit',
                    },
                    {
                      onPress: () => {
                        setItemsToRender(items => ({
                          ...items,
                          pendingTodos: items.pendingTodos?.filter(i => i.uuid !== item.uuid),
                        }));
                      },
                      label: 'Delete',
                    },
                  ]}
                />
              );
            }

            // TODO queue up edits to live todo items
            return <LiveTodoItem item={item} isExpanded={expandedItems[item.uuid]} onExpand={onExpand} />;
          }}
        />
        <TodoFormModal
          isVisible={!!formModel}
          onRequestClose={() => setFormModel(null)}
          model={formModel}
          onSubmit={async values => {
            if (!formModel) {
              return;
            }

            const newItem: PendingTodoItem = {
              ...formModel,
              ...values,
              isPending: true as const,
              uuid: formModel?.uuid ?? `pending-${v4()}`,
            };

            setItemsToRender(items => ({
              ...items,
              pendingTodos: formModel?.uuid
                ? items.pendingTodos?.map(p => (p.uuid === formModel.uuid ? newItem : p))
                : [...(items.pendingTodos ?? []), newItem],
            }));

            return newItem;
          }}
        />
      </>
    );
  }

  return content;
};

import { useState } from 'react';
import { View } from 'react-native';

import { ActivityIndicator, Button, IconButton, SimpleSelectInput, sizes, useTheme } from '@almond/ui';

import { HEALTH_RECORD_TYPES } from '../../config';

import { themedStyles } from './styles';

import type { ExtendedDocumentOut } from '../../types';
import type { HealthRecordTypesEnum } from '@almond/api-types';

export type MutateButtonsProps = {
  item: ExtendedDocumentOut;
  onDelete?: (item: ExtendedDocumentOut) => void;
  onMoveToHealthRecords?: (item: ExtendedDocumentOut, documentType: HealthRecordTypesEnum) => Promise<void>;
  patientUuid?: string | null;
  onHasMoveIntentChange?: (id: string, hasMoveIntent: boolean) => void;
};

export const MutateButtons: React.FC<MutateButtonsProps> = props => {
  const { item, onDelete, onMoveToHealthRecords, patientUuid, onHasMoveIntentChange } = props;
  const [hasMoveIntent, setHasMoveIntent] = useState(false);
  const [isMoveInProgress, setIsMoveInProgress] = useState(false);
  const [healthRecordType, setHealthRecordType] = useState<HealthRecordTypesEnum | null>(null);
  const [styles, theme] = useTheme(themedStyles);

  const onConfirm = async () => {
    if (!patientUuid || !onMoveToHealthRecords || !healthRecordType) return;

    try {
      setIsMoveInProgress(true);
      await onMoveToHealthRecords(item, healthRecordType);
      setHealthRecordType(null);
    } catch (e) {
      // TODO show error to user
      // eslint-disable-next-line no-console
      console.error(e);

      // In the meantime, re-throw so Sentry sees an uncaught error and we're alerted
      throw e;
    } finally {
      setHasMoveIntent(false);
      onHasMoveIntentChange?.(item.uuid, false);
      setIsMoveInProgress(false);
    }
  };

  const handleMoveToHealthRecordsPress = () => {
    setHasMoveIntent(true);
    onHasMoveIntentChange?.(item.uuid, true);
  };

  if (hasMoveIntent) {
    return (
      <View style={styles.mutateButtonContainer}>
        <View style={styles.selectInputContainer}>
          <SimpleSelectInput
            testID="healthRecordTypeSelect"
            options={HEALTH_RECORD_TYPES}
            value={healthRecordType || undefined}
            onSelect={value => setHealthRecordType(value)}
            closeModalTrigger={hasMoveIntent}
          />
        </View>

        {isMoveInProgress && <ActivityIndicator size={sizes.SPACING_L} color={theme.secondaryTextDark} />}
        {!isMoveInProgress && (
          <IconButton
            source="arrow-circle-up"
            color={healthRecordType ? 'primary' : 'info'}
            onPress={onConfirm}
            isDisabled={!healthRecordType}
          />
        )}
      </View>
    );
  }

  return (
    <View style={styles.mutateButtonContainer}>
      {onMoveToHealthRecords && (
        <Button
          isSquared
          onPress={handleMoveToHealthRecordsPress}
          fixedWidth={false}
          size="s"
          testID="MoveToHealthRecords"
        >
          Move to Health Records
        </Button>
      )}
      {onDelete && (
        <IconButton source="delete" color="secondaryTextDark" aria-label="Delete" onPress={() => onDelete(item)} />
      )}
    </View>
  );
};

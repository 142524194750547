import { Dimensions } from 'react-native';

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  modalContainer: {
    overflowY: 'auto',
  } as any,
  modalContent: {
    maxHeight: Dimensions.get('screen').height * 0.6,
    marginBottom: 0,
  },
  count: {
    color: theme.secondaryTextDark,
    textAlign: 'right',
  },
  countInvalid: {
    color: theme.error,
  },
  description: {
    marginTop: sizes.SPACING_M,
  },
  footer: {
    marginVertical: sizes.SPACING_XL,
    gap: sizes.SPACING_S,
  },
  error: {
    color: theme.error,
    textAlign: 'center',
  },
  submit: {
    marginHorizontal: 'auto',
  },
}));

import React, { useCallback } from 'react';

import { getStylesForWeb, MaterialIcon, Text, useTheme } from '@almond/ui';
import { useDropzone } from 'react-dropzone';

import { Error } from '../Error';

import { themedStyles } from './styles';

import type { Accept } from 'react-dropzone';
import type { StyleProp, ViewStyle } from 'react-native';

export type DragDropFileProps = {
  onDrop: (files: File[]) => void;
  style?: StyleProp<ViewStyle>;
  maxFiles?: number;
  accept?: Accept;
};

export const DragDropFile = ({ onDrop: onDropExternal, style, maxFiles, accept }: DragDropFileProps) => {
  const [styles] = useTheme(themedStyles);

  // TODO switch to useEvent
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Do something with the files
      onDropExternal(acceptedFiles);
    },
    [onDropExternal]
  );
  const { getRootProps, getInputProps, isDragActive, fileRejections, isDragReject } = useDropzone({
    onDrop,
    maxFiles,
    accept,
  });

  const { className, inlineStyle } = getStylesForWeb(
    styles.container,
    isDragActive && styles.containerActive,
    isDragReject && styles.containerReject,
    style
  );

  const dragRejectMessage = `${maxFiles ? `Only ${maxFiles} file${maxFiles > 1 ? 's' : ''} allowed. ` : ''}File${
    maxFiles && maxFiles > 1 ? 's' : ''
  } must not bee too large and can only be PDFs and images.`;

  return (
    <div {...getRootProps()} className={className} style={inlineStyle} data-testid="DragDropFile">
      <input {...getInputProps()} />
      <MaterialIcon source="upload-2" color="secondaryTextDark" size={72} />
      {isDragActive ? (
        <Text size="l" style={styles.text}>
          {isDragReject ? dragRejectMessage : 'Drop files here!'}
        </Text>
      ) : (
        <Text size="l" style={styles.text}>
          {'Drag & drop to upload a file or '}
          <Text style={[styles.text, styles.underline]} size="l">
            choose a file
          </Text>
        </Text>
      )}
      {Array.from(new Set(fileRejections.map(r => r.errors).flat())).map((error, i) => {
        return <Error key={i} error={error} />;
      })}
    </div>
  );
};

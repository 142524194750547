import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    flex: 1,
    backgroundColor: theme.lightSecondaryBackground,
  },

  wave: {
    bottom: 0,
    position: 'absolute',
  },

  loadingBackground: {
    height: '100%',
    backgroundColor: theme.lightAccent,
  },
}));

import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { correlationId } from '../config';

import type { MimeTypes } from '@almond/api-types';

export const useGetHeaders = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useCallback(
    async (contentType?: MimeTypes) => {
      const headers: Record<string, string> = {
        // We need to add x-correlation-id header to all Almond APIs.
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'x-correlation-id': correlationId,
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      };

      if (contentType) {
        headers['Content-Type'] = contentType;
      }

      return headers;
    },
    [getAccessTokenSilently]
  );
};

import { useEffect } from 'react';
import { View } from 'react-native';

import { Button, SENTRY_UNMASK, Text, useTheme } from '@almond/ui';

import { Header, LocalImage } from '~/modules/ui';

import { logErrorToSentry } from '../../utils';

import { themedStyles } from './styles';

import type { ErrorBoundaryProps } from 'expo-router';
import type { StyleProp, ViewStyle } from 'react-native';

type ErrorContainerProps = ErrorBoundaryProps & {
  style?: StyleProp<ViewStyle>;
};

/**
 * To display an error caught within a section that
 * already has a header, and that doesn't need to
 * stretch to the full viewport size
 */
export const ErrorContainer = (props: ErrorContainerProps) => {
  const [styles] = useTheme(themedStyles);

  useEffect(() => {
    logErrorToSentry(props.error);
  }, [props.error]);

  const text =
    process.env.EXPO_PUBLIC_ENV === 'prod'
      ? `Let's try that again! Let us know if the problem is persisting.`
      : props.error.message;

  return (
    <View style={[styles.container, props.style]}>
      <LocalImage resizeMode="contain" height={240} width="100%" source="error" />

      <Text fontStyle="bold" size="xl" style={[styles.title, SENTRY_UNMASK]}>
        Oops, looks like our wires got crossed
      </Text>
      <Text fontStyle="bold" size="m" style={[styles.subtitle, SENTRY_UNMASK]}>
        {text}
      </Text>
      <Button onPress={props.retry} style={SENTRY_UNMASK}>
        Try again
      </Button>
    </View>
  );
};

/**
 * For use when we need to display an error as the only thing
 * rendered on the page, so we need to include a generic header
 * and extend the content the height of the whole window
 */
export const ErrorPage = (props: ErrorBoundaryProps) => {
  const [styles] = useTheme(themedStyles);

  return (
    <>
      <Header title="almond" />
      <ErrorContainer {...props} style={styles.containerFullScreen} />
    </>
  );
};

import React from 'react';

import { LocalImage as NativeLocalImage } from '@almond/ui';

import { useImages } from '~assets';

import type { LocalImageProps as NativeLocalImageProps } from '@almond/ui';
import type { Images } from '~assets';

export type LocalImageProps = Omit<NativeLocalImageProps<Images>, 'images'>;

export const LocalImage: React.FC<LocalImageProps> = props => {
  const images = useImages();

  return <NativeLocalImage {...props} images={images} />;
};

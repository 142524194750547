import React from 'react';
import { useWindowDimensions, View } from 'react-native';

import { IconButton, useTheme } from '@almond/ui';

import { UserMenu } from '~/modules/auth';

import { PATIENT_HEADER_THRESHOLD_WIDTH } from '../../config';
import { usePatientSidebar, useShouldDisplayBackButton } from '../../hooks';
import { Tabs } from './Tabs';

import { themedStyles } from './styles';

export const Right: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const windowDimensions = useWindowDimensions();
  const { canOpenSidebar, toggleSidebar } = usePatientSidebar();
  const shouldDisplayBackButton = useShouldDisplayBackButton();

  return (
    <View style={styles.right}>
      {windowDimensions.width >= PATIENT_HEADER_THRESHOLD_WIDTH && !shouldDisplayBackButton && <Tabs />}
      {canOpenSidebar && <IconButton source="info" color="primary" onPress={toggleSidebar} />}
      {!canOpenSidebar && <UserMenu />}
    </View>
  );
};

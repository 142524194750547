import { useEffect } from 'react';

import { useEvent } from '@almond/utils';
import { useChatContext } from 'stream-chat-react';

import type { CustomEventTypes } from '../types';
import type { Channel, EventHandler, EventTypes } from 'stream-chat';

type Listener = EventHandler;

export type ListenerMap = { [key in CustomEventTypes]?: Listener };

export function useStreamEventListener(
  channel: Channel | null | undefined,
  eventType?: CustomEventTypes,
  listener?: Listener
): void;
export function useStreamEventListener(channel: Channel | null | undefined, listener?: Listener): void;
export function useStreamEventListener(channel: Channel | null | undefined, listenerMap: ListenerMap): void;
export function useStreamEventListener(eventType: CustomEventTypes, listener?: Listener): void;
export function useStreamEventListener(
  arg1: Channel | null | undefined | CustomEventTypes,
  arg2?: CustomEventTypes | Listener | ListenerMap,
  arg3?: Listener
) {
  const { client } = useChatContext();

  const listenerTarget = typeof arg1 === 'string' ? client : arg1;

  let eventType: CustomEventTypes | null;

  if (typeof arg1 === 'string') eventType = arg1;
  else if (typeof arg2 === 'string') eventType = arg2;
  else eventType = null;

  let listenerMap: ListenerMap | null = null;

  if (typeof arg2 === 'object') {
    listenerMap = arg2;
  }

  const handler: Listener = useEvent(event => {
    const singleHandler = typeof arg2 === 'function' ? arg2 : arg3;

    if (singleHandler) {
      return singleHandler(event);
    }

    if (listenerMap) {
      const thisHandler = listenerMap[event.type];

      if (thisHandler) {
        return thisHandler(event);
      }
    }
  });

  useEffect(() => {
    if (!listenerTarget) return;

    const listener = eventType ? listenerTarget.on(eventType as EventTypes, handler) : listenerTarget.on(handler);

    return () => {
      listener.unsubscribe();
    };
  }, [listenerTarget, eventType, handler]);
}

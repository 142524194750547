/* eslint-disable @typescript-eslint/naming-convention,max-statements,max-lines */

/// ///////////////////////////////////////////////////////////
// This component was created by
// 1. Copying the default MessageInputFlat source code from stream-chat-react
// https://github.com/GetStream/stream-chat-react/blob/master/src/components/MessageInput/MessageInputFlat.tsx
// 2. Updating imports
// 3. Removing MediaRecorder, EmojiPicker, QuotedMessagePreviewHeader, LinkPreviewList
// 4. Adding custom props
// 5. Adding custom components at the end of the components tree
//
// We will likely modify this component more in the future. These are the initial
// edits, but check the file's git history for more recent edits.
/// ///////////////////////////////////////////////////////////
import './message-input-flat.css';

import React, { useEffect, useMemo, useState } from 'react';
import { Pressable } from 'react-native';

import { Button, Text, useTheme } from '@almond/ui';
import { useTimeout } from '@almond/utils';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import { useDropzone } from 'react-dropzone';
import {
  ChatAutoComplete,
  SendButton as DefaultSendButton,
  UploadButton,
  UploadIcon as DefaultUploadIcon,
  useChannelActionContext,
  useChannelStateContext,
  useChatContext,
  useComponentContext,
  useMessageInputContext,
  useTranslationContext,
} from 'stream-chat-react';

import { useDraftAttachmentContext, useMaxAttachmentsLeft } from '~/modules/documents';
import { logErrorToSentry } from '~/modules/logging';

import { useStreamEventListener } from '../../hooks';
import { MessagePromptModal } from '../MessagePromptModal';
import { AttachmentPreviewList } from './AttachmentPreviewList';
import { QuotedMessagePreview } from './QuotedMessagePreview';
import { useSendMessage } from './useSendMessage';

import { directStyles, themedStyles } from './styles';

import type { DefaultGenerics, Event } from 'stream-chat';

type MessageInputProps = {
  onUploadButtonClick: (files?: File[]) => void;
  onBeforeSendMessage: () => Promise<unknown>;
  showModalOnUpload: boolean;
};

export const MessageInputFlat = (props: MessageInputProps) => {
  const { quotedMessage } = useChannelStateContext('MessageInputFlat');
  const { setQuotedMessage } = useChannelActionContext<DefaultGenerics>('MessageInputFlat');
  const { channel } = useChatContext('MessageInputFlat');

  const handleQuotedMessageUpdate = (e: Event) => {
    if (e.message?.id !== quotedMessage?.id) return;
    if (e.type === 'message.deleted') {
      setQuotedMessage(undefined);

      return;
    }

    setQuotedMessage(e.message);
  };

  useStreamEventListener(channel, {
    'message.deleted': handleQuotedMessageUpdate,
    'message.updated': handleQuotedMessageUpdate,
  });

  return <MessageInputV2 {...props} />;
};

const accept = {
  'image/*': ['.png', '.jpg', '.jpeg'],
  'application/pdf': ['.pdf'],
};

const MessageInputV2 = (props: MessageInputProps) => {
  const { multipleUploads, quotedMessage } = useChannelStateContext('MessageInputV2');

  const { t } = useTranslationContext('MessageInputV2');

  const { isUploadEnabled, message, text, clearEditingState } = useMessageInputContext('MessageInputV2');

  const { attachments } = useDraftAttachmentContext();
  const maxAttachmentsLeft = useMaxAttachmentsLeft();
  const { client } = useChatContext();

  const { FileUploadIcon = DefaultUploadIcon, SendButton = DefaultSendButton } = useComponentContext('MessageInputV2');

  const id = useMemo(() => nanoid(), []);
  const [styles] = useTheme(themedStyles);

  const [showTooltip, setShowTooltip] = useState(false);
  const [setTimeout, cancelTimeout] = useTimeout();

  const { getRootProps, isDragActive, isDragReject } = useDropzone({
    accept,
    disabled: !isUploadEnabled || maxAttachmentsLeft === 0 || !!message,
    multiple: multipleUploads,
    noClick: true,
    onDrop: props.onUploadButtonClick,
  });

  // TODO: "!message" condition is a temporary fix for shared
  // state when editing a message (fix shared state issue)
  const displayQuotedMessage = !message && quotedMessage && !quotedMessage.parent_id;
  const isAdmin = client?.user?.role === 'provider' || client?.user?.role === 'careguide';
  const CHARACTER_LIMIT = 700;

  const {
    onSendMessage,
    sendMessage,
    isMessageSending,
    isMessagePromptModalVisible,
    toggleIsMessagePromptModalVisible,
  } = useSendMessage(props.onBeforeSendMessage, CHARACTER_LIMIT);

  const isCharacterCountValid = text.length <= CHARACTER_LIMIT;
  const counterClass = (() => {
    if (text.length === 0) {
      return directStyles.noCharacterCount;
    }

    if (text.length <= CHARACTER_LIMIT) {
      return directStyles.characterCountValid;
    }

    return directStyles.characterCountInvalid;
  })();

  const [messageCanNotBeEdited, setMessageCanNotBeEdited] = useState(false);

  useEffect(() => {
    if (messageCanNotBeEdited && message?.id) {
      logErrorToSentry('Message is too big to edit but an edit was attempted by an internal user', {
        messageId: message.id,
      });
    }
  }, [messageCanNotBeEdited, message?.id]);

  if (message) {
    const upcomingEditHistory = Array.from(Array.isArray(message.editHistory) ? message.editHistory : []);

    upcomingEditHistory.push({ date: new Date().toISOString(), previousText: message.text });

    const postEditCustomDataSize = JSON.stringify(upcomingEditHistory).length;

    if (postEditCustomDataSize > 4500) {
      if (!messageCanNotBeEdited) {
        setMessageCanNotBeEdited(true);
      }

      return (
        <div style={{ textAlign: 'center' }}>
          ERROR: Message is too big to edit. It also may have been edited too many times already.
        </div>
      );
    }
  }

  // handleMouseEnter for show tooltip with delay
  const handleMouseEnter = () => {
    if (!isAdmin) return;

    setTimeout(() => {
      setShowTooltip(true);
    }, 500);
  };

  // handleMouseLeave for hide tooltip
  const handleMouseLeave = () => {
    if (!isAdmin) return;

    cancelTimeout();
    setShowTooltip(false);
  };

  return (
    <div
      {...getRootProps({ className: 'str-chat__message-input' })}
      data-testid={message ? 'EditMessageModal' : undefined}
    >
      {message && (
        <Text
          fontStyle="bold"
          size="xxl"
          style={{
            /* @ts-ignore */
            $$css: true,
            _: 'str-chat__edit-title',
          }}
        >
          Edit Message
        </Text>
      )}

      {isDragActive && (
        <div
          className={clsx('str-chat__dropzone-container', {
            'str-chat__dropzone-container--not-accepted': isDragReject,
          })}
        >
          {!isDragReject && <p>{t<string>('Drag your files here')}</p>}
          {isDragReject && <p>{t<string>('Some of the files will not be accepted')}</p>}
        </div>
      )}
      {!isCharacterCountValid && isAdmin && (
        <div>
          <Text size="m" style={styles.characterCountTextError}>
            {'Oops! This message is too long, please send this message as multiple messages :)'}
          </Text>
        </div>
      )}

      {displayQuotedMessage && <QuotedMessagePreview quotedMessage={quotedMessage} />}

      <div className="str-chat__message-input-inner">
        {!message && (
          <div className="str-chat__file-input-container" data-testid="file-upload-button">
            {props.showModalOnUpload ? (
              <Pressable role="button" onPress={() => props.onUploadButtonClick()}>
                <FileUploadIcon />
              </Pressable>
            ) : (
              <>
                <UploadButton
                  accept={Object.values(accept)
                    .flatMap(res => [...res])
                    ?.join(',')}
                  aria-label="File upload"
                  className="str-chat__file-input"
                  data-testid="file-input"
                  disabled={!isUploadEnabled || maxAttachmentsLeft === 0}
                  id={id}
                  multiple={multipleUploads}
                  onFileChange={props.onUploadButtonClick}
                />
                <label className="str-chat__file-input-label" htmlFor={id}>
                  <FileUploadIcon />
                </label>
              </>
            )}
          </div>
        )}
        <div
          className={`str-chat__message-textarea-container ${
            isAdmin && !isCharacterCountValid ? 'str-chat__message-textarea-container-error' : ''
          }`}
        >
          {isUploadEnabled && <AttachmentPreviewList isMessageSending={isMessageSending} />}

          <div className="str-chat__message-textarea-with-emoji-picker">
            <ChatAutoComplete handleSubmit={onSendMessage} />

            <div className="str-chat__message-textarea-emoji-picker" />
          </div>
        </div>
        {/* hide SendButton if this component is rendered in the edit message form */}
        {!message && (
          <div
            style={directStyles.sendButtonContainer}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            data-testid="send-message-button-container"
          >
            <SendButton
              style={isAdmin && !isCharacterCountValid ? directStyles.sendButtonError : {}}
              disabled={
                (isAdmin && !isCharacterCountValid) ||
                !text.length ||
                attachments.some(i => i.cdn?.status === 'uploading') ||
                isMessageSending
              }
              sendMessage={onSendMessage}
            />
            {showTooltip && (
              <div style={directStyles.toolTipContainer}>
                <span style={directStyles.toolTipSpanKey}>⌘</span>
                <span style={directStyles.toolTipSpanKey}>Enter</span>
                <span>to send message</span>
              </div>
            )}
          </div>
        )}
      </div>
      {message && (
        <div className="almond__edit-send-container">
          <Button style={{ width: 140 }} onPress={onSendMessage} isDisabled={!text.length}>
            Save
          </Button>
          <Button type="secondary" style={{ width: 140 }} onPress={clearEditingState}>
            Cancel
          </Button>
        </div>
      )}
      {isAdmin && (
        <div className="almond__character-count-container">
          <span className="almond__character-count" style={counterClass}>
            {text.length} / {CHARACTER_LIMIT}
          </span>
        </div>
      )}
      {text && (
        <MessagePromptModal
          isVisible={isMessagePromptModalVisible}
          onRequestClose={toggleIsMessagePromptModalVisible}
          text={text}
          attachmentsLength={attachments.length}
          onConfirmSend={() => {
            toggleIsMessagePromptModalVisible();
            sendMessage();
          }}
        />
      )}
    </div>
  );
};

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    backgroundColor: theme.lightAccent,
  },
  loading: {
    flexGrow: 0,
  },
  content: {
    paddingVertical: 60,
    height: '100%',
    alignItems: 'center',
    paddingHorizontal: sizes.SPACING_L,
  },
  mobileContent: {
    paddingVertical: sizes.SPACING_L,
  },
  constrainWidth: {
    width: 480,
    maxWidth: '100%',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
  },
  image: {
    width: '100%',
    height: 240,
    aspectRatio: 1,
    transform: [{ scaleX: -1 }],
    marginBottom: sizes.SPACING_L,
  },
  message: {
    textAlign: 'center',
    color: theme.darkText,
    marginBottom: sizes.SPACING_S,
  },
  cta: {
    marginTop: sizes.SPACING_M,
  },
}));

.wrapper {
  gap: 10px;
}

.invalid {
  border: 1px solid var(--color-error);
}
.newLabel {
  color: var(--color-accent);
}

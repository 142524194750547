import { useMemo } from 'react';

import { useAlmondApiQuery } from '~/modules/api';

import { parseTodoFromElation } from './parseTodoFromElation';

export const useImportTodosFromElation = (visitNoteId: string) => {
  const { data, isLoading, error } = useAlmondApiQuery(
    visitNoteId ? `/visit_notes/${visitNoteId as '{visit_note_id}'}/` : null
  );

  const pendingTodos = useMemo(() => {
    return data?.bullets
      .filter(a => a.category === 'Instr')
      .map(item =>
        parseTodoFromElation(
          item.text,
          item.children.filter(child => child.category === 'Instr')
        )
      );
  }, [data]);

  return { pendingTodos, isLoading, error };
};

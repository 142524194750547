import React from 'react';

import { Button, useTheme } from '@almond/ui';

import { useBookUrl } from '~/modules/user';
import { Belly, Reproductive } from '~assets';

import { ItemWithAction } from '../ItemWithAction';
import { SectionWithItems } from '../SectionWithItems';

import { themedStyles } from './styles';

export const GetCare = () => {
  const [styles] = useTheme(themedStyles);
  const generalBookUrl = useBookUrl({ isNewMember: false, category: 'general' });
  const maternityBookUrl = useBookUrl({ isNewMember: false, category: 'maternity' });

  return (
    <SectionWithItems title="Get Care">
      <ItemWithAction
        icon={<Reproductive />}
        title="Gynecology Care"
        action={
          <Button size="s" fixedWidth={false} style={styles.button} href={generalBookUrl}>
            {'Book'}
          </Button>
        }
        separator
      />
      <ItemWithAction
        icon={<Belly />}
        title="Maternity Care"
        action={
          <Button size="s" fixedWidth={false} style={styles.button} href={maternityBookUrl}>
            {'Book'}
          </Button>
        }
      />
    </SectionWithItems>
  );
};

import React from 'react';

import { Header } from '~/modules/ui';

import { Left } from './Left';
import { Right } from './Right';

export const AdminHeader: React.FC = () => {
  return <Header left={<Left />} right={<Right />} />;
};

import { useState } from 'react';

import { useToggle } from '@almond/utils';
import { useChatContext, useMessageInputContext } from 'stream-chat-react';

import type React from 'react';
import type { DefaultGenerics, Message } from 'stream-chat';

export const useSendMessage = (onBeforeSendMessage: () => void, characterLimit: number) => {
  const { handleSubmit, message, textareaRef, text } = useMessageInputContext<DefaultGenerics>('MessageInputV2');
  const { client } = useChatContext();
  const isAdmin = client?.user?.role === 'provider' || client?.user?.role === 'careguide';
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [isMessagePromptModalVisible, toggleIsMessagePromptModalVisible] = useToggle(false);
  const [event, setEvent] = useState<React.BaseSyntheticEvent<object>>();
  const [customMessageData, setCustomMessageData] = useState<Partial<Message<DefaultGenerics>>>();

  const sendMessage = async (e?: React.BaseSyntheticEvent<object>, cmd?: Partial<Message<DefaultGenerics>>) => {
    if (!event && !e) return;

    try {
      setIsMessageSending(true);
      await onBeforeSendMessage();
    } catch (err) {
      if (err instanceof Error) {
        // TODO create toast notification for this error
        if (typeof window !== 'undefined') {
          // eslint-disable-next-line no-alert
          window.alert(err.message);
        }
      }

      throw e;
    } finally {
      setIsMessageSending(false);
    }

    return handleSubmit((event ?? e) as React.BaseSyntheticEvent<object>, customMessageData ?? cmd);
  };

  const onSendMessage = async (e: React.BaseSyntheticEvent<object>, cmd?: Partial<Message<DefaultGenerics>>) => {
    if (!text || text.length > characterLimit) return;

    e.preventDefault();
    textareaRef.current?.focus?.();
    setEvent(e);
    setCustomMessageData(cmd);

    if (isMessageSending) {
      // User already started sending this message. Prevent attempting to send
      // the message or upload the attachments more than once
      return;
    }

    if (isAdmin && !message) {
      toggleIsMessagePromptModalVisible();
    } else {
      // If this is editing an existing message, store the previous edits on the
      // message object
      if (message) {
        const editHistory = Array.isArray(message.editHistory) ? message.editHistory : [];

        if (text !== message.text) {
          editHistory.push({
            date: new Date().toISOString(),
            previousText: message.text,
          });
        }

        // Editing a message
        return handleSubmit(e, { ...(cmd || {}), editHistory });
      }

      sendMessage(e, cmd);
    }
  };

  return {
    onSendMessage,
    sendMessage,
    isMessageSending,
    isMessagePromptModalVisible,
    toggleIsMessagePromptModalVisible,
  };
};

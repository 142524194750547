export const printJestWarnings = (isLoading: boolean, isAuthenticated: boolean) => {
  if (process.env.NODE_ENV === 'test') {
    // When in a Jest environment and API requests aren't working as expected,
    // the issue frequently is that the authentication was not mocked correctly.
    // This warning serves as a reminder when writing tests to check that.

    if (isLoading) {
      // eslint-disable-next-line no-console
      console.warn('No network request from useAlmondApi() because isLoading is true');
    }

    if (!isAuthenticated) {
      // eslint-disable-next-line no-console
      console.warn('No network request from useAlmondApi() because isAuthenticated is false');
    }
  }
};

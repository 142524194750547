import React, { useEffect } from 'react';
import { Image, View } from 'react-native';

import { ActivityIndicator, Button, ScrollView, Text, useBrowserType, useTheme } from '@almond/ui';
import { Link, Redirect, useLocalSearchParams } from 'expo-router';

import { useDocumentViewer } from '~/modules/documents';
import { ErrorContainer } from '~/modules/logging';
import { useDocument } from '~/modules/messaging';
import { PatientPageTitle } from '~/modules/routing';
import { useCurrentUser } from '~/modules/user';
import { useImages } from '~assets';

import { themedStyles } from './styles';

import type { DocumentDetailsParams } from '~/types';

export { ErrorContainer as ErrorBoundary };

const Document: React.FC = () => {
  const browserType = useBrowserType();
  const [styles] = useTheme(themedStyles);
  const currentUser = useCurrentUser();
  const { openDocument } = useDocumentViewer();
  const contentContainerStyle = [styles.content, browserType === 'mobile' && styles.mobileContent];
  const images = useImages();
  const patientUuid = currentUser?.patientUuid as string | null;
  const searchParams = useLocalSearchParams<DocumentDetailsParams>();
  const { document, isLoading, error } = useDocument(patientUuid, searchParams.documentId);

  useEffect(() => {
    if (document) {
      openDocument(document, '_self');
    }
  }, [document, openDocument]);

  if (error) {
    const notFound = error?.info?.errors.find((e: any) => e.error_code === 'not_found');

    const errorText = notFound
      ? 'This document is no longer available - please speak with your care team if you need anything.'
      : 'There was an error loading this document.';

    return (
      <ScrollView style={styles.container} contentContainerStyle={contentContainerStyle}>
        <PatientPageTitle title="Documents" />
        <View style={styles.constrainWidth}>
          <Image source={images.plant1} style={styles.image} resizeMode="contain" />
          <Text size="l" style={styles.message}>
            {errorText}
          </Text>
          <Link
            href="/(patient)/messages"
            // @ts-ignore
            asChild
          >
            <Button style={styles.cta} size="m">
              {'Message my Care Team'}
            </Button>
          </Link>
        </View>
      </ScrollView>
    );
  }

  if (isLoading || !document) {
    return (
      <View style={[styles.container, ...contentContainerStyle]}>
        <PatientPageTitle title="Documents" />

        <ActivityIndicator style={styles.loading} />
      </View>
    );
  }

  if (document.category === 'health_record') {
    return <Redirect href="/(patient)/health-records" />;
  }

  return <Redirect href="/(patient)/messages" />;
};

export default Document;

import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  text: {
    color: theme.secondaryText,
  },
  link: {
    color: theme.primary,
    textDecorationLine: 'underline',
  },
}));

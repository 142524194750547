.str-chat__message-bubble .str-chat__message-text {
  line-height: 1.2;
}

.str-chat__message-bubble .str-chat__message-text p {
  font-size: 16px;
}
.str-chat__message .str-chat__message-inner .str-chat__message-bubble .str-chat__message-text p + p,
.str-chat__quoted-message-preview .str-chat__message-inner .str-chat__message-bubble .str-chat__message-text p + p {
  margin-top: 12px;
}
.str-chat__message .str-chat__message-inner .str-chat__message-bubble .str-chat__message-text p,
.str-chat__quoted-message-preview .str-chat__message-inner .str-chat__message-bubble .str-chat__message-text p {
  hyphens: none;
}

.str-chat__message .str-chat__message-inner .str-chat__message-bubble .str-chat__message-text a:hover,
.str-chat__quoted-message-preview .str-chat__message-inner .str-chat__message-bubble .str-chat__message-text a:hover {
  text-decoration: underline;
}

import React from 'react';

import { Slot } from 'expo-router';

import { Chat } from '~/modules/messaging';
import { PatientHeader } from '~/modules/routing';

const PatientLayout: React.FC = () => {
  return (
    <Chat>
      <PatientHeader />
      <Slot />
    </Chat>
  );
};

export default PatientLayout;

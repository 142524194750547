import React from 'react';

import { useChatContext } from 'stream-chat-react';

import { useChannelStatus } from '../../hooks';
import { HeaderButton } from './HeaderButton';

export const StatusToggleButton = () => {
  const { channel } = useChatContext();
  const { status, statusVisibleToUser, toggleStatus } = useChannelStatus(channel);

  if (!statusVisibleToUser) return null;

  return (
    <HeaderButton
      onPress={toggleStatus}
      testID="StatusToggleButton"
      text={status === 'ongoing' ? 'Resolve' : 'Unresolve'}
    />
  );
};

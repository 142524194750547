import React from 'react';

import { Text, useBrowserType, useTheme } from '@almond/ui';

import { useSidebar } from '~/modules/ui';

import { useIsOnChannelListPage, useIsOnElation } from '../../hooks';
import { BackButton } from './BackButton';

import { themedStyles } from './styles';

export const Left: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const browserType = useBrowserType();
  const { isSidebarOpen } = useSidebar();
  const isOnChannelListPage = useIsOnChannelListPage();
  const isOnElation = useIsOnElation();

  if (browserType === 'mobile' && !isOnChannelListPage && (!isOnElation || isSidebarOpen)) return <BackButton />;

  return (
    <Text fontStyle="bold" size="m" style={styles.logo}>
      {browserType === 'desktop' ? 'internal ' : ''}
      <Text fontStyle="bold" size="m" style={styles.admin}>
        {'dashboard'}
      </Text>
    </Text>
  );
};

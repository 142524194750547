// Array is in order displayed in UI
export const CATEGORIES = [
  { id: 'general', title: 'General' },
  { id: 'follow_ups', title: 'Follow Ups' },
  { id: 'referrals', title: 'Referrals' },
  { id: 'imaging', title: 'Imaging' },
  { id: 'labs', title: 'Labs' },
  { id: 'vaccinations', title: 'Vaccinations' },
  { id: 'medications_and_supplements', title: 'Medications & Supplements' },
  { id: 'read', title: 'Read' },
] as const;

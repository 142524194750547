import { useMemo } from 'react';

import { logErrorToSentry } from '~/modules/logging';

import { useCachedItems } from './useCachedItems';

import type { Channel } from 'stream-chat';

type CachedChannels = {
  channels: Channel[];
  hasNextPage: boolean;
  isLoading: boolean;
  error: Error | null;
  lastRefreshedAt: Date | null;
};

const defaultCachedChannels: CachedChannels = {
  channels: [],
  hasNextPage: false,
  isLoading: false,
  error: null,
  lastRefreshedAt: null,
};

export const useCachedChannels = (
  filterString: string,
  onChannelsChange?: (prevChannels: Channel[], nextChannels: Channel[]) => void
) => {
  const [cachedChannels, setCachedChannels] = useCachedItems<CachedChannels>(filterString, (prevItems, nextItems) =>
    onChannelsChange?.(prevItems?.channels || [], nextItems?.channels || [])
  );

  const functions = useMemo(() => {
    return {
      setIsLoading: (isLoading: boolean) => {
        // TODO manage multiple requests race condition
        setCachedChannels(p => ({
          ...(p || defaultCachedChannels),
          isLoading,
        }));
      },
      setError: (error: Error | null) => {
        logErrorToSentry(error);

        setCachedChannels(p => ({
          ...(p || defaultCachedChannels),
          error,
        }));
      },
      setChannels: (isFullReload: boolean, channels: (oldVal: Channel[]) => Channel[]) => {
        setCachedChannels(p => ({
          ...(p || defaultCachedChannels),
          channels: channels(p?.channels || []),
          lastRefreshedAt: isFullReload ? new Date() : p?.lastRefreshedAt || null,
        }));
      },
      setHasNextPage: (hasNextPage: boolean) => {
        setCachedChannels(p => ({
          ...(p || defaultCachedChannels),
          hasNextPage,
        }));
      },
    };
  }, [setCachedChannels]);

  return useMemo(() => {
    return {
      ...(cachedChannels || defaultCachedChannels),
      ...functions,
    };
  }, [cachedChannels, functions]);
};

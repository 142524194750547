import React from 'react';

import { Button, useTheme } from '@almond/ui';
import { useRouter } from 'expo-router';
import { useChatContext } from 'stream-chat-react';

import { useUnreadCount } from '~/modules/messaging/hooks';
import { isCurrentUserIncomplete } from '~/modules/user';
import { FemaleDoctor } from '~assets';

import { ItemWithAction } from '../ItemWithAction';
import { SectionWithItems } from '../SectionWithItems';

import { themedStyles } from './styles';

export const Messages: React.FC = () => {
  const { client } = useChatContext();
  const { user } = client;
  const unreadCount = useUnreadCount();
  const router = useRouter();
  const [styles] = useTheme(themedStyles);

  if (!user?.role || isCurrentUserIncomplete(user.role)) {
    return null;
  }

  return (
    <SectionWithItems title="Messages">
      <ItemWithAction
        icon={<FemaleDoctor />}
        title="Message Care Team"
        action={
          <Button
            style={styles.button}
            onPress={() => router.push('/(patient)/messages')}
            size="s"
            type={unreadCount > 0 ? 'secondary' : 'primary'}
            fixedWidth={false}
          >
            {unreadCount > 0 ? `View (${unreadCount})` : 'Message'}
          </Button>
        }
      />
    </SectionWithItems>
  );
};

import React, { useEffect, useState } from 'react';
import { Pressable } from 'react-native';

import { Avatar, BaseModal, useTheme } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';

import { useAlmondApiQuery } from '~/modules/api';
import { useRerender } from '~/modules/ui';
import { getFullName, useCurrentUser } from '~/modules/user';

import { Menu } from './Menu';

import { themedStyles } from './styles';

export const UserMenu: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const { client } = useChatContext();
  const [isVisible, setIsVisible] = useState(false);
  const rerender = useRerender();
  const { user: streamUser } = client;
  const currentUser = useCurrentUser();
  const { data: currentPatient, isLoading: isLoadingCurrentPatient } = useAlmondApiQuery(
    currentUser?.patientUuid ? `/patients/${currentUser?.patientUuid as '{patient_uuid}'}/` : null
  );

  useEffect(() => {
    if (streamUser && Object.keys(streamUser).length > 1) return;

    const listener = client.on('health.check', () => {
      // Sometimes the user from the client includes only 1 field (id).
      // As soon as this event is fired, we need to rerender the component to have the correct user.
      rerender();
    });

    return () => {
      listener.unsubscribe();
    };
  }, [client, rerender, streamUser]);

  if (!streamUser || isLoadingCurrentPatient) return <Avatar isLoading />;

  const user = (() => {
    if (!currentPatient) {
      return streamUser;
    }

    const { firstName, preferredFirstName, lastName } = currentPatient.profile;

    return {
      id: streamUser.id,
      name:
        streamUser.name || [firstName, preferredFirstName ? `"${preferredFirstName}"` : undefined, lastName].join(' '),
      first_name: streamUser.first_name || firstName,
      preferred_first_name: streamUser.preferred_first_name || preferredFirstName,
      last_name: streamUser.last_name || lastName,
      image: streamUser.image,
    };
  })();

  const handlePress = () => {
    setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      <Pressable onPress={handlePress} role="button">
        <Avatar user={user} image={user?.image} testID="UserMenu-Avatar" />
      </Pressable>
      <BaseModal isVisible={isVisible} onRequestClose={handleClose} backdropStyle={styles.modalBackdrop}>
        <Menu name={getFullName(user)} onClose={handleClose} />
      </BaseModal>
    </>
  );
};

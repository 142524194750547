import { useEvent } from '@almond/utils';

import { useAlmondApiMutation } from '~/modules/api';

import type { DraftAttachment } from '../types';
import type { MultipartForm } from '@almond/api-types';

export const useUploadFile = (patientUuid: string | undefined) => {
  const { trigger: uploadFile } = useAlmondApiMutation<
    '/patients/{patient_uuid}/documents/upload/',
    'post',
    MultipartForm
  >('post', `/patients/${patientUuid as '{patient_uuid}'}/documents/upload/`);

  const { trigger: uploadElationDocument } = useAlmondApiMutation(
    'post',
    '/patients/{patient_uuid}/reports/{elation_report_id}/attachments/'
  );

  return useEvent(async (attachment: DraftAttachment) => {
    if (!patientUuid) throw new Error('Patient UUID is not defined');

    if ('elationDocumentId' in attachment) {
      return uploadElationDocument({ patient_uuid: patientUuid, elation_report_id: attachment.elationDocumentId });
    }

    const body = new FormData();

    body.append('file', attachment.file);

    const result = await uploadFile(body);

    return {
      ...result,
      documentType: attachment.healthRecordType,
    };
  });
};

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  container: {
    // 50% width children
    flexWrap: 'wrap',
    flexDirection: 'column',
    margin: -sizes.SPACING_XS,
  },
  itemContainer: {
    maxWidth: '100%',
  },
  item: {
    padding: sizes.SPACING_XXS,
    height: sizes.SPACING_XL,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: sizes.SPACING_S,
  },
  thumbnail: {
    borderRadius: sizes.SPACING_XS,
  },
  button: {
    marginTop: sizes.SPACING_S,
  },

  // Inside modal
  items: {
    marginHorizontal: sizes.SPACING_L,
    marginTop: sizes.SPACING_XXL,
  },
}));

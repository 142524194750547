import levenshtein from 'js-levenshtein';

const MAXIMUM_DISTANCE = 3;

export const getClosestCategory = <Id extends string>(
  categories: readonly { id: Id; title: string }[],
  enteredCategory: string | undefined
) => {
  if (!enteredCategory) {
    return undefined;
  }

  const lowerCaseCategory = enteredCategory.toLowerCase();

  let currentCategory: (typeof categories)[number] | undefined;
  let currentDistance = MAXIMUM_DISTANCE;

  for (let i = 0; i < categories.length; i += 1) {
    const category = categories[i];
    const distance = levenshtein(lowerCaseCategory, category.title.toLowerCase());

    if (distance <= currentDistance) {
      currentCategory = category;
      currentDistance = distance;
    }
  }

  return currentCategory;
};

import { useState } from 'react';
import { View } from 'react-native';

import { useTheme } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';

import { MediumPreview, useDocumentViewer } from '~/modules/documents';
import { Placeholder, SidebarButton } from '~/modules/ui';

import { useDocuments } from '../../hooks';
import { getChannelPatient } from '../../utils';
import { SharedMediaModal } from './SharedMediaModal';

import { themedStyles } from './styles';

import type { FC } from 'react';

type SharedMediaProps = {
  editable?: boolean;
};

const PREVIEW_COUNT = 4;

export const SharedMedia: FC<SharedMediaProps> = props => {
  const [styles] = useTheme(themedStyles);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { channel } = useChatContext();
  const { openDocument, isDocumentOpen } = useDocumentViewer();
  // TODO: strictly type this
  const patientUuid = getChannelPatient(channel)?.patient_uuid as string | null;
  const { data } = useDocuments(patientUuid, 'media');

  const items = (data || []).slice(0, PREVIEW_COUNT);

  // Internal - show if >= 0
  // Patient - show if > PREVIEW_COUNT
  const showModalButton = data?.length && (props.editable || data.length > PREVIEW_COUNT);

  return (
    <View testID="SharedMedia">
      <View style={styles.container} role="list">
        {items.map(d => (
          <View key={d.uuid} style={styles.item} role="listitem">
            <MediumPreview document={d} onPress={() => openDocument(d)} />
          </View>
        ))}
      </View>
      {!data?.length && <Placeholder>No media items</Placeholder>}
      {showModalButton ? (
        <SidebarButton style={styles.button} onPress={() => setIsModalVisible(true)} testID="ViewAllMedia">
          View All Media
        </SidebarButton>
      ) : null}
      <SharedMediaModal
        patientUuid={patientUuid as string}
        isVisible={isModalVisible && !isDocumentOpen}
        onRequestClose={() => setIsModalVisible(false)}
        items={data || []}
        editable={props.editable}
        onOpenPreview={d => openDocument(d)}
      />
    </View>
  );
};

import { z } from 'zod';

import type { ChannelData, ChannelResponse } from 'stream-chat';

const almondChannelData = z.object({
  id: z.string(),
  name: z.string(),
  first_name: z.union([z.string(), z.null()]).optional(),
  last_name: z.union([z.string(), z.null()]).optional(),
  preferred_first_name: z.union([z.string(), z.null()]).optional(),
  birthday: z.string().regex(/^\d{2}\/\d{2}\/\d{4}$/),
  image: z.string().url().optional(),
  status: z.enum(['ongoing', 'resolved']).optional(),
});

export const parseChannelData = (data?: ChannelResponse | ChannelData) => {
  if (!data) return null;

  return almondChannelData.parse(data);
};

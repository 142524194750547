import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { ActivityIndicator, useBrowserType, useTheme } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';

import { ErrorContainer } from '~/modules/logging';
import { Channel, ChannelHeader, MessageInput, MessageList, PatientSidebar, useChannel } from '~/modules/messaging';
import { PatientPageTitle, usePatientSidebar } from '~/modules/routing';
import { userIdToConversationId } from '~/modules/user';

import { themedStyles } from './styles';

export { ErrorContainer as ErrorBoundary };

const PatientMessages: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const { client } = useChatContext();
  const browserType = useBrowserType();
  const conversationId = userIdToConversationId(client.user?.id);
  const { channel } = useChannel(conversationId, true);
  const { isSidebarOpen } = usePatientSidebar();

  const content = useMemo(() => {
    const conversation = (
      <>
        <ChannelHeader />
        <View style={styles.channel}>
          {channel && (
            <View style={[styles.channelWindow, StyleSheet.absoluteFill]}>
              <Channel channel={channel} role="patient">
                <View style={styles.channelContent}>
                  <MessageList addParamsToLabLink />
                  <MessageInput />
                </View>
              </Channel>
            </View>
          )}
          {!channel && <ActivityIndicator />}
        </View>
      </>
    );

    return (
      <View style={styles.contentContainer} testID="page">
        {isSidebarOpen ? <PatientSidebar /> : conversation}
      </View>
    );
  }, [channel, isSidebarOpen, styles.channel, styles.channelContent, styles.channelWindow, styles.contentContainer]);

  return (
    <View style={styles.container}>
      <PatientPageTitle title="Messages" />

      {/*
       * Order reversed with flexDirection: row-reverse. So
       * clicking an image brings up the image viewer overlay
       * on top of the sidebar. If we aren't using the default
       * Stream image view overlay, we can switch this to "row"
       * and swap the order of the chat window and sidebar in
       * the DOM.
       */}
      {browserType === 'desktop' && <PatientSidebar />}
      {content}
    </View>
  );
};

export default PatientMessages;

import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    ...getShadow(),
    backgroundColor: theme.lightPrimary,
    padding: sizes.SPACING_L,
    borderRadius: sizes.SPACING_L,
    rowGap: sizes.SPACING_M,
    alignItems: 'center',
  },

  title: {
    color: theme.secondaryTextDark,
    textAlign: 'center',
  },

  titleMobile: {
    marginHorizontal: sizes.SPACING_XXL,
  },
}));

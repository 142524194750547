/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import { Appearance } from 'react-native';

export const lightImages = {
  plant1: require('./meditations/plant1.webp') as number,
  plant2: require('./meditations/plant2.webp') as number,
  universe1: require('./meditations/universe1.webp') as number,
  universe2: require('./meditations/universe2.webp') as number,
  universe3: require('./meditations/universe3.webp') as number,
  error: require('./meditations/error.webp') as number,
};

export type Images = typeof lightImages;

// We don't support multiple themes now, but it'll be possible in the future.
export const darkImages: Images = lightImages;

export const images: Images = Appearance.getColorScheme() === 'dark' ? darkImages : lightImages;

// We only support images and PDFs for now
export type FileType = 'pdf' | 'image';

export const getFileType = (fileName: string): FileType => {
  const lowercaseFileName = fileName.toLowerCase();

  if (lowercaseFileName.endsWith('.pdf')) {
    return 'pdf';
  }

  return 'image';
};

import React from 'react';

import { useBrowserTypeMap, useTheme } from '@almond/ui';

import { Modal, type ModalProps } from '~/modules/ui';

import { themedStyles } from './styles';

import type { PatientDetailOut } from '@almond/api-types';

export type BaseVerificationModalProps = ModalProps & {
  patient: PatientDetailOut;
};

export const BaseVerificationModal = (props: BaseVerificationModalProps) => {
  const { patient, ...restProps } = props;
  const [styles] = useTheme(themedStyles);
  const { isMobile } = useBrowserTypeMap();

  return (
    <Modal
      {...restProps}
      showClose
      containerStyle={[styles.modalContainer, isMobile && styles.modalContainerMobile]}
      contentContainerStyle={styles.modalContentContainer}
    />
  );
};

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  upcoming: {
    color: theme.darkText,
    marginBottom: sizes.SPACING_XS,
  },

  items: {
    marginHorizontal: 80,
    marginTop: sizes.SPACING_XXL,
  },

  previewItems: {
    marginBottom: sizes.SPACING_S,
  },

  itemPreview: {
    paddingVertical: sizes.SPACING_XXS,
  },

  itemTitle: {
    color: theme.darkText,
  },

  buttons: {
    rowGap: sizes.SPACING_S,
  },
}));

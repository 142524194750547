import { LOG_DATE_TIME_FORMAT } from '@almond/date-and-time';
import { captureException } from '@sentry/browser';
import dayjs from 'dayjs';

const formatTimestamp = (): string => dayjs().format(LOG_DATE_TIME_FORMAT);

export const logErrorToSentry = (message: any, details: Record<string, string> = {}) => {
  if (!message) {
    return;
  }

  if (process.env.NODE_ENV === 'test') {
    return;
  }

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    return console.log(`SENTRY LOGGER: ${message}`, details);
  }

  captureException(message, scope =>
    // Standard context fields for all messages logged
    scope
      .setContext('Message Context', {
        timestamp: formatTimestamp(),
        application: 'artemis',
      })
      .setContext('Message Details', details)
  );
};

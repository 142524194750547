import { Slot, useGlobalSearchParams } from 'expo-router';

import { useAlmondApiQuery } from '~/modules/api';
import { ErrorContainer } from '~/modules/logging';
import { useChannel } from '~/modules/messaging';
import { AdminPatientLayout } from '~/modules/routing';

import type { ElationDetailsParams } from '~/types';

export { ErrorContainer as ErrorBoundary };

export default () => {
  const { elationId } = useGlobalSearchParams<ElationDetailsParams>();

  const { data, error } = useAlmondApiQuery(
    elationId ? `/patients/by_elation/${elationId as '{elation_patient_id}'}` : null
  );

  const conversationId = data?.authId ? data.authId.replace('auth0|', '') : undefined;

  useChannel(conversationId, !!conversationId);

  if (error) {
    throw error;
  }

  return (
    <AdminPatientLayout showJoinInThread>
      <Slot />
    </AdminPatientLayout>
  );
};

import React from 'react';

import { useChatContext } from 'stream-chat-react';

import { useMarkAsUnread } from '../../hooks';
import { getCareguideIds } from '../../utils';
import { HeaderButton } from './HeaderButton';

export const MarkAsUnreadButton = () => {
  const { channel } = useChatContext();
  const { isAvailable, isDisabled, markAsUnread } = useMarkAsUnread(channel);

  if (!isAvailable) return null;

  return (
    <HeaderButton
      isDisabled={isDisabled}
      onPress={() => channel && markAsUnread(getCareguideIds(channel))}
      testID="MarkAsUnreadButton"
      text="Mark as unread"
    />
  );
};

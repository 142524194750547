import { BaseButton, Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { BaseButtonProps } from '@almond/ui';

export const EditRightAction = (props: Omit<BaseButtonProps, 'style'>) => {
  const [styles] = useTheme(themedStyles);

  return (
    <BaseButton {...props} style={styles.rightAction} testID="Edit">
      <Text style={styles.rightActionText} size="xs">
        {'Edit'}
      </Text>
    </BaseButton>
  );
};

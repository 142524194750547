import { z } from 'zod';

import { CATEGORIES } from './config';

export const TITLE_MAX_LENGTH = 95;

export const validationSchema = z.object({
  category: z.enum(
    // Some Typescript fiddling to get this to work with z.enum()
    CATEGORIES.map(c => c.id) as [(typeof CATEGORIES)[number]['id'], ...(typeof CATEGORIES)[number]['id'][]]
  ),
  title: z
    .string()
    .max(TITLE_MAX_LENGTH, { message: 'To Do name is too long, fix to publish' })
    .min(1, { message: 'No item name, update item name to publish' }),
  description: z.string(),
});

export const safeParseValidation = (item: unknown) =>
  validationSchema.safeParse(item, {
    errorMap: (issue, ctx) => {
      if (issue.message) {
        return { message: issue.message };
      }

      if (issue.code === 'invalid_enum_value') {
        return { message: `"${ctx.data}" is an unknown category, choose a category to publish` };
      }

      if (issue.code === 'invalid_type') {
        return { message: 'No category selected, choose a category to publish' };
      }

      return { message: ctx.defaultError };
    },
  });

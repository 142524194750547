import React from 'react';
import { useWindowDimensions, View } from 'react-native';

import { useTheme } from '@almond/ui';

import { Header } from '~/modules/ui';

import { PATIENT_HEADER_THRESHOLD_WIDTH } from '../../config';
import { usePatientSidebar, useShouldDisplayBackButton } from '../../hooks';
import { BackButton } from './BackButton';
import { Right } from './Right';
import { Tabs } from './Tabs';

import { themedStyles } from './styles';

export const PatientHeader: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const windowDimensions = useWindowDimensions();
  const { isSidebarOpen } = usePatientSidebar();
  const shouldDisplayBackButton = useShouldDisplayBackButton();
  const showMobileTabs = windowDimensions.width < PATIENT_HEADER_THRESHOLD_WIDTH;

  return (
    <View testID="PatientHeader">
      <Header
        left={(shouldDisplayBackButton || isSidebarOpen) && <BackButton />}
        right={<Right />}
        leftAlignTitle={!showMobileTabs}
      />
      {showMobileTabs && !isSidebarOpen && !shouldDisplayBackButton && (
        <View style={styles.mobileTabs}>
          <Tabs />
        </View>
      )}
    </View>
  );
};

import React, { useEffect } from 'react';

import { auth0IdSchema, getAuth0IdSchema, WindowMessageReceiver } from '@almond/extension-utils';
import { cypressUtilities } from '@almond/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalSearchParams, usePathname } from 'expo-router';

import { Error, LoadingWithBackground } from '~/modules/ui';

import { authenticateForExtension } from './authenticateForExtension';

const notSupportedBrowser = typeof Array.prototype.at !== 'function';
const isInIframe = () => {
  return window.parent && window.parent !== window && !cypressUtilities.isCypressRunning();
};

const RequireAuthentication: React.FC<React.PropsWithChildren> = props => {
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, user, error } = useAuth0();
  const pathname = usePathname();
  const searchParams = useGlobalSearchParams();

  useEffect(() => {
    if (isLoading || isAuthenticated) return;

    const toCall = async () => {
      // If the user is logged in via Demi, isAuthenticated
      // will still be false. Try to get the access token from
      // the Demi session, but if that fails, redirect to the
      // login page
      try {
        await getAccessTokenSilently();
      } catch (e) {
        await loginWithRedirect({
          appState: {
            returnTo: pathname,
            params: searchParams,
          },
          authorizationParams: {
            prompt: 'login',
            screen_hint: 'login',
          },
          openUrl(url) {
            if (isInIframe()) {
              // We're in an iframe. Auth0 can't open in an iframe,
              // so instead open it in a new tab.
              authenticateForExtension(url);
            } else {
              window.location.assign(url);
            }
          },
        });
      }
    };

    toCall();
  }, [isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently, pathname, searchParams]);

  useEffect(() => {
    if (!isAuthenticated || !user?.sub || !isInIframe()) {
      return;
    }

    const messageReceiver = new WindowMessageReceiver(window, getAuth0IdSchema, { fromExtension: true });

    messageReceiver.addListener(async () => {
      // WARNING - this will expose this value to any browser extension that embeds
      // Artemis in an iframe. This is ok, because a) the risk of a user installing
      // an extension that tries to get this data is low, and b) even if they do, the
      // auth0 ID is not very sensitive.
      return auth0IdSchema.parse({
        type: 'auth0_id',
        id: user.sub,
      });
    });

    return () => {
      messageReceiver.removeListener();
    };
  }, [isAuthenticated, user]);

  const showLoading = isLoading || !isAuthenticated || !user;

  if (showLoading) return <LoadingWithBackground />;
  if (error) return <Error error={error} />;

  return props.children;
};

const NotSupported = () => {
  // Put as early in the application lifecycle as possible
  window.location.replace('/not-supported.html');

  return null;
};

const toExport = notSupportedBrowser ? NotSupported : RequireAuthentication;

export { toExport as RequireAuthentication };

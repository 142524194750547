import { StyleSheet } from 'react-native';

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    borderRadius: sizes.SPACING_S,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.skeletonLoader,
    width: '100%',
    overflow: 'hidden',
  },

  header: {
    height: 41,
    backgroundColor: theme.skeletonLoader,
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: sizes.SPACING_S,
    alignItems: 'center',
    paddingHorizontal: sizes.SPACING_M,
  },

  contentContainer: {
    padding: sizes.SPACING_M,
  },
}));

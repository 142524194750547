import { ErrorContainer } from '~/modules/logging';
import { AdminTodoPage, TodoPageWrapper } from '~/modules/todos';

export { ErrorContainer as ErrorBoundary };

export default () => {
  return (
    <TodoPageWrapper>
      {/* TODO render different page for bulk editing page */}
      <AdminTodoPage />
    </TodoPageWrapper>
  );
};

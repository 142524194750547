import { useState } from 'react';
import { Pressable, View } from 'react-native';

import { Text, useTheme } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';

import { formatFileName, useDocumentViewer } from '~/modules/documents';
import { FileThumbnail } from '~/modules/documents/components/FileThumbnail';
import { Placeholder, SidebarButton } from '~/modules/ui';

import { useDocuments } from '../../hooks';
import { getChannelPatient } from '../../utils';
import { HealthRecordsModal } from './HealthRecordsModal';

import { themedStyles } from './styles';

export const HealthRecords = () => {
  const [styles] = useTheme(themedStyles);
  const { channel } = useChatContext();
  const { openDocument, isDocumentOpen } = useDocumentViewer();
  const [isModalVisible, setIsModalVisible] = useState(false);
  // TODO: strictly type this
  const patientUuid = getChannelPatient(channel)?.patient_uuid as string | null;

  const { data } = useDocuments(patientUuid, 'health_record');

  const items = (data || []).slice(0, 4);

  return (
    <View testID="HealthRecords">
      <View style={styles.container} role="list">
        {items.map(d => (
          <View key={d.uuid} role="listitem" style={styles.itemContainer}>
            <Pressable role="button" style={styles.item} onPress={() => openDocument(d)} aria-label={d.filename}>
              <FileThumbnail filename={d.filename} fileLocation={d.fileLocation} style={styles.thumbnail} />
              <Text numberOfLines={1} fontStyle="book" size="s">
                {formatFileName(d.filename)}
              </Text>
            </Pressable>
          </View>
        ))}
      </View>
      {!data?.length && <Placeholder>No Health Records</Placeholder>}
      {data?.length ? (
        <SidebarButton style={styles.button} onPress={() => setIsModalVisible(true)} testID="ViewAllRecords">
          View All Records
        </SidebarButton>
      ) : null}
      <HealthRecordsModal
        patientUuid={patientUuid as string}
        isVisible={isModalVisible && !isDocumentOpen}
        onRequestClose={() => setIsModalVisible(false)}
        items={data || []}
        onOpenPreview={d => openDocument(d)}
      />
    </View>
  );
};

import groupBy from 'object.groupby';

import { logErrorToSentry } from '~/modules/logging';

import { CATEGORIES } from '../config';

export const noCategory = Symbol('noCategory');

export function groupTodosIntoCategories<TodoItem extends { category: string; uuid: string }>(
  todos: readonly TodoItem[]
): Record<string, TodoItem[]>;
export function groupTodosIntoCategories<TodoItem extends { category?: string; uuid: string }>(
  todos: readonly TodoItem[]
): Record<string | typeof noCategory, TodoItem[]>;
export function groupTodosIntoCategories<TodoItem extends { category?: string; uuid: string }>(
  todos: readonly TodoItem[]
) {
  const unknownCategory = todos.find(t => t.category && !CATEGORIES.find(c => c.id === t.category));

  if (unknownCategory && !('isPending' in unknownCategory && unknownCategory.isPending)) {
    // Ignore the unknown category, but log to Sentry so we know of the issue
    logErrorToSentry('A todo was returned with an unknown category', {
      todo_uuid: unknownCategory.uuid,
      category: unknownCategory.category ?? '*NONE*',
    });
  }

  return groupBy(todos, todo => {
    // Only group by KNOWN categories. Otherwise, consider it "no category"
    const category = CATEGORIES.find(c => c.id === todo.category);

    return category?.id ?? noCategory;
  });
}

import { StyleSheet } from 'react-native';

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  button: {
    marginTop: sizes.SPACING_S,
  },
  accordion: {
    borderBottomColor: theme.border,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
}));

import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { View } from 'react-native';

import { useTheme } from '@almond/ui';
import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';

import { useAlmondApiQuery } from '~/modules/api';
import { CDN_COOKIE_NAME } from '~/modules/documents/config';
import { Error, LoadingWithBackground } from '~/modules/ui';
import { env } from '~env';

import { useForceRouteGroup } from '../../hooks';
import { authIdToStreamId } from '../../services';

import { themedStyles } from './styles';

import type { ExtendedUserDetail } from '../../types';

type CurrentUserContext = undefined | ExtendedUserDetail;

const CurrentUserContext = createContext<CurrentUserContext>(undefined);

// As soon as `useLogout` was implemented, I received a lot of cyclic dependencies. I played with different directories,
// but they were still presented. The reason is that the `user` module imports something from the `api` module.
// So, we have to pass this function manually from the root of the app.
// It's called only in one place, so I don't think it's too critical.
type CurrentUserStateProviderProps = React.PropsWithChildren;

export const CurrentUserStateProvider: React.FC<CurrentUserStateProviderProps> = props => {
  const [styles] = useTheme(themedStyles);
  const { data, isLoading, error } = useAlmondApiQuery('/users/me', {
    // We want to make sure the CDN_COOKIE value is re-fetched more frequently than it will expire.
    // We don't want to risk expired CDN tokens, since we can't detect the difference between a
    // 404 and a 403 when loading CDN images. This should be sufficient to ensure that every user
    // gets their CDN token recalculated frequently enough that it won't expire unless the Auth0
    // token expires, at which point the user will be redirected to the login page.
    revalidateOnReconnect: true,
    revalidateOnFocus: true,
  });
  const currentUser = useMemo(
    () =>
      data && {
        ...data,
        streamId: authIdToStreamId(data.authId),
      },
    [data]
  );

  useForceRouteGroup(data);
  useEffect(() => {
    if (!data) return;

    Sentry.setUser({ id: data.authId });
    Cookies.remove(CDN_COOKIE_NAME);
    Cookies.set(CDN_COOKIE_NAME, data.cdnCookie, {
      domain: env.APEX_DOMAIN,
      sameSite: 'None',
      secure: true,
      expires: 1,
    });
  }, [data]);

  return (
    <CurrentUserContext.Provider value={currentUser}>
      {isLoading && <LoadingWithBackground />}
      {error && <Error error={error} />}
      {/* Need to always render children, even if they're not displayed. The Stack always needs
       to exist so the URL knows it matches with a valid route. */}
      <View style={isLoading || error ? styles.hidden : styles.container}>{props.children}</View>
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = () => useContext(CurrentUserContext);

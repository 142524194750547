import { StyleSheet } from 'react-native';

import { sizes, styleSheetFactory, SYSTEM_FONT_FAMILY } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  textField: {
    flexDirection: 'row',
    gap: sizes.SPACING_XS,
    marginBottom: sizes.SPACING_S,
    flexWrap: 'wrap',
  },

  text: {
    color: theme.darkText,
  },
  tagsContainer: {
    width: '100%',
  },
  bulletItem: {
    marginBottom: sizes.SPACING_XS,
  },
  bullet: {
    fontSize: sizes.FONT_SIZE_M,
    marginRight: sizes.SPACING_XS,
  },
  loading: {
    alignItems: 'flex-start',
  },
  copyButton: {
    height: sizes.SPACING_L,
    backgroundColor: 'transparent',
    borderColor: theme.secondaryTextDark,
    borderWidth: StyleSheet.hairlineWidth,
    width: 'auto',
    // Prevent button from pushing next line down
    marginVertical: -sizes.SPACING_XS,
  },
  copyButtonLabel: {
    color: theme.secondaryTextDark,
    fontFamily: SYSTEM_FONT_FAMILY,
    fontSize: sizes.FONT_SIZE_XXS,
    marginHorizontal: 0,
  },
  elation: {
    backgroundColor: theme.secondaryTextDark,
  },
}));

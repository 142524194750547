import React from 'react';

import { Button, useBrowserTypeMap, useTheme } from '@almond/ui';
import { useGlobalSearchParams, useRouter } from 'expo-router';
import { useChatContext } from 'stream-chat-react';

import { useAlmondApiQuery } from '~/modules/api';
import { useChannel } from '~/modules/messaging/hooks';
import { getChannelPatient } from '~/modules/messaging/utils';
import { Error } from '~/modules/ui';
import { userIdToConversationId } from '~/modules/user';

import { ReferralModal } from '../ReferralModal';

import { themedStyles } from './styles';

export const ReferButton = () => {
  const [styles] = useTheme(themedStyles);
  const router = useRouter();
  const searchParams = useGlobalSearchParams();
  const isVisible = searchParams.referral === 'true';
  const { client } = useChatContext();
  const conversationId = userIdToConversationId(client.user?.id);
  const { channel } = useChannel(conversationId, true);
  const patientUuid = getChannelPatient(channel)?.patient_uuid as string | null;
  const { data, error, isLoading } = useAlmondApiQuery(
    patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/` : null,
    {
      // This combination is relevant to useSWRImmutable.
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const { isMobile } = useBrowserTypeMap();

  if (error) return <Error error="Error loading referral code. Please refresh the page." />;

  return (
    <>
      <Button
        style={[styles.button, isMobile && styles.buttonMobile]}
        // @ts-ignore
        onPress={() => router.setParams({ referral: 'true' })}
        isLoading={isLoading}
        isDisabled={isLoading}
        size="s"
        fixedWidth={false}
        testID="ReferFriends"
      >
        {'Refer friends'}
      </Button>
      <ReferralModal
        isVisible={isVisible}
        // @ts-ignore
        onRequestClose={() => router.setParams({ referral: undefined })}
        referralCode={data?.referralCode}
      />
    </>
  );
};

.wrapper {
  display: grid;

  /* Copied from <BaseTodoItem> */
  --horizontal-padding: 18px;
  grid-template-columns: var(--horizontal-padding) 20px auto 20px var(--horizontal-padding);

  column-gap: 8px;
  align-items: center;
  border-radius: 8px;
  padding-block: 14px;
}

.error {
  background: var(--color-light-error);
}
.info {
  background: var(--color-info);
}
.text {
  color: var(--color-primary-text);
  grid-column: 3 / -2;
}

.leftIcon {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

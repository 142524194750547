import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  textField: {
    flexDirection: 'row',
    gap: sizes.SPACING_XS,
    marginBottom: sizes.SPACING_S,
  },

  text: {
    color: theme.darkText,
  },

  searchbar: {
    alignSelf: 'center',
    marginTop: sizes.SPACING_M,
    borderWidth: 1,
    borderColor: theme.border,
    overflow: 'hidden',
    width: 330,
    borderRadius: sizes.SPACING_M,
  },

  userItem: {
    flexDirection: 'row',
    height: 60,
    alignItems: 'center',
  },

  name: {
    flex: 1,
    marginStart: sizes.SPACING_M,
  },
  itemButton: {
    width: 80,
  },
}));

import { useAlmondApiQuery } from '~/modules/api';

import { useProcessDocuments } from './useProcessDocuments';

const DOCUMENT_PATH = (patientUuid: string, documentUuid: string) =>
  `/patients/${patientUuid as '{patient_uuid}'}/documents/${documentUuid as '{document_uuid}'}/` as const;

export function useDocument(patientUuid: string | null, documentUuid: string | null) {
  const documentPath = patientUuid && documentUuid ? DOCUMENT_PATH(patientUuid, documentUuid) : null;

  const { data, isLoading, error, mutate } = useAlmondApiQuery(documentPath, {
    revalidateIfStale: false,
    shouldRetryOnError: false,
  });

  const { documents, isLoadingUsers, usersError } = useProcessDocuments(data ? [data] : []);

  return {
    document: documents?.[0],
    isLoading: isLoading || isLoadingUsers,
    error: error || usersError,
    mutate,
  };
}

export const processResponse = async (res: Response) => {
  if (res.status === 204) return;

  const responseData = await res.json();

  if (res.ok) return responseData;

  const error = new Error(`There was an error fetching data from "${res.url.split('api/v2')[1]}"`);

  (error as any).info = responseData;

  throw error;
};

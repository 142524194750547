import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  background: {
    backgroundColor: theme.lightSecondaryBackground,
    flex: 1,
  },

  container: {
    rowGap: sizes.SPACING_M,
    marginVertical: sizes.SPACING_L,
  },

  containerDesktop: {
    width: 580,
    alignSelf: 'center',
  },

  containerMobile: {
    marginHorizontal: sizes.SPACING_M,
  },

  title: {
    marginVertical: sizes.SPACING_XXL,
    color: theme.text,
    height: 100,
  },
}));

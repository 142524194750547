import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.background,
  },

  image: {
    width: 432,
    height: 356,
    maxWidth: '100%',
  },

  title: {
    textAlign: 'center',
    color: theme.darkText,
  },

  subtitle: {
    textAlign: 'center',
    color: theme.placeholder,
    marginTop: sizes.SPACING_S,
  },
}));

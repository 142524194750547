const MAX_JOINED_USERS = 3;

// This logic is copied from `useJoinTypingUsers` inside `stream-chat-chat`.
// `useTranslationContext` is removed, so this is not a hook anymore.
export const joinTypingUsers = (names: string[]) => {
  if (!names.length) return null;

  const [name, ...rest] = names;

  if (names.length === 1) return `${name} is typing...`;

  const isLargeArray = names.length > MAX_JOINED_USERS;
  const joinedUsers = (isLargeArray ? names.slice(0, MAX_JOINED_USERS) : rest).join(', ').trim();

  if (isLargeArray) return `${joinedUsers} and more are typing...`;

  return `${joinedUsers} and ${name} are typing...`;
};

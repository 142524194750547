import { useEffect, useState } from 'react';
import { Image, Pressable, View } from 'react-native';

import { ActivityIndicator, MaterialIcon, sizes, useTheme } from '@almond/ui';

import { PdfIcon } from '~assets';

import { getFileType } from '../../utils';
import { usePrefetchImageUrl } from './usePrefetchImageUrl';

import { themedStyles } from './styles';

import type { FC } from 'react';
import type { LayoutChangeEvent, PressableProps, StyleProp, ViewStyle } from 'react-native';

type FileThumbnailProps = {
  filename?: string;
  fileLocation?: string;
  style?: StyleProp<ViewStyle>;
  isLoading?: boolean;
  isError?: boolean;
  onRetry?: PressableProps['onPress'];
};

export const FileThumbnail: FC<FileThumbnailProps> = props => {
  const { fileLocation, filename, style, isLoading, isError, onRetry } = props;

  const [styles] = useTheme(themedStyles);
  const [errorSize, setErrorSize] = useState(0);

  // Initial hasLoadError value of false. When onLoadError is called, it flips to true
  const [hasLoadError, setHasLoadError] = useState(false);

  const fileType = !hasLoadError && filename ? getFileType(filename) : undefined;
  const imageUrl = usePrefetchImageUrl(fileType === 'image' ? fileLocation : undefined);

  useEffect(() => {
    if (!imageUrl) return;

    // If file location changes, reset the error state
    setHasLoadError(false);
  }, [imageUrl]);

  if (!fileLocation || !filename) return null;

  return (
    <View
      style={[style, styles.container]}
      aria-label={(hasLoadError ? `The following file failed to load - ` : '') + filename}
    >
      {fileType === 'image' && imageUrl && (
        <Image source={{ uri: imageUrl }} onError={() => setHasLoadError(true)} alt={filename} style={styles.content} />
      )}
      {fileType === 'pdf' && (
        <View style={styles.content}>
          <PdfIcon width="50%" height="100%" />
        </View>
      )}
      {isLoading && (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size={sizes.SPACING_XL} />
        </View>
      )}
      {isError && (
        <Pressable
          style={styles.loadingContainer}
          onPress={onRetry}
          aria-label="Retry"
          role="button"
          testID="SmallPreview-retry"
          onLayout={(event: LayoutChangeEvent) => {
            // Dynamically size the icon to be half the size of the container
            const { width } = event.nativeEvent.layout;

            setErrorSize(width * 0.7);
          }}
        >
          <MaterialIcon source="refresh" size={errorSize} color="error" />
        </Pressable>
      )}
      {hasLoadError && (
        <View
          style={[style, styles.content]}
          onLayout={(event: LayoutChangeEvent) => {
            // Dynamically size the icon to be half the size of the container
            const { width } = event.nativeEvent.layout;

            setErrorSize(width / 2);
          }}
        >
          <MaterialIcon source="error" size={errorSize} color="error" />
        </View>
      )}
    </View>
  );
};

import { StyleSheet } from 'react-native';

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    borderBottomColor: theme.border,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingTop: sizes.SPACING_M,
    paddingBottom: sizes.SPACING_S,
  },
  header: {
    marginVertical: sizes.SPACING_S,
  },
}));

import { StyleSheet } from 'react-native';

import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    borderLeftColor: theme.border,
    borderLeftWidth: StyleSheet.hairlineWidth,
    backgroundColor: theme.lightSecondaryBackground,
    height: '100%',
    width: 230,
  },
  containerMobile: {
    width: '100%',
    backgroundColor: theme.background,
  },
  header: {
    height: 48,
    backgroundColor: theme.lightAccent,
    paddingHorizontal: 12,
    justifyContent: 'center',
  },
  contents: {
    flex: 1,
    paddingHorizontal: 12,
  },
  footer: {
    padding: 12,
  },
  text: {
    color: theme.darkText,
  },
}));

import { StyleSheet } from 'react-native';

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  rightAction: {
    height: 20,
    width: 42,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: StyleSheet.hairlineWidth / 2,
    borderRadius: sizes.SPACING_M,
    backgroundColor: theme.background,
  },

  rightActionText: {
    color: theme.secondaryTextDark,
  },
}));

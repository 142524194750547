import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

export type SectionWithItemsProps = React.PropsWithChildren<{ title: string }>;

export const SectionWithItems: React.FC<SectionWithItemsProps> = props => {
  const { children, title } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.container}>
      <Text fontStyle="medium" size="xl" style={styles.title}>
        {title}
      </Text>
      {children}
    </View>
  );
};

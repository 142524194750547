import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@almond/ui';
import { Link } from 'expo-router';

import { usePathnameWithGroups } from '../../hooks';
import { useIsNarrow } from './useIsNarrow';

import { themedStyles } from './styles';

import type { Href } from 'expo-router';

export type TabProps = {
  href: Href<string>;
  title: string;
  indicator?: boolean;
  isDisabled?: boolean;
};

export const Tab: React.FC<TabProps> = props => {
  const [styles] = useTheme(themedStyles);
  const isSelected = props.href === usePathnameWithGroups();
  const isNarrow = useIsNarrow();
  const content = (
    <View
      style={[
        styles.tab,
        isNarrow && styles.tabNarrow,
        isSelected && styles.selectedTab,
        props.isDisabled && styles.disabled,
      ]}
    >
      {props.indicator && <View style={styles.indicator} testID="UnreadIndicator" />}
      <Text style={isSelected && styles.selectTabText} fontStyle={isSelected ? 'medium' : 'book'}>
        {props.title}
      </Text>
    </View>
  );

  if (props.isDisabled) return content;

  return (
    <Link href={props.href} push>
      {content}
    </Link>
  );
};

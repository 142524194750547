/// ///////////////////////////////////////////////////////////
// This component was created by
// 1. Copying the default AttachmentPreviewList source code from stream-chat-react
// eslint-disable-next-line max-len
// https://github.com/GetStream/stream-chat-react/blob/master/src/components/MessageInput/AttachmentPreviewList/AttachmentPreviewList.tsx
// 2. Replacing the default UI with the custom one
//
// We will likely modify this component more in the future. These are the initial
// edits, but check the file's git history for more recent edits.
/// ///////////////////////////////////////////////////////////
import './attachment-overrides.css';

import React from 'react';

import { SmallPreviewDraftAttachment, useDraftAttachmentContext } from '~/modules/documents';

import type { DraftAttachment } from '~/modules/documents/types';

type AttachmentListPreviewProps = {
  isMessageSending: boolean;
};

export const AttachmentPreviewList = (props: AttachmentListPreviewProps) => {
  const { attachments, deleteAttachment, retryAttachmentUpload } = useDraftAttachmentContext();

  if (!attachments.length) {
    return null;
  }

  return (
    <div className="str-chat__attachment-preview-list">
      <div className="str-chat__attachment-list-scroll-container" data-testid="attachment-list-scroll-container">
        {attachments.map(file => (
          <PreviewItem
            item={file}
            key={file.id}
            onRemove={() => !props.isMessageSending && deleteAttachment(file)}
            onRetry={() => retryAttachmentUpload(file)}
          />
        ))}
      </div>
    </div>
  );
};

type PreviewItemProps = { item: DraftAttachment; onRemove: () => void; onRetry: () => void };
const previewStyle = { width: 280, maxWidth: '100%' } as const;

const PreviewItem = ({ item, onRemove, onRetry }: PreviewItemProps) => {
  return (
    <SmallPreviewDraftAttachment
      style={previewStyle}
      item={item}
      isLoading={item.cdn?.status === 'uploading'}
      isError={item.cdn?.status === 'error'}
      onRetry={onRetry}
      onDelete={onRemove}
    />
  );
};

import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import { Button, PhoneNumberInput, Text, useTheme } from '@almond/ui';

import { parseApiResponseError } from '~/modules/errors';

import { useUpdatePatientMutation } from '../../hooks';
import { BaseVerificationModal } from '../BaseVerificationModal';

import { themedStyles } from './styles';

import type { BaseVerificationModalProps } from '../BaseVerificationModal';
import type { SmsOptInStatusEnum } from '@almond/api-types';

export type SendVerificationCodeModalProps = Omit<BaseVerificationModalProps, 'footer'> & {
  onOptInPress: () => void;
  onUpdatePhonePress: () => void;
  mutatePatient: () => void;
};

export const SendVerificationCodeModal = (props: SendVerificationCodeModalProps) => {
  const { patient, mutatePatient, onOptInPress, onUpdatePhonePress, onRequestClose, ...restProps } = props;
  const [styles] = useTheme(themedStyles);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { updatePatient, isUpdatingPatient } = useUpdatePatientMutation();

  const updateSmsOptInStatus = useCallback(
    async (smsOptInStatus: SmsOptInStatusEnum) => {
      try {
        await updatePatient({ smsOptInStatus });
        mutatePatient();
      } catch (error) {
        setErrorMessage(parseApiResponseError(error));
      }
    },
    [mutatePatient, updatePatient]
  );

  const handleRequestClose = useCallback(async () => {
    await updateSmsOptInStatus('opted_out');
    onRequestClose();
  }, [onRequestClose, updateSmsOptInStatus]);

  return (
    <BaseVerificationModal {...restProps} onRequestClose={handleRequestClose} patient={patient}>
      <View style={styles.contentContainer}>
        <PhoneNumberInput
          value={patient.profile.phone}
          label="Cell Phone*"
          textContentType="telephoneNumber"
          style={styles.textInput}
          containerStyle={styles.textInputContainer}
          isDisabled
          // eslint-disable-next-line max-len
          helperText="Reply STOP to unsubscribe. Reply HELP for assistance. Standard message and data rates apply."
        />
      </View>
      {errorMessage && <Text style={styles.error}>{`* ${errorMessage}`}</Text>}
      <View style={styles.footer}>
        <Button
          style={styles.button}
          fixedWidth={false}
          isDisabled={isUpdatingPatient}
          isLoading={isUpdatingPatient}
          onPress={onOptInPress}
        >
          {'Opt-in to SMS messages'}
        </Button>
        <Button
          type="secondary"
          style={styles.button}
          fixedWidth={false}
          isDisabled={isUpdatingPatient}
          isLoading={isUpdatingPatient}
          onPress={onUpdatePhonePress}
        >
          {'Update Phone Number'}
        </Button>
      </View>
    </BaseVerificationModal>
  );
};

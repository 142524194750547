import React from 'react';

import { BaseButton, Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

type HeaderButtonProps = {
  onPress: () => void;
  text: string;
  testID?: string;
  isDisabled?: boolean;
  icon?: React.ReactNode;
};

export const HeaderButton = (props: HeaderButtonProps) => {
  const [styles, theme] = useTheme(themedStyles);

  return (
    <BaseButton
      onPress={props.onPress}
      testID={props.testID}
      style={[styles.headerButton, props.isDisabled && styles.disabled]}
      hoverBackground={theme.selectedOption}
      isDisabled={props.isDisabled}
    >
      {({ isHovered }) => (
        <>
          {props.icon}
          <Text style={[styles.headerButtonText, isHovered && styles.headerButtonTextHover]}>{props.text}</Text>
        </>
      )}
    </BaseButton>
  );
};

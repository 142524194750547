import React, { useEffect } from 'react';
import { View } from 'react-native';

import { ScrollView, useAdaptive, useTheme } from '@almond/ui';
import { useToggle } from '@almond/utils';
import { useRouter } from 'expo-router';
import { useChatContext } from 'stream-chat-react';

import { useAlmondApiQuery } from '~/modules/api';
import { ErrorContainer } from '~/modules/logging';
import { GetCare, Messages, PatientVisits } from '~/modules/patient';
import { SendVerificationCodeModal, VerifyPhoneNumberModal } from '~/modules/profile';
import { Referral } from '~/modules/referral';
import { BackgroundImage, useRerender } from '~/modules/ui';
import { isCurrentUserIncomplete, useCurrentUser } from '~/modules/user';

import { themedStyles } from './styles';

export { ErrorContainer as ErrorBoundary };

const title = '✨ Dreams Do Come True';
const subtitle =
  // eslint-disable-next-line max-len
  'Receive and send text messages directly with the Care Team about sensitive medical and billing information. We promise no marketing messages :)';

const PatientGroup: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const containerStyle = useAdaptive(styles.containerDesktop, styles.containerMobile);
  const currentUser = useCurrentUser();
  const { data, mutate } = useAlmondApiQuery(
    currentUser?.patientUuid ? `/patients/${currentUser?.patientUuid as '{patient_uuid}'}/` : null
  );
  const { client } = useChatContext();
  const { user } = client;
  const rerender = useRerender();
  const [isSendCodeVisible, toggleIsSendCodeVisible] = useToggle(false);
  const [isVerifyVisible, toggleIsVerifyVisible] = useToggle(false);
  const router = useRouter();

  useEffect(() => {
    if (user && Object.keys(user).length > 1) return;

    const listener = client.on('health.check', () => {
      // Sometimes the user from the client includes only 1 field (id).
      // As soon as this event is fired, we need to rerender the component to have the correct user.
      rerender();
    });

    return () => {
      listener.unsubscribe();
    };
  }, [client, rerender, user]);

  useEffect(() => {
    // Waiting for the current user and patient to be loaded.
    if (!user?.role || !data) {
      return;
    }

    const shouldShowModal =
      // Current user is complete.
      !isCurrentUserIncomplete(user.role) &&
      // The patient was created before the settings page milestone.
      (data.profile.smsOptInStatus == null ||
        // Or it was created after, but didn't verify his phone number successfully.
        (data.profile.smsOptInStatus === 'opted_in' && !data.profile.isSmsVerified));

    if (shouldShowModal) {
      toggleIsSendCodeVisible();
    }
  }, [data, toggleIsSendCodeVisible, user?.role]);

  return (
    <View style={styles.background}>
      <BackgroundImage type="desktop" />

      <ScrollView contentContainerStyle={[styles.container, containerStyle]}>
        <GetCare />
        <Messages />
        <PatientVisits />
        {!isCurrentUserIncomplete(user?.role) && <Referral />}
      </ScrollView>

      {data && (
        <SendVerificationCodeModal
          title={title}
          subtitle={subtitle}
          isVisible={isSendCodeVisible}
          onRequestClose={toggleIsSendCodeVisible}
          patient={data}
          mutatePatient={mutate}
          onOptInPress={() => {
            toggleIsSendCodeVisible();
            toggleIsVerifyVisible();
          }}
          onUpdatePhonePress={() => {
            toggleIsSendCodeVisible();
            router.navigate('/(patient)/settings');
          }}
        />
      )}

      {data && (
        <VerifyPhoneNumberModal
          title={title}
          subtitle={subtitle}
          isVisible={isVerifyVisible}
          onRequestClose={toggleIsVerifyVisible}
          patient={data}
          mutatePatient={mutate}
          optOutOnRequestClose
          optOutOnError
          optInOnSuccess
          sendTextOnSuccess
        />
      )}
    </View>
  );
};

export default PatientGroup;

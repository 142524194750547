import '@almond/ui/index.css';

import React, { useEffect, useState } from 'react';

import { initAnalytics, useInjectGTM } from '@almond/analytics';
import { ScrollStateProvider, ToastNotificationProvider, useInjectCssVariables } from '@almond/ui';
import { cypressUtilities, initSentry, StatsigProvider } from '@almond/utils';
import { Auth0Provider } from '@auth0/auth0-react';
import { Stack } from 'expo-router';
import * as SplashScreen from 'expo-splash-screen';
import { v4 } from 'uuid';

import { RequireAuthentication, useRedirectCallback } from '~/modules/auth';
import { DocumentViewerProvider } from '~/modules/documents';
import { ErrorPage } from '~/modules/logging';
import { UnreadMessagesProvider } from '~/modules/messaging';
import { PatientPageTitle } from '~/modules/routing';
import { useKeyboardVisible } from '~/modules/ui';
import { CurrentUserStateProvider } from '~/modules/user';
import { fontsUtilities } from '~assets';
import { env } from '~env';

export {
  // Catch any errors thrown by the Layout component.
  ErrorPage as ErrorBoundary,
};

export const unstable_settings = {};

// Prevent the splash screen from auto-hiding before asset loading is complete.
SplashScreen.preventAutoHideAsync();

// Enabling Sentry and Analytics only in builds.
if (process.env.NODE_ENV === 'production') {
  initAnalytics(process.env.EXPO_PUBLIC_ENV);
  initSentry(process.env.EXPO_PUBLIC_ENV);
}

const RootLayoutContent: React.FC = () => {
  const redirectCallback = useRedirectCallback();

  // Resize page while keyboard is open
  useKeyboardVisible();
  useInjectGTM(env.GTM_KEY);
  useInjectCssVariables();

  return (
    <ScrollStateProvider>
      <ToastNotificationProvider getUuid={v4}>
        <PatientPageTitle />
        <StatsigProvider
          sdkKey={env.STATSIG_CLIENT_ID}
          environment={env.STATSIG_ENVIRONMENT}
          isTest={process.env.EXPO_PUBLIC_PLAYWRIGHT === 'true' || cypressUtilities.isCypressRunning()}
        >
          <Auth0Provider
            domain={env.AUTH0_DOMAIN}
            clientId={env.AUTH0_CLIENT_ID}
            onRedirectCallback={redirectCallback}
            authorizationParams={{
              scope: 'openid, profile, email',
              redirect_uri: window.location.origin,
              audience: 'almond-patient-portal',
            }}
          >
            <RequireAuthentication>
              <DocumentViewerProvider>
                <CurrentUserStateProvider>
                  <UnreadMessagesProvider>
                    <Stack screenOptions={{ headerShown: false }} />
                  </UnreadMessagesProvider>
                </CurrentUserStateProvider>
              </DocumentViewerProvider>
            </RequireAuthentication>
          </Auth0Provider>
        </StatsigProvider>
      </ToastNotificationProvider>
    </ScrollStateProvider>
  );
};

const RootLayout: React.FC = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState<any>();

  // Expo Router uses Error Boundaries to catch errors in the navigation tree.
  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        await SplashScreen.preventAutoHideAsync();
        await fontsUtilities.loadFonts();
        setIsInitialized(true);
      } catch (e) {
        setError(e);
      }
    };

    initialize();
  }, [error]);

  useEffect(() => {
    if (isInitialized) {
      SplashScreen.hideAsync();
    }
  }, [isInitialized]);

  if (!isInitialized) {
    return null;
  }

  return <RootLayoutContent />;
};

export default RootLayout;

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  channelContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
    width: '100%',
  },
  joinButton: {
    marginBottom: sizes.SPACING_S,
    marginHorizontal: sizes.SPACING_S,
  },
}));

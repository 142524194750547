import { StyleSheet } from 'react-native';

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  contentContainer: {
    width: '100%',
    marginTop: 'auto',
  },

  action: {
    backgroundColor: theme.background,
    padding: sizes.SPACING_M,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: theme.placeholder,
  },

  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

  icon: {
    marginEnd: sizes.SPACING_S,
  },

  cancel: {
    marginVertical: sizes.SPACING_S,
  },

  border: {
    borderRadius: sizes.SPACING_S,
    overflow: 'hidden',
    marginHorizontal: sizes.SPACING_S,
  },

  cancelText: {
    color: theme.primary,
    textAlign: 'center',
    flex: 1,
  },

  disabled: {
    opacity: 0.5,
  },

  crossed: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  } as any,

  tilted: {
    transform: [{ rotate: '45deg' }],
  },
}));

import { StyleSheet } from 'react-native';

import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    height: '100%',
    aspectRatio: 1,
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.skeletonLoader,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    padding: '10%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

import type { DefaultDemographicFormValues } from './types';

export const checkShouldVerifyPhoneNumber = (
  phone: string,
  isOptedIntoSms: boolean,
  defaultValues: DefaultDemographicFormValues
) => {
  const isNumberChanged =
    isValidPhoneNumber(phone, 'US') && parsePhoneNumber(phone, 'US').number !== defaultValues.phone && isOptedIntoSms;
  const isOptedIntoSmsChanged = !defaultValues.isOptedIntoSms && isOptedIntoSms;

  return isNumberChanged || isOptedIntoSmsChanged;
};

import { useChatContext } from 'stream-chat-react';

import { useConversationMembers } from './useConversationMembers';
import { useDocuments } from './useDocuments';
import { useStreamEventListener } from './useStreamEventListener';

// When receiving a new message, check if it has attachments and if so, refresh the document list
export const useListenForNewAttachments = () => {
  const { channel } = useChatContext();
  const members = useConversationMembers(channel);
  const patientUuid = members.find(m => m.isPatient)?.patientUuid;
  const me = members.find(m => m.isMe);

  const { triggerRevalidate } = useDocuments(patientUuid);

  useStreamEventListener('message.new', async event => {
    if (event.message?.attachments?.length && event.user?.id !== me?.id) {
      // New files from someone else, need to refresh document list
      triggerRevalidate();
    }
  });
};

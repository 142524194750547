import { useEffect, useState } from 'react';

import { parseChannelData } from '../utils';
import { useStreamEventListener } from './useStreamEventListener';

import type { Channel } from 'stream-chat';

/**
 * Get data for the given channel
 * Note - this hook does not use the channel object from useChatContext()
 *        because it is used for arbitrary channels in the ChannelList. It
 *        must be able to operate on any channel, not just the selected one
 * @param channel The channel to get the information for
 * @returns Channel data stored by Stream, updated as Stream sends update events
 */
export const useChannelData = (channel: Channel | undefined) => {
  const [channelData, setChannelData] = useState<ReturnType<typeof parseChannelData>>(() =>
    parseChannelData(channel?.data)
  );

  useEffect(() => {
    if (!channel) return;

    setChannelData(parseChannelData(channel.data));
  }, [channel]);

  useStreamEventListener(channel, 'channel.updated', event => {
    const updatedData = parseChannelData(event.channel);

    setChannelData(updatedData);
  });

  return channelData;
};

type User = {
  id: string;
  role?: string;
};

export const sortUsersComparator = <T extends User>(a: T, b: T, streamId?: string) => {
  // Sort "me" first
  if (streamId === a.id) return -1;
  if (streamId === b.id) return 1;

  // Define role order
  const ROLE_ORDER = ['patient', 'provider', 'careguide', 'admin'];

  // Get role index for sorting
  const aRoleIndex = a.role ? ROLE_ORDER.indexOf(a.role) : ROLE_ORDER.length;
  const bRoleIndex = b.role ? ROLE_ORDER.indexOf(b.role) : ROLE_ORDER.length;

  return aRoleIndex - bRoleIndex;
};

import { useChatContext } from 'stream-chat-react';

import { useDraftAttachmentContext } from '../components/DraftAttachmentProvider';
import { MAX_ALLOWED_ATTACHMENTS } from '../config';

/**
 * Get the maximum number of files that can be added to the attachments
 * @returns Maximum number of files that can be added to the attachments
 */
export const useMaxAttachmentsLeft = () => {
  const { channel } = useChatContext();
  const isPatient = channel?.state.membership.role === 'patient';

  const { attachments } = useDraftAttachmentContext();

  const uploadToHealthRecords = isPatient ? false : attachments.every(a => a.isHealthRecord);
  const maxFiles = uploadToHealthRecords ? 1 : MAX_ALLOWED_ATTACHMENTS;

  return maxFiles - attachments.length;
};

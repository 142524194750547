import { StyleSheet } from 'react-native';

import { sizes, styleSheetFactory } from '@almond/ui';

const height = 64;
const padding = sizes.SPACING_S;

export const deleteSize = sizes.SPACING_L;

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    display: 'flex',
    height,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.lightAccent,
    flexDirection: 'row',
    alignItems: 'center',
    padding,
    columnGap: sizes.SPACING_M,
    borderRadius: 12,
    backgroundColor: theme.background,
  },
  deletedContainer: {
    opacity: 0.5,
  },
  thumbnail: {
    borderRadius: sizes.SPACING_S,
  },

  textWrapper: {
    // To make sure ellipsis works if filename is too long
    flex: 1,
  },
  fileName: {
    color: theme.darkText,
  },
  fileSize: {
    color: theme.secondaryText,
  },
  deleteButton: {
    position: 'absolute',
    top: deleteSize / -4,
    right: deleteSize / -4,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.secondaryText,
    backgroundColor: theme.background,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

export const Placeholder: React.FC<React.PropsWithChildren> = props => {
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.container}>
      <Text style={styles.placeholder}>{props.children || 'No data found.'}</Text>
    </View>
  );
};

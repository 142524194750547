.wrapper::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: var(--color-error);
  position: absolute;

  /* radius 50% width is a full circle. 1px less so it doesn't bleed around the edges of the icon */
  clip-path: circle(calc(50% - 1px));
}

import { useCallback, useMemo } from 'react';

import { useAlmondApiQuery } from '~/modules/api';

import type { TodoDetailOut } from '@almond/api-types';

const actions = {
  add: (items: readonly TodoDetailOut[], item: TodoDetailOut) => [...items.filter(i => i.uuid !== item.uuid), item],
  update: (items: readonly TodoDetailOut[], item: TodoDetailOut) => items.map(i => (i.uuid === item.uuid ? item : i)),
  delete: (items: readonly TodoDetailOut[], item: TodoDetailOut) => items.filter(i => i.uuid !== item.uuid),
};

const sortTodos = (a: TodoDetailOut, b: TodoDetailOut) => {
  const parseDate = (date: string | undefined) => (date ? Date.parse(date) : 0);
  const aArchivedAt = Math.max(parseDate(a.archivedAt), parseDate(a.completedAt));
  const bArchivedAt = Math.max(parseDate(b.archivedAt), parseDate(b.completedAt));

  if (aArchivedAt !== bArchivedAt) {
    return aArchivedAt < bArchivedAt ? 1 : -1;
  }

  const aCreatedAt = a.createdAt ? Date.parse(a.createdAt) : Infinity;
  const bCreatedAt = b.createdAt ? Date.parse(b.createdAt) : Infinity;

  if (aCreatedAt !== bCreatedAt) {
    return aCreatedAt < bCreatedAt ? 1 : -1;
  }

  return a.title.localeCompare(b.title);
};

type GetTodosOptions = {
  patientUuid?: string;
};

export const useGetTodos = (options: GetTodosOptions) => {
  const { patientUuid } = options;

  const items = useAlmondApiQuery(patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/todos/` : null, {
    query: { archived_or_completed: false },
  });

  const archivedItems = useAlmondApiQuery(patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/todos/` : null, {
    query: { archived_or_completed: true },
  });

  const active = useMemo(() => {
    return [...(items.data?.todos ?? [])].sort(sortTodos);
  }, [items.data]);
  const archived = useMemo(() => {
    return [...(archivedItems.data?.todos ?? [])].sort(sortTodos);
  }, [archivedItems.data]);

  const { mutate } = items;
  const archivedMutate = archivedItems.mutate;

  const modifyCache = useCallback(
    (type: 'create' | 'delete' | 'edit' | 'archiveStatus', updatedTodo: TodoDetailOut) => {
      const isArchived = updatedTodo.archivedAt || updatedTodo.completedAt;
      const action = {
        create: actions.add,
        delete: isArchived ? null : actions.delete,
        edit: isArchived ? null : actions.update,
        archiveStatus: isArchived ? actions.delete : actions.add,
      }[type];

      if (action) {
        mutate(
          oldData => {
            const updatedTodos = action(oldData?.todos || [], updatedTodo);

            return {
              count: updatedTodos.length,
              todos: updatedTodos,
            };
          },
          { revalidate: false }
        );
      }

      const archivedAction = {
        create: null,
        delete: isArchived ? actions.delete : null,
        edit: isArchived ? actions.update : null,
        archiveStatus: isArchived ? actions.add : actions.delete,
      }[type];

      if (archivedAction) {
        archivedMutate(
          oldData => {
            const updatedTodos = archivedAction(oldData?.todos || [], updatedTodo);

            return {
              count: updatedTodos.length,
              todos: updatedTodos,
            };
          },
          { revalidate: false }
        );
      }
    },
    [archivedMutate, mutate]
  );

  return {
    active,
    archived,
    error: items.error || archivedItems.error,
    isLoading: items.isLoading || archivedItems.isLoading,
    modifyCache,
  };
};

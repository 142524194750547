import React from 'react';
import { View } from 'react-native';

import { ScrollView, Text, useBrowserType, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

export type SidebarProps = React.PropsWithChildren<{
  footer?: React.ReactNode;
}>;

export const Sidebar: React.FC<SidebarProps> = props => {
  const [styles] = useTheme(themedStyles);
  const browserType = useBrowserType();

  return (
    <View style={[styles.container, browserType === 'mobile' && styles.containerMobile]} testID="Sidebar">
      {browserType === 'desktop' && (
        <View style={styles.header}>
          <Text fontStyle="bold" size="m" accessibilityLevel={3} style={styles.text}>
            Info
          </Text>
        </View>
      )}
      <ScrollView style={styles.contents} listenToNativeEvents>
        {props.children}
      </ScrollView>
      {props.footer && <View style={styles.footer}>{props.footer}</View>}
    </View>
  );
};

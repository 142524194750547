export const formatFileName = (filename: string): string => {
  // On macOS, files with a "/" in them are actually encoded as ":".
  // The ":" character is not allowed in filenames. So when we display
  // a filename, we need to replace ":" with "/".
  // TODO identify what happens on Windows.
  return (
    filename
      .replaceAll(':', '/')
      // Remove file extension from display to users
      .replace(/\.[A-Z0-9]+$/i, '')
  );
};

import { useCallback } from 'react';

import { useToastNotification } from '@almond/ui';

import { useAlmondApiMutation } from '~/modules/api';

import { getCategoryTitle } from '../utils';

import type { TodoDetailOut } from '@almond/api-types';

export const useMarkTodos = (patientUuid: string | undefined, type: 'complete' | 'archive') => {
  const { showToast } = useToastNotification();

  const result = useAlmondApiMutation(
    'post',
    patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/todos/{todo_uuid}/${type}/` : null
  );

  const markItem = useCallback(
    async (item: TodoDetailOut, markAs: boolean) => {
      if (!patientUuid) {
        return null;
      }

      try {
        const response = await result.trigger({
          patient_uuid: patientUuid,
          todo_uuid: item.uuid,
          completed: type === 'complete' ? markAs : undefined,
          archived: type === 'archive' ? markAs : undefined,
        });

        const text = markAs
          ? `To Do Item ${type === 'archive' ? 'archived' : 'completed'} and moved to Archive`
          : // eslint-disable-next-line max-len
            `To Do Item marked as ${type === 'archive' ? 'unarchived' : 'incomplete'} and moved to the ${getCategoryTitle(item.category)} category`;

        showToast({
          type: 'info',
          text,
          duration: 5000,
        });

        return response;
      } catch (e) {
        showToast({
          type: 'error',
          // eslint-disable-next-line max-len
          text: `Marking the To Do Item as ${type === 'archive' ? 'archived' : 'completed'} failed. Please try again or reach out to the Care Team.`,
          duration: 15000,
        });

        return null;
      }
    },
    [patientUuid, result, showToast, type]
  );

  return {
    markItem,
  };
};

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  modalContent: {
    flex: 1,
    marginHorizontal: sizes.SPACING_XXL,
    marginTop: sizes.SPACING_L,
    padding: 12,
  },

  modalContentContainer: {
    flex: 1,
  },

  attachments: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: sizes.SPACING_S,
    marginHorizontal: sizes.SPACING_XXL,
    columnGap: sizes.SPACING_XS,
  },

  modalFooter: {
    flexDirection: 'row',
    columnGap: sizes.SPACING_M,
    justifyContent: 'center',
  },

  button: {
    width: 145,
  },

  messageContainer: {
    flexDirection: 'row',
    columnGap: sizes.SPACING_S,
  },
}));

import { useBrowserTypeMap } from '@almond/ui';
import { useGlobalSearchParams, useRouter } from 'expo-router';

export const useSidebar = () => {
  const router = useRouter();
  const searchParams = useGlobalSearchParams();
  const isSidebarOpen = searchParams.sidebar === 'true';
  const { isMobile } = useBrowserTypeMap();
  const isSidebarDisplayed = isMobile && isSidebarOpen;
  const toggleSidebar = () => {
    // @ts-ignore
    router.setParams({ sidebar: searchParams.sidebar === 'true' ? undefined : 'true' });
  };

  return { isSidebarOpen, isSidebarDisplayed, toggleSidebar };
};

import React from 'react';

import { useTheme } from '@almond/ui';
import Svg, { Path } from 'react-native-svg';

import { themedStyles } from './styles';

import type { BrowserType } from '@almond/ui';
import type { ViewProps } from 'react-native';

export type BackgroundProps = React.PropsWithChildren<ViewProps>;

type BackgroundImageProps = {
  type: BrowserType;
};

export const BackgroundImage = (props: BackgroundImageProps) => {
  const [styles, theme] = useTheme(themedStyles);

  if (props.type === 'desktop') {
    return (
      <Svg viewBox="0 0 1440 300" style={styles.wave}>
        <Path
          fill={theme.lightAccent}
          /* eslint-disable-next-line max-len */
          d="M 2 53 C 362 185 600 128 720 96 C 840 64 960 0 1080 0 C 1200 0 1320 64 1380 96 L 1440 128 L 1440 400 H 0 Z"
        />
      </Svg>
    );
  }

  if (props.type === 'mobile') {
    return (
      <Svg viewBox="0 0 380 30">
        <Path
          fill={theme.lightAccent}
          /* eslint-disable-next-line max-len */
          d="M 380 30 H 0 V 10 C 14 4 42 -8 92 9 C 208 55 339 -14 380 18 Z"
        />
      </Svg>
    );
  }

  throw new Error('Mobile or desktop required');
};

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  attachmentContainer: {
    maxWidth: 'var(--str-chat__message-with-attachment-max-width)' as any,
    padding: sizes.SPACING_XS,
    marginBottom: sizes.SPACING_S,
  },
  imageContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: sizes.SPACING_XXS,
    overflow: 'hidden',
    gap: sizes.SPACING_XXS,
    borderRadius: 12,
  },
  fileContainer: {
    margin: sizes.SPACING_XXS,
    gap: sizes.SPACING_XXS,
    borderRadius: 12,
  },
  imageItem: {
    aspectRatio: 1,
    width: 'auto',
    flexGrow: 1,
    minWidth: 140,
  },
  fileItem: {
    width: 'auto',
  },
  oddImageOut: {
    aspectRatio: 2,
    width: '100%',
  },
  soloImage: {
    aspectRatio: 1,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  imagePressable: {
    // Web-only property,
    cursor: 'zoom-in',
  } as any,
}));

import { useEffect } from 'react';

import { useEvent } from '@almond/utils';
import { useChatContext } from 'stream-chat-react';

/**
 * This hook calls the provided callback after Stream is initialized
 * and the user is authenticated/connected. Without this, calls to
 * client.queryChannels() will fail. The provided callback will be
 * called only once. The callback instance/closure called is the one
 * that exists in the render cycle when the user is authenticated.
 * @param cb Function that gets called after Stream is initialized
 *           and the user is authenticated/connected.
 */
export const useRunAfterStreamInit = (cb: () => void, dependencies: any[] = []) => {
  const { client } = useChatContext();
  const mostRecentCb = useEvent(cb);

  useEffect(() => {
    if (!client) {
      return;
    }

    if (client.user) {
      mostRecentCb();
    } else {
      const listener = client.on('connection.changed', () => {
        if (client.user) {
          listener.unsubscribe();
          mostRecentCb();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, ...dependencies]);
};

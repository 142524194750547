import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  modalContainer: {
    maxWidth: 595,
    minHeight: 480,
  },

  modalContainerMobile: {
    width: '100%',
    height: '100%',
  },

  modalContentContainer: {
    justifyContent: 'center',
  },
}));

.header {
  height: 62px;
  background-color: var(--color-light-secondary-background);
  padding-inline: 16px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 10px;
}

.left {
  justify-content: center;
}

.center {
  align-items: center;
  justify-content: center;
}

.right {
  align-items: flex-end;
  justify-content: center;
}

.title {
  color: var(--color-accent);
}

.centerText {
  text-align: center;
}

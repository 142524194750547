import React from 'react';
import { View } from 'react-native';

import { BaseButton, useTheme } from '@almond/ui';
import { useRouter } from 'expo-router';

import { channelIdToPath } from '~/modules/routing/utils';

import { useMarkAsRead } from '../../hooks';

import { themedStyles } from './styles';

import type { Channel, DefaultGenerics } from 'stream-chat';
import type { ChannelPreviewUIComponentProps } from 'stream-chat-react';

export type PreviewContainerProps = React.PropsWithChildren<ChannelPreviewUIComponentProps<DefaultGenerics>> & {
  onLongPress?: (channel: Channel) => void;
  unreadCounter: number | undefined;
  isTyping: boolean;
  isPatientTyping: boolean;
  isSearching: boolean;
};

export const PreviewContainer: React.FC<PreviewContainerProps> = props => {
  const router = useRouter();
  const [styles] = useTheme(themedStyles);
  const {
    isTyping,
    isPatientTyping,
    channel,
    active,
    displayTitle,
    setActiveChannel,
    watchers,
    children,
    onLongPress,
    unreadCounter,
    isSearching,
  } = props;
  const markAsRead = useMarkAsRead(channel);
  const handlePress = () => {
    setActiveChannel?.(channel, watchers);
    markAsRead();
    router.push(channelIdToPath('admin', channel.id) as any);
  };

  const handleLongPress = () => {
    onLongPress?.(channel);
  };

  const opacity = active || isSearching || unreadCounter || (isTyping && isPatientTyping) ? 1 : 0.5;

  return (
    <BaseButton
      isDisabled={!channel.id}
      onPress={handlePress}
      aria-label={`Select Channel: ${displayTitle || ''}`}
      aria-selected={active}
      testID="channel-preview-button"
      role="option"
      onLongPress={handleLongPress}
    >
      <View style={[styles.previewContainer, { opacity }, active && styles.previewContainerSelected]}>{children}</View>
    </BaseButton>
  );
};

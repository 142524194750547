import { useMemo } from 'react';

import { useCurrentUser } from '~/modules/user';

import type { Filter } from '../../types';
import type { ChannelFilters } from 'stream-chat';

export const useStreamFilter = (filter: Filter | undefined, search: string) => {
  const currentUser = useCurrentUser();
  const streamId = currentUser?.streamId;

  const filters: ChannelFilters | undefined = useMemo(() => {
    if (!streamId || !filter) return undefined;

    if (search) {
      return {
        $or: [{ birthday: { $autocomplete: search } }, { name: { $autocomplete: search } }],
      };
    }

    if (filter === 'member-of') {
      return { members: { $in: [streamId] } };
    }

    if (filter === 'all') {
      // Admins are read-only, and are members of 0 channels (and can not join any)
      // So show all channels when role is admin
      return {};
    }

    return { status: { $eq: filter } };
  }, [streamId, filter, search]);

  return filters;
};

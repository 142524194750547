import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    width: 400,
    maxWidth: '100%',
    marginHorizontal: 'auto',
    height: '100%',
    marginTop: sizes.SPACING_XL,
    rowGap: sizes.SPACING_M,
  },
  fileList: {
    rowGap: 12,
  },
  checkboxLabel: {
    color: theme.darkText,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  applyButton: {
    width: 100,
  },
}));

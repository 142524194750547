import React from 'react';
import { View } from 'react-native';

import { ActivityIndicator, useTheme } from '@almond/ui';

import { BackgroundImage } from './BackgroundImage';

import { themedStyles } from './styles';

export const LoadingWithBackground = () => {
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.loadingBackground}>
      <BackgroundImage type="desktop" />
      <ActivityIndicator />
    </View>
  );
};

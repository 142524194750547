import type { ChannelSort } from 'stream-chat';

// WARNING: Only sort by properties that we can be confident we will be notified of
// when they change, *even for channels that the client is not watching*. We will always
// be notified of changes to has_unread and last_message_at, because mark_unread/mark_read
// and message.new all get dispatched even for channels that the client is not watching.
//
// If we sort by other properties, channel #9 may become channel #12 without us knowing. Then,
// when we fetch the next page of channels (assuming 10-channel page size), the formerly channel 11
// is now channel 10, and when we fetch starting at channel 11, we'll end up skipping channel 10.
export const sortConfig: ChannelSort = [{ has_unread: -1 }, { last_message_at: -1 }];

export const REFRESH_INTERVAL = 5 * 60 * 1000;
/**
 * This needs to be <= 30, or the "Load more" button will not show up correctly. This is because
 * Stream's backend API will clamp this value to 30,
 */
export const LIMIT = 30;

.button {
  height: 35px;
  flex-grow: 1;
  background: var(--color-accent);
  outline-offset: -5px;

  /* Layout of children */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.button:hover {
  background: color-mix(in hsl, var(--color-accent), var(--color-dark-text) 20%);
}
.button:active {
  background: color-mix(in hsl, var(--color-accent), var(--color-dark-text) 25%);
}

.buttonText {
  text-align: center;
  color: var(--color-background);
}

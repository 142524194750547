import dayjs from 'dayjs';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { z } from 'zod';

// Disallow the following space separated characters ( ) [ ] , { } |
const allowedCharacters = /^[^()[\],{}|]*$/;

export const validationSchema = z.object({
  firstName: z.string().regex(allowedCharacters, { message: 'Names cannot contain the characters (){}[],|' }),
  preferredFirstName: z
    .string()
    .regex(allowedCharacters, { message: 'Names cannot contain the characters (){}[],|' })
    .nullable(),
  lastName: z.string().regex(allowedCharacters, { message: 'Names cannot contain the characters (){}[],|' }),
  birthday: z
    .string()
    .regex(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/, {
      message: 'Please enter a valid date MM/DD/YYYY',
    })
    .refine(arg => dayjs(arg, ['MM/DD/YYYY']).isBefore(dayjs(), 'day'), {
      message: 'Date must be in the past',
    }),
  email: z.string().email({ message: 'Please enter a valid email address' }),
  phone: z.string().refine(
    arg => {
      if (!arg) {
        return false;
      }

      return isValidPhoneNumber(arg, 'US');
    },
    { message: 'Please enter a valid 10 digit phone number' }
  ),
  isOptedIntoSms: z.boolean().nullable(),
});

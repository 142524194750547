import { useEffect } from 'react';

import { useEvent } from '@almond/utils';
import { useChatContext } from 'stream-chat-react';

import { streamChannelFilter, streamChannelSort } from '../../utils';
import { sortConfig } from './config';

import type { CustomEventTypes } from '../../types';
import type { Channel, ChannelFilters, Event } from 'stream-chat';

const eventsToListenTo = [
  'message.new',
  'channel.updated',
  'channel.truncated',
  'channel.hidden',
  'channel.visible',
  'channel.deleted',
  'notification.removed_from_channel',
  'notification.message_new',
  'notification.added_to_channel',
  'notification.mark_read',
  'notification.mark_unread',
  'almond_channel_status_updated',
];

export const useListenToChannelUpdateEvents = (
  channels: Channel[],
  setChannels: (isFullReload: boolean, channels: (oldVal: Channel[]) => Channel[]) => void,
  filters: ChannelFilters | undefined
) => {
  const { client } = useChatContext();

  // We're using the `channel` object in this handler, but we don't want to reattach
  // this event listener every time the channel list changes. So wrap in useEvent
  // so the useEffect() dependency array never changes.
  const eventHandler = useEvent(async (e: Event) => {
    if (eventsToListenTo.includes(e.type)) {
      const channelType = e.channel_type || (e._channel_type as unknown as string);
      const channelId = e.channel_id || (e._channel_id as unknown as string);

      if (!channelType || !channelId) {
        return;
      }

      const existingChannel = channels.find(c => c.id === channelId && c.type === channelType);

      // If we receive a channel_status_updated event, but the new status is
      // one we're already aware of (because we already received channel.updated),
      // then we can ignore the event.
      if (
        (e.type as CustomEventTypes) === 'almond_channel_status_updated' &&
        existingChannel &&
        e.status === existingChannel.data?.status
      ) {
        return;
      }

      if (e.type === 'notification.mark_read') {
        // If the channel is not visible, marking it as read won't impact the display.
        // If the channel already had 0 unread messages, marking it as read won't impact the display.
        if (
          !existingChannel ||
          (e.last_read_message_id === existingChannel?.lastMessage()?.id && existingChannel.countUnread() === 0)
        ) {
          return;
        }
      }

      const newChannel = client.channel(channelType, channelId);

      await newChannel.watch();

      if (streamChannelFilter(newChannel, filters)) {
        setChannels(false, prevChannels => {
          return streamChannelSort([...prevChannels.filter(c => c.cid !== newChannel.cid), newChannel], sortConfig);
        });
      } else {
        setChannels(false, prevChannels => {
          return prevChannels.filter(c => c.cid !== newChannel.cid);
        });
      }
    }
  });

  useEffect(() => {
    const listener = client.on(eventHandler);

    return () => listener.unsubscribe();
  }, [client, eventHandler]);
};

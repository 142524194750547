import { useEffect, useState } from 'react';

/**
 * Artemis has error boundaries set up that log to Sentry and display a friendly
 * error page to the user. However, the error boundary can only catch code that
 * throws synchronously from React contexts. If a promise rejects or an error
 * happens as a result of a click handler, the error boundary isn't alerted.
 *
 * If this function is called from async code it causes a re-render and throws
 * the error synchronously in a useEffect(), so the error boundary can catch it.
 */
export const useCaptureError = () => {
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  return setError;
};

import { useState } from 'react';

import { getCareguideIds } from '../utils';
import { useChannelStatus } from './useChannelStatus';
import { useMarkAsUnread } from './useMarkAsUnread';

import type { BottomSheetAction } from '~/modules/ui';
import type { Channel } from 'stream-chat';

export const useConversationMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [channel, setChannel] = useState<Channel | undefined>();
  const { toggleStatus, status, statusVisibleToUser } = useChannelStatus(channel);
  const { isAvailable: isMarkUnreadAvailable, isDisabled, markAsUnread } = useMarkAsUnread(channel);
  const [cachedStatus, setCachedStatus] = useState<string | undefined>();

  const onDismiss = () => {
    setIsVisible(false);
    setChannel(undefined);
  };

  // Once toggleStatus() finishes, the status is updated. If we're not careful,
  // the displayed status will be the updated status before the UI is hidden.
  // To prevent this, we cache the status before the value updates and display
  // it until the UI is hidden.
  const onChangeStatus = async () => {
    setCachedStatus(status);
    await toggleStatus();
    onDismiss();
    setCachedStatus(undefined);
  };

  const onMarkAsUnreadPress = async () => {
    if (!channel) return;
    await markAsUnread(getCareguideIds(channel));
    onDismiss();
  };

  const actions: BottomSheetAction[] = [];

  if (statusVisibleToUser) {
    actions.push({
      icon: (cachedStatus || status) === 'ongoing' ? 'check' : 'reply-all',
      title: (cachedStatus || status) === 'ongoing' ? 'Resolve' : 'Unresolve',
      onPress: onChangeStatus,
    });
  }

  if (isMarkUnreadAvailable) {
    actions.push({ icon: 'mark-chat-unread', title: 'Mark as Unread', onPress: onMarkAsUnreadPress, isDisabled });
  }

  const openConversationMenu = (c: Channel) => {
    setIsVisible(true);
    setChannel(c);
  };

  return {
    // If there are no options to display in the menu, don't display the menu at all
    openConversationMenu: actions.length ? openConversationMenu : undefined,
    isVisible,
    actions,
    onDismiss,
  };
};

import type { Channel, UserResponse } from 'stream-chat';

export const getChannelPatient = (channel: Channel | null | undefined): UserResponse | undefined => {
  if (!channel?.state.members) {
    return undefined;
  }

  return Object.values(channel.state.members)
    .map(member => member.user)
    .find(user => user?.role === 'patient');
};

import { useEffect, useRef } from 'react';

import { getDeferred, useEvent } from '@almond/utils';

/**
 * Wraps the token in a stable function that returns a stable promise. This allows the Stream
 * SDK to repeatedly call a single function and know it's always receiving the most up-to-date
 * token, provided by the most recent /users/me call. This reduces instances of token expiration
 * when the application is open for long periods of time. /users/me is re-called on tab focus,
 * so even for a long-running tab, if a user comes back the tab should have a valid Stream token.
 * @param token The stream token for this render
 * @returns A function that returns a stable promise that resolves to a Stream token.
 *    The promise only changes its reference (oldPromise !== newPromise) when the token
 *    is updated, NOT when the token is initially set (undefined->defined).
 */
export const useStreamTokenProvider = (token: string | undefined) => {
  const deferredRef = useRef(getDeferred<string>());
  const oldTokenRef = useRef(token);

  useEffect(() => {
    if (oldTokenRef.current) {
      // There was a previous token value, so we need to overwrite it with a new deferred
      deferredRef.current = getDeferred<string>();
    }

    if (token) {
      // There is a new token value, so resolve to it
      deferredRef.current.resolve(token);
    }

    oldTokenRef.current = token;
  }, [token, deferredRef]);

  return useEvent(() => {
    return deferredRef.current.promise;
  });
};

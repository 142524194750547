import { initialSortChannelsComparator } from './initialSortChannelsComparator';

import type { ExtendedUserDetail } from '~/modules/user/types';
import type { Channel } from 'stream-chat';

export const sortChannelsComparator = (
  a: Channel,
  b: Channel,
  currentUser: ExtendedUserDetail,
  unreadMessagesMap: Record<string, number>
) => {
  const aRead = a.state.read[currentUser.streamId].unread_messages || (a.id ? unreadMessagesMap[a.id] : undefined);
  const bRead = b.state.read[currentUser.streamId].unread_messages || (b.id ? unreadMessagesMap[b.id] : undefined);

  // Then any unread ones
  if (aRead) return -1;
  if (bRead) return 1;

  // And finally, the other ones sorted by `last_message_at`
  return initialSortChannelsComparator(a, b);
};

export const getFullName = (userObject?: null | Record<string, unknown>): string => {
  const id = typeof userObject?.id === 'string' ? userObject.id : 'unknown';

  if (!userObject || typeof userObject.name !== 'string') {
    return id;
  }

  const hasNameParts = ['first_name', 'last_name'].every(key => userObject[key] && typeof userObject[key] === 'string');

  if (!hasNameParts) {
    return userObject.name || id;
  }

  let calledName = userObject.first_name;

  if (userObject.preferred_first_name && typeof userObject.preferred_first_name === 'string') {
    calledName = userObject.preferred_first_name;
  }

  return `${calledName} ${userObject.last_name}`;
};

import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  modalBackdrop: {
    backgroundColor: 'transparent',
  },

  menu: {
    ...getShadow(),
    backgroundColor: theme.background,
    width: 160,
    position: 'absolute',
    top: 52,
    end: sizes.SPACING_M,
    padding: 12,
    rowGap: 10,
    borderRadius: sizes.SPACING_XS,
  },

  menuText: {
    color: theme.darkText,
  },

  button: {
    marginEnd: sizes.SPACING_M,
  },
}));

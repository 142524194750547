import React, { useEffect, useState } from 'react';

import { setHeapUser } from '@almond/analytics';
import { useUpdateUser } from 'statsig-react';
import { StreamChat } from 'stream-chat';
import { Chat, Streami18n } from 'stream-chat-react';

import { useCaptureError } from '~/modules/logging';
import { useCurrentUser } from '~/modules/user';
import { env } from '~env';

import { useStreamTokenProvider } from './useStreamTokenProvider';

const AlmondChat: React.FC<React.PropsWithChildren> = props => {
  const [client] = useState(() => StreamChat.getInstance(env.STREAM_API_KEY, { timeout: 5000 }));
  const [i18nInstance] = useState(() => new Streami18n({ language: 'en' }));
  const currentUser = useCurrentUser();
  const updateStatsigUser = useUpdateUser();
  const captureError = useCaptureError();

  // Split these out into their own variables. If the API is called again, but it returns
  // the exact same strings, we don't want the useEffect() to run again
  const { streamId, streamToken, authId, patientUuid } = currentUser || {};

  const streamTokenProvider = useStreamTokenProvider(streamToken);
  const { connectUser } = client;

  useEffect(() => {
    if (!authId || !streamId) return;

    const toCall = async () => {
      try {
        const event = await connectUser({ id: streamId }, streamTokenProvider);

        updateStatsigUser({
          userID: authId,
          custom: {
            patientUuid,
            role: event?.me?.role,
          },
        });
        setHeapUser(authId, {
          patient_uuid: patientUuid,
          // We could determine a more specific role than "internal" here, but in the interest
          // of matching the value from Demi, leaving it as-is here. We can always look up the
          // user's Auth0 ID to determine who it is.
          role: patientUuid ? 'patient' : 'internal',
        });
      } catch (error) {
        captureError(error);
      }
    };

    toCall();
  }, [captureError, connectUser, streamId, authId, patientUuid, streamTokenProvider, updateStatsigUser]);

  return (
    <Chat i18nInstance={i18nInstance} client={client}>
      {props.children}
    </Chat>
  );
};

export { AlmondChat as Chat };

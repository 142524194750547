import React from 'react';

import { IconButton } from '@almond/ui';
import { useRouter } from 'expo-router';

import { useAdminSidebar } from '../../hooks';

export const BackButton: React.FC = () => {
  const { replace } = useRouter();
  const { isSidebarOpen, toggleSidebar } = useAdminSidebar();

  const handlePress = () => {
    if (isSidebarOpen) return toggleSidebar();

    replace('/(admin)/(conversations)/');
  };

  return (
    <IconButton
      source="arrow-back"
      onPress={handlePress}
      color="text"
      testID="Back"
      accessibilityLabel="Previous Screen"
    />
  );
};

import { titleCase } from 'title-case';
import { v4 } from 'uuid';

import { CATEGORIES } from '../../config';
import { getClosestCategory } from '../../utils';

export const parseTodoFromElation = <ElationTodo extends { text: string }>(
  elationTitle: string,
  children: ElationTodo[]
) => {
  // split only on the first colon
  // https://stackoverflow.com/questions/4607745/split-string-only-on-first-instance-of-specified-character
  const [text1, text2] = elationTitle.split(/:(.*)/);

  const title = (text2 || text1).trim();
  const initialCategory = text2 ? text1.trim() : undefined;

  return {
    uuid: `pending-${v4()}`,
    isPending: true,
    title: titleCase(title),
    category: getClosestCategory(CATEGORIES, initialCategory)?.id ?? initialCategory,
    initialCategory,
    description: children.map(b => b.text.trim()).join('\n'),
  } as const;
};

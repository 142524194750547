import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

export type ErrorProps = {
  error: any;
};

export const Error: React.FC<ErrorProps> = props => {
  const [styles] = useTheme(themedStyles);

  if (!props.error) return null;

  const error = (() => {
    if (typeof props.error === 'string') return props.error;
    if ('message' in props.error) return props.error.message;

    return 'Error loading the data. Please, refresh the page.';
  })();

  return (
    <View style={styles.container}>
      <Text style={styles.error}>{error}</Text>
    </View>
  );
};

import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    ...getShadow(),
    backgroundColor: theme.background,
    borderRadius: sizes.SPACING_M,
    padding: sizes.SPACING_L,
  },

  title: {
    marginBottom: sizes.SPACING_M,
  },
}));

import React from 'react';
import { View } from 'react-native';

import { ActivityIndicator, Button, ScrollView, useBrowserTypeMap, useTheme } from '@almond/ui';

import { Error } from '../Error';
import { Placeholder } from '../Placeholder';
import { themedStyles } from './listModalStyles';
import { Modal } from './Modal';

import type { ModalProps } from './Modal';
import type { StyleProp, ViewStyle } from 'react-native';

// Get all properties of T that are to a string or number value
type GetIdPropertyCandidate<T> = { [P in keyof T]: T[P] extends string | number ? P : never }[keyof T];

// If T has an 'id' property, then keyProp is optional
type GetKeyProp<T> =
  GetIdPropertyCandidate<T> extends 'id'
    ? { keyProp?: GetIdPropertyCandidate<T> }
    : { keyProp: GetIdPropertyCandidate<T> };

type BaseListModalProps<T> = GetKeyProp<T> &
  React.PropsWithChildren<{
    renderItem: (item: T) => React.ReactNode;
    scrollViewStyle?: StyleProp<ViewStyle>;
    isCloseDisabled?: boolean;
    testID?: string;
  }> &
  Omit<ModalProps, 'subtitle' | 'footer' | 'children'>;

type ListModalPropsWithItems<T> = BaseListModalProps<T> & {
  items: readonly T[];
  result?: never;
};
type ListModalPropsWithResult<T> = BaseListModalProps<T> & {
  items?: never;
  result: { data: readonly T[] | undefined; isLoading: boolean; error: any };
};

export type ListModalProps<T> = ListModalPropsWithItems<T> | ListModalPropsWithResult<T>;

export const ListModal = <T,>(props: ListModalProps<T>) => {
  const [styles] = useTheme(themedStyles);
  const { items, result, children, renderItem, keyProp = 'id', scrollViewStyle, isCloseDisabled, ...restProps } = props;
  const { isMobile } = useBrowserTypeMap();

  const data = items ?? result?.data;

  let content: React.ReactNode;

  if (result?.isLoading) content = <ActivityIndicator />;
  else if (result?.error) content = <Error error={result.error} />;
  else if (data?.length)
    content = (
      <ScrollView style={[styles.scrollView, scrollViewStyle]}>
        <View role="list">
          {data.map(item => (
            <View key={item[keyProp as GetIdPropertyCandidate<T>] as any} role="listitem" style={styles.item}>
              {renderItem(item)}
            </View>
          ))}
        </View>
      </ScrollView>
    );
  else content = <Placeholder>{"There's no data to show here."}</Placeholder>;

  const footer = (
    <Button
      onPress={props.onRequestClose}
      style={[styles.doneButton, isMobile && styles.doneButtonMobile]}
      type="secondary"
      isDisabled={isCloseDisabled}
      size="m"
    >
      {'Done'}
    </Button>
  );

  return (
    <Modal {...restProps} footer={footer} containerStyle={isMobile && styles.containerMobile}>
      {children}
      {content}
    </Modal>
  );
};

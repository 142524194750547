import { Dimensions } from 'react-native';

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    width: '100%',
    maxWidth: 580,
    minHeight: 'min(600px, 100vh)' as any,
  },

  containerMobile: {
    minHeight: '100%',
  },

  center: {
    alignSelf: 'center',
  },

  title: {
    color: theme.darkText,
  },

  subtitle: {
    marginTop: sizes.SPACING_S,
    textAlign: 'center',
  },

  subtitleDesktop: {
    maxWidth: '70%',
  },

  contentContainer: {
    flex: 1,
    marginBottom: sizes.SPACING_L,
  },

  contentContainerDesktop: {
    maxHeight: Dimensions.get('screen').height * 0.4,
  },
}));

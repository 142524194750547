import React from 'react';

import { MaterialIcon, sizes } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';

import { useCurrentUser } from '~/modules/user';

import { useToggleMember } from '../../hooks';
import { HeaderButton } from './HeaderButton';

// For /elation/[elationId] routes (i.e. for the extension), we show a
// Leave button in the channel header for providers
export const LeaveChannelButton = () => {
  const { channel, client } = useChatContext();
  const currentUser = useCurrentUser();
  const currentUserRole = client.user?.role;

  const { buttonTitle, toggleMember, isChannelMember } = useToggleMember({
    user: { id: currentUser?.streamId, role: currentUserRole },
    channel,
    streamId: currentUser?.streamId,
  });

  if (!isChannelMember) {
    return null;
  }

  return (
    <HeaderButton
      onPress={toggleMember}
      testID="LeaveChannelButton"
      text={buttonTitle}
      icon={<MaterialIcon source="close" size={sizes.FONT_SIZE_M} color="placeholder" />}
    />
  );
};

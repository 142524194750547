import React from 'react';
import { View } from 'react-native';

import { Avatar, avatarSizes, SkeletonLoader, Text, useTheme } from '@almond/ui';

import { getFullName, useCurrentUser } from '~/modules/user';

import { useChannelData, useChannelUnreadCount, useIsTyping } from '../../hooks';
import { getLatestMessageUpdatedAt, getPreviewMessageText } from '../../utils';
import { Name } from './Name';
import { PreviewContainer } from './PreviewContainer';
import { Status } from './Status';

import { themedStyles } from './styles';

import type { PreviewContainerProps } from './PreviewContainer';
import type { DefaultGenerics } from 'stream-chat';
import type { AvatarProps, ChannelPreviewUIComponentProps } from 'stream-chat-react';

export type PreviewProps = ChannelPreviewUIComponentProps<DefaultGenerics> &
  Pick<PreviewContainerProps, 'onLongPress'> & {
    isSearching: boolean;
  };

export const Preview: React.FC<PreviewProps> = props => {
  const [styles] = useTheme(themedStyles);
  const { channel, unread } = props;
  const currentUser = useCurrentUser();
  const { isTyping, typingUser } = useIsTyping(channel);
  const previewMessageText = getPreviewMessageText(channel, isTyping, typingUser);
  const latestMessageUpdatedAt = getLatestMessageUpdatedAt(channel);
  const channelData = useChannelData(channel);
  const patientName = getFullName(channelData);
  const patientDOB = `DOB: ${channelData?.birthday}`;
  const patientImage = channelData?.image as AvatarProps['image'];
  const latestMessageUserRole = channel.state.messages.at(-1)?.user?.role;
  const isPatientTyping = typingUser[0]?.role === 'patient';
  const unreadCount = useChannelUnreadCount(channel, currentUser?.streamId, unread);

  return (
    <PreviewContainer {...props} unreadCounter={unreadCount} isTyping={isTyping} isPatientTyping={isPatientTyping}>
      <Avatar image={patientImage} user={channelData} size="l" />
      <View style={styles.center}>
        <Name name={patientName} />
        <Text numberOfLines={1} family="SystemFont" fontStyle="book" size="xs">
          {patientDOB}
        </Text>
        <Text
          numberOfLines={1}
          style={[
            styles.latestMessage,
            !!unreadCount && latestMessageUserRole === 'patient' && styles.latestMessageBoldBlack,
            isTyping && isPatientTyping && styles.latestMessageBoldBlue,
          ]}
          family="SystemFont"
          fontStyle="book"
          size="m"
        >
          {previewMessageText}
        </Text>
      </View>
      <View style={styles.right}>
        <Text numberOfLines={1} family="SystemFont" fontStyle="book" size="xxs">
          {latestMessageUpdatedAt}
        </Text>
        <Status {...props} unreadCounter={unreadCount} />
      </View>
    </PreviewContainer>
  );
};

export const LoadingPreview = () => {
  const [styles, theme] = useTheme(themedStyles);

  return (
    <View style={styles.previewContainer}>
      <SkeletonLoader.Circle diameter={avatarSizes.l} backgroundColor={theme.darkSecondaryBackground} />
      <View style={[styles.center, styles.loadingText]}>
        <SkeletonLoader.Rectangle height={12} width={100} />
        <SkeletonLoader.Rectangle height={6} width={100} />
        <SkeletonLoader.Rectangle height={10} width={100} />
      </View>
      <View style={styles.right}>
        <SkeletonLoader.Rectangle height={8} width={40} />
      </View>
    </View>
  );
};

import { StyleSheet } from 'react-native';

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  modal: {
    width: 579,
    height: 595,
    padding: 0,
    paddingHorizontal: sizes.SPACING_M,
    paddingTop: sizes.SPACING_M,
  },

  contentContainer: {
    flex: 1,
    rowGap: sizes.SPACING_XL,
    paddingVertical: sizes.SPACING_M,
  },

  contentContainerDesktop: {
    paddingHorizontal: 48,
  },

  referral: {
    alignSelf: 'center',
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border,
    padding: sizes.SPACING_M,
    borderRadius: sizes.SPACING_S,
  },

  buttonsContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    columnGap: sizes.SPACING_S,
    marginTop: sizes.SPACING_S,
  },

  linkButton: {
    width: 98,
  },

  linkText: {
    alignSelf: 'center',
    marginTop: sizes.SPACING_XS,
  },

  howItWorks: {
    backgroundColor: theme.secondaryBackground,
    justifyContent: 'center',
    borderRadius: sizes.SPACING_M,
    paddingVertical: sizes.SPACING_L,
    paddingHorizontal: 80,
    rowGap: sizes.SPACING_M,
  },

  howItWorksMobile: {
    paddingHorizontal: sizes.SPACING_L,
  },

  howItWorksSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  howItWorksIndex: {
    width: sizes.SPACING_M,
    flexShrink: 0,
  },

  textAccent: {
    color: theme.accent,
  },

  textTertiary: {
    color: theme.darkText,
  },

  textCenter: {
    textAlign: 'center',
  },

  termsOfReferral: {
    rowGap: sizes.SPACING_M,
  },

  copiedText: {
    marginTop: sizes.SPACING_S,
  },

  doneButton: {
    width: 160,
    alignSelf: 'center',
    marginVertical: sizes.SPACING_M,
  },
}));

import { BaseButton, MaterialIcon, sizes, Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

export type SeeMoreButtonProps = {
  isExpanded: boolean;
  onPress: () => void;
};

export const SeeMoreButton = (props: SeeMoreButtonProps) => {
  const { isExpanded, onPress } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <BaseButton onPress={onPress} style={styles.seeMoreButton}>
      <Text selectable={false} fontStyle="medium">
        {isExpanded ? 'See less' : 'See more'}
      </Text>
      <MaterialIcon
        source={isExpanded ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
        color="darkText"
        size={sizes.SPACING_L}
      />
    </BaseButton>
  );
};

import { useAuth0 } from '@auth0/auth0-react';
import useSWR from 'swr';

import { env } from '~env';

import { printJestWarnings, processResponse } from '../utils';
import { useGetHeaders } from './useGetHeaders';
import { useLogout } from './useLogout';

import type { AddPrefix, StripPrefix } from '../types';
import type { ApiRestEndpoint, paths, RequestParams, SuccessResponse } from '@almond/api-types';
import type { SWRConfiguration, SWRResponse } from 'swr';

export type AlmondApiQueryOptions<Path extends StripPrefix<keyof paths>> =
  | SWRConfiguration<SuccessResponse<ApiRestEndpoint<AddPrefix<Path>, 'get'>>>
  | (RequestParams<ApiRestEndpoint<AddPrefix<Path>, 'get'>> extends never
      ? never
      : { query: RequestParams<ApiRestEndpoint<AddPrefix<Path>, 'get'>> });

/**
 * Custom hook to call Almond APIs.
 * @param path API path to call
 * @param options SWR options
 * @returns SWR Return object
 */
export const useAlmondApiQuery = <Path extends StripPrefix<keyof paths>>(
  path: Path | null,
  // TODO figure out how to make this required for paths with query params
  options: AlmondApiQueryOptions<Path> = {} as AlmondApiQueryOptions<Path>
): SWRResponse<SuccessResponse<ApiRestEndpoint<AddPrefix<Path>, 'get'>>> => {
  const { isLoading, isAuthenticated } = useAuth0();
  const getHeaders = useGetHeaders();
  const logout = useLogout();

  printJestWarnings(isLoading, isAuthenticated);

  const query = new URLSearchParams('query' in options && options.query ? options.query : {}).toString();

  let pathWithQuery: string | null = path;

  if (path && query) {
    pathWithQuery += `?${query}`;
  }

  return useSWR(
    isLoading || !isAuthenticated ? null : pathWithQuery,
    async (url: string) => {
      const fullUrl = `${env.API_BASE_URL}/api/v2${url}`;
      const headers = await getHeaders();
      const res = await fetch(fullUrl, { headers });

      if (res.status === 401) {
        return logout();
      }

      return processResponse(res);
    },
    { ...options, revalidateOnFocus: 'revalidateOnFocus' in options ? options.revalidateOnFocus : false }
  );
};

import { StyleSheet } from 'react-native';

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  item: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: theme.border,
  },
  scrollView: {
    paddingHorizontal: sizes.SPACING_L,
    marginTop: sizes.SPACING_S,
  },
  doneButton: {
    alignSelf: 'flex-end',
    width: 60,
  },
  doneButtonMobile: {
    marginHorizontal: sizes.SPACING_L,
  },
  disabled: {
    opacity: 0.5,
  },
  containerMobile: {
    padding: 0,
    paddingVertical: sizes.SPACING_L,
  },
}));

import { createContext, useContext, useMemo, useState } from 'react';

import { getFileType } from '../../utils';
import { DocumentViewer } from './DocumentViewer';

import type { ExtendedDocumentOut } from '../../types';
import type { FC, PropsWithChildren } from 'react';

type PartialDocument = Pick<ExtendedDocumentOut, 'filename' | 'fileLocation'>;

type DocumentContext = {
  closeDocument: () => void;
  openDocument: (document?: PartialDocument | null, location?: string) => void;
  isDocumentOpen: boolean;
};

const DocumentViewerContext = createContext<DocumentContext | null>(null);

export const DocumentViewerProvider: FC<PropsWithChildren> = props => {
  const [currentDocument, setCurrentDocument] = useState<PartialDocument | null>(null);

  const value = useMemo(
    () => ({
      openDocument: (document?: PartialDocument | null, location: string = '_blank') => {
        if (!document) {
          return;
        }

        const fileType = getFileType(document.filename);

        if (fileType === 'pdf') {
          window.open(document.fileLocation, location, 'noopener');
        } else {
          setCurrentDocument(document);
        }
      },
      closeDocument: () => {
        setCurrentDocument(null);
      },
      isDocumentOpen: currentDocument !== null,
    }),
    [currentDocument]
  );

  return (
    <DocumentViewerContext.Provider value={value}>
      {props.children}
      <DocumentViewer document={currentDocument} onClose={value.closeDocument} />
    </DocumentViewerContext.Provider>
  );
};

export const useDocumentViewer = () => {
  const context = useContext(DocumentViewerContext);

  if (!context) {
    throw new Error('useDocumentViewer must be used within a DocumentViewerProvider');
  }

  return context;
};

.str-chat__channel-list-react .str-chat__channel-list-messenger-react {
  padding-bottom: 0;
  background-color: var(--color-light-secondary-background);
}

.str-chat {
  border: 0;
}

@media only screen and (max-width: 768px) {
  .str-chat__channel-list {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

export type ItemWithActionProps = {
  icon: React.ReactNode;
  title: string;
  action: React.ReactNode;
  separator?: boolean;
};

export const ItemWithAction: React.FC<ItemWithActionProps> = props => {
  const { icon, title, action, separator } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View style={[styles.container, separator && styles.separator]}>
      <View style={styles.icon}>{icon}</View>
      <Text style={styles.title} fontStyle="medium" size="m">
        {title}
      </Text>
      {action}
    </View>
  );
};

import { CONTACT_US_PHONE_NUMBER, Link, Text } from '@almond/ui';

import { Section } from '~/modules/ui';

import type { PatientDetailOut, SubscriptionStatusEnum } from '@almond/api-types';

export type MembershipProps = {
  data: PatientDetailOut | undefined;
};

const subscriptionStatusMap: Record<SubscriptionStatusEnum, string> = {
  active: 'Active',
  canceled: 'Canceled',
  inactive: 'Inactive',
  incomplete: 'Inactive',
  incomplete_expired: 'Inactive',
  past_due: 'Active',
  paused: 'Paused',
  trialing: 'Active',
  unpaid: 'Active',
};

export const Membership = (props: MembershipProps) => {
  const { data } = props;

  if (!data?.profile.subscriptionStatus) {
    return null;
  }

  const right = (() => {
    if (['active', 'trialing'].includes(data.profile.subscriptionStatus)) {
      return <Link url="https://almondhealth.typeform.com/to/FMHEJJ4C">{'Cancel Membership'}</Link>;
    }

    if (['inactive', 'incomplete_expired'].includes(data.profile.subscriptionStatus)) {
      return (
        <Text>
          {'Call or text us at '}
          <Link url={`+1${CONTACT_US_PHONE_NUMBER.replace(/[ ()-]/g, '')}`} urlType="phoneNumber" size="l">
            {CONTACT_US_PHONE_NUMBER}
          </Link>
          {' to reactivate'}
        </Text>
      );
    }

    return null;
  })();

  return (
    <Section title="Membership">
      <Text>
        {`Membership Status: ${subscriptionStatusMap[data.profile.subscriptionStatus]}`}
        {right && ' | '}
        {right}
      </Text>
    </Section>
  );
};

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  logo: {
    color: theme.accent,
  },

  admin: {
    color: theme.badge,
  },

  rightContainer: {
    flexDirection: 'row',
  },

  button: {
    marginEnd: sizes.SPACING_M,
  },
}));

/**
 * Utility to replace path params in a URL with the values provided in the params object
 * Note: this mutates the params object
 * @param url URL to replace path params in
 * @param params Params object to replace path params with
 * @returns URL with path params replaced
 */
export const replacePathParams = (url: string, params: Record<string, string>) => {
  let replacedUrl = url;

  Object.keys(params).forEach(key => {
    const searchTerm = `{${key}}`;

    if (url.includes(searchTerm)) {
      replacedUrl = replacedUrl.replace(searchTerm, params[key]);
      // eslint-disable-next-line no-param-reassign
      delete params[key];
    }
  });

  return replacedUrl;
};

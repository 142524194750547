import { useAlmondApiMutation } from '~/modules/api';
import { useCurrentUser } from '~/modules/user';

export const useUpdatePatientMutation = () => {
  const currentUser = useCurrentUser();
  const { trigger: updatePatient, isMutating: isUpdatingPatient } = useAlmondApiMutation(
    'put',
    currentUser?.patientUuid ? `/patients/${currentUser?.patientUuid as '{patient_uuid}'}` : null
  );

  return { updatePatient, isUpdatingPatient };
};

import { useMemo } from 'react';

import { useChatContext } from 'stream-chat-react';
import useSWR from 'swr';

import { authIdToStreamId, getFullName } from '~/modules/user';

import type { DocumentOut } from '@almond/api-types';

export function useProcessDocuments(documents: readonly DocumentOut[] | undefined) {
  const { client } = useChatContext();

  // Only request unique IDs
  const uploadedByIds = [...new Set(documents?.map(document => authIdToStreamId(document.uploadedBy)))];

  const {
    data: usersData,
    isLoading: isLoadingUsers,
    error: usersError,
  } = useSWR(uploadedByIds, ids => {
    return client.queryUsers({ id: { $in: ids } });
  });
  const formattedDocuments = useMemo(() => {
    if (!documents || !usersData) return;

    return documents.map(document => {
      const uploadedByUser = usersData.users.find(user => user.id === authIdToStreamId(document.uploadedBy));

      return { ...document, uploadedByName: getFullName(uploadedByUser) };
    });
  }, [documents, usersData]);

  return {
    documents: formattedDocuments,
    isLoadingUsers,
    usersError,
  };
}

import React from 'react';
import { View } from 'react-native';

import { HOME_SITE_HREF, Link, Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

export const TermsOfReferral = () => {
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.termsOfReferral}>
      <Text style={styles.textCenter} fontStyle="bold" size="m">
        {'Terms of Referral'}
      </Text>
      <Text>
        {[
          'This limited discount only applies to the annual Almond membership fee,',
          ' which includes the services described ',
        ].join('')}
        <Link url={HOME_SITE_HREF}>{'on our website'}</Link>
        {[
          ' and specifically does not apply toward fees for visits, procedures, labs and other clinical care. ',
          'Almond reserves the right to change the terms of the limited discount, including ',
          'period of applicability, at any time in its sole discretion. ',
          'For existing members, the discount will be applied to the membership for the next year.',
        ].join('')}
      </Text>
    </View>
  );
};

import { Slot } from 'expo-router';

import { ErrorContainer } from '~/modules/logging';
import { AdminPatientLayout } from '~/modules/routing';

export { ErrorContainer as ErrorBoundary };

export default () => (
  <AdminPatientLayout>
    <Slot />
  </AdminPatientLayout>
);

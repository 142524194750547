import { useCallback, useEffect, useRef, useState } from 'react';

import { useEvent } from '@almond/utils';

import type { Dispatch, SetStateAction } from 'react';

/**
 * Custom hook to manage cached items based on a filter string.
 *
 * This hook maintains a cache of items mapped to filter strings. This allows for multiple
 * state variable storage within one hook, as the filter string changes, keeping old values
 * around for future retrieval.
 *
 * - The `filterString` is used as the key to store and retrieve cached items.
 * - The `onFilterChange` callback is invoked whenever the `filterString` changes,
 *   passing the previous and current cached items as arguments.
 *
 * It returns the stored item for the current `filterString`, and a state setter function for
 * the current `filterString` value.
 *
 * @param filterString - The string used to filter and cache items.
 * @param onFilterChange - Optional callback that triggers when the filter string changes,
 *                         passing the previous and current values for the respective filter string.
 * @returns Cached item and setter in the same format as useState() - a tuple where the first
 *          value is the state value for the given filter string,
 *          and the second is a setter function to update the state item.
 */
export const useCachedItems = <T extends Record<string, unknown>>(
  filterString: string,
  onFilterChange?: (prevValue: T | undefined, nextValue: T | undefined) => void
) => {
  const [cachedItems, setCachedItems] = useState(() => new Map<string, T | undefined>());
  const onFilterChangeEvent = useEvent(onFilterChange);
  const prevFilterString = useRef(filterString);
  const currentFilterString = useRef(filterString);

  currentFilterString.current = filterString;

  useEffect(() => {
    if (prevFilterString.current !== filterString) {
      onFilterChangeEvent(cachedItems.get(prevFilterString.current), cachedItems.get(filterString));
      prevFilterString.current = filterString;
    }
  }, [filterString, cachedItems, onFilterChangeEvent]);

  const setValue: Dispatch<SetStateAction<T | undefined>> = useCallback(value => {
    setCachedItems(p => {
      const newMap = new Map(p);

      newMap.set(
        currentFilterString.current,
        typeof value === 'function' ? value(p.get(currentFilterString.current)) : value
      );

      return newMap;
    });
  }, []);

  return [cachedItems.get(filterString), setValue] as const;
};

import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

export type NameProps = {
  name?: string;
};

export const Name: React.FC<NameProps> = props => {
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.nameContainer}>
      <Text numberOfLines={1} family="SystemFont" fontStyle="medium" size="m">
        {props.name}
      </Text>
    </View>
  );
};

.details {
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  background: var(--color-error);
}

.summary::marker {
  color: var(--color-background);
}
.text {
  color: var(--color-background);
}

.summaryContainer {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

import { useEffect, useState } from 'react';

export const useIsCloseDisabled = (trigger?: any) => {
  const [moveIntents, setMoveIntents] = useState<string[]>([]);
  const isCloseDisabled = !!moveIntents.length;

  useEffect(() => {
    setMoveIntents([]);
  }, [trigger]);

  const onHasMoveIntentChange = (id: string, hasMoveIntent: boolean) => {
    setMoveIntents(prevState => {
      const filteredMoveIntents = [...prevState].filter(i => i !== id);

      return hasMoveIntent ? [...filteredMoveIntents, id] : filteredMoveIntents;
    });
  };

  return { isCloseDisabled, onHasMoveIntentChange };
};

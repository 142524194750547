import React from 'react';
import { View } from 'react-native';

import { Avatar, Button, Text, useTheme } from '@almond/ui';

import { useToggleMember } from '../../hooks';

import { themedStyles } from './styles';

import type { UseToggleMemberArgs } from '../../hooks';
import type { UserResponse } from 'stream-chat';
import type { DefaultStreamChatGenerics } from 'stream-chat-react';

export type UserItemProps = Omit<UseToggleMemberArgs, 'user'> & {
  user: UserResponse<DefaultStreamChatGenerics>;
};

export const UserItem: React.FC<UserItemProps> = props => {
  const { user, streamId } = props;
  const [styles] = useTheme(themedStyles);
  const { buttonTitle, buttonType, toggleMember } = useToggleMember(props);

  const name = (() => {
    if (user.role === 'patient') return `${user.name} (${user.birthday})`;
    if (streamId === user.id) return `${user.name}, You`;

    return `${user.name}, ${user.label}`;
  })();

  return (
    <View style={styles.userItem}>
      <Avatar image={user.image} user={user} size="xl" />
      <Text fontStyle={streamId === user.id ? 'bold' : 'book'} size="m" style={styles.name}>
        {name}
      </Text>
      {buttonTitle && (
        <Button
          isSquared
          onPress={toggleMember}
          type={buttonType}
          fixedWidth={false}
          style={styles.itemButton}
          size="m"
        >
          {buttonTitle}
        </Button>
      )}
    </View>
  );
};

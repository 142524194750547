import { sizes, styleSheetFactory } from '@almond/ui';

const height = 48;
const bigHeight = 72;
const padding = sizes.SPACING_S;

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    height,
    flexDirection: 'row',
    alignItems: 'center',
    padding,
    columnGap: sizes.SPACING_M,
  },
  containerBig: {
    height: bigHeight,
  },
  previewButton: {
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    columnGap: sizes.SPACING_M,
  },
  thumbnail: {
    borderRadius: sizes.SPACING_S,
  },
  mobileThumbnail: {
    borderRadius: sizes.SPACING_XS,
  },
  fileName: {
    color: theme.darkText,
  },
  fileSize: {
    color: theme.secondaryText,
  },
  body: {
    flex: 1,
  },

  mutateButtonContainer: {
    flexDirection: 'row',
    height: '100%',
    gap: sizes.SPACING_S,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectInputContainer: {
    // Quick-and-dirty way of making the input smaller
    // Not for use in patient-facing UI!
    transform: [{ scale: 0.6 }],
    transformOrigin: '0',
    marginRight: -90,
    width: 240,
  },
}));

import { useCallback, useState } from 'react';

import { loginSuccessfulSchema, WindowMessageSender } from '@almond/extension-utils';
import { useFocusEffect, useRouter } from 'expo-router';

import type { AppState } from '@auth0/auth0-react';

const openerSameOrigin = () => {
  try {
    return window.opener?.origin === window.origin;
  } catch {
    // Security issue prevented us from accessing window.opener.origin
    // because it's embedded in a different frame
    return false;
  }
};

export const useRedirectCallback = () => {
  const router = useRouter();
  const [returnTo, setReturnTo] = useState<string>();
  const [params, setParams] = useState<Record<string, any>>();

  useFocusEffect(
    useCallback(() => {
      if (openerSameOrigin()) {
        // This is a new tab opened by the browser extension for the purpose
        // of logging in. Now that we are logged in, reload the extension
        // and close this tab, which isn't needed anymore.
        const messageSender = new WindowMessageSender(window, window.opener, window.location.href);

        messageSender.send(loginSuccessfulSchema.parse({ type: 'login_successful' })).then(() => {
          window.close();
        });

        return;
      }

      if (!returnTo && !params) return;

      router.replace({ pathname: returnTo as any, params });

      setReturnTo(undefined);
      setParams(undefined);
    }, [returnTo, params, router])
  );

  return useCallback((appState?: AppState) => {
    if (appState?.returnTo) {
      setReturnTo(appState.returnTo);
    }

    if (appState?.params) {
      setParams(appState.params);
    }
  }, []);
};

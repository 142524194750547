import { forwardRef, useEffect, useState } from 'react';
import { Image, Pressable, View } from 'react-native';

import { Text, useTheme } from '@almond/ui';

import { useImages } from '~assets';

import { themedStyles } from './styles';

import type { ImageProps, PressableProps, StyleProp, ViewStyle } from 'react-native';

type ImageWithFallbackProps = Omit<ImageProps, 'source'> & {
  source: { uri: string };
  onPress?: PressableProps['onPress'];
  onPressStyles?: StyleProp<ViewStyle>;
};

export const ImageWithFallback = forwardRef<Image, ImageWithFallbackProps>((props, ref) => {
  const { onPress, source, onError, style, onPressStyles, ...otherProps } = props;
  const [currentSrc, setCurrentSrc] = useState<string | Error>(source.uri);
  const [errorSize, setErrorSize] = useState(0);
  const images = useImages();
  const [styles] = useTheme(themedStyles);

  useEffect(() => {
    setCurrentSrc(source.uri);
  }, [source.uri]);

  const internalOnError: ImageProps['onError'] = event => {
    if (onError) return onError(event);
    setCurrentSrc(new Error('Image failed to load'));
  };

  const isError = currentSrc instanceof Error;
  const isPressable = onPress && !isError;

  const ele = isError ? (
    <View
      style={styles.notFoundImage}
      onLayout={event => {
        // Dynamically size the icon to be half the size of the container
        const { width, height } = event.nativeEvent.layout;
        const dimension = Math.min(width, height);

        setErrorSize(dimension * 0.7);
      }}
    >
      <Image style={{ width: errorSize, height: errorSize }} resizeMode="contain" source={images.universe2} ref={ref} />
      <Text style={[styles.notFoundText, { fontSize: errorSize * 0.13 }]}>Poof! Image deleted</Text>
    </View>
  ) : (
    <Image
      {...otherProps}
      style={isPressable ? { width: '100%', height: '100%' } : style}
      source={source}
      onError={internalOnError}
      ref={ref}
    />
  );

  if (isPressable) {
    return (
      <Pressable onPress={onPress} style={[style, onPressStyles]}>
        {ele}
      </Pressable>
    );
  }

  return ele;
});

import React from 'react';

import { Badge, MaterialIcon } from '@almond/ui';

import { getChannelPatient } from '../../utils';

import type { DefaultGenerics } from 'stream-chat';
import type { ChannelPreviewUIComponentProps } from 'stream-chat-react';

export type StatusProps = React.PropsWithChildren<ChannelPreviewUIComponentProps<DefaultGenerics>> & {
  unreadCounter?: number;
};

export const Status: React.FC<StatusProps> = props => {
  const { channel, unreadCounter } = props;
  const latestMessage = channel.state.messages.at(-1);
  const patientId = getChannelPatient(channel)?.id;
  const isReadByPatient = patientId && channel.state.read[patientId].unread_messages === 0;
  const isSending = latestMessage?.status === 'sending';
  const isDelivered = latestMessage?.status === 'received' && !isReadByPatient;
  const isDeliveredAndRead = latestMessage?.status === 'received' && isReadByPatient;
  const isFailed = latestMessage?.status === 'failed';

  const iconName = (() => {
    if (isSending) return 'check';
    if (isDelivered) return 'check';
    if (isDeliveredAndRead) return 'done-all';
    if (isFailed) return 'info';

    return undefined;
  })();
  const iconColor = (() => {
    if (isSending) return 'primary';
    if (isDelivered) return 'secondaryTextDark';
    if (isDeliveredAndRead) return 'secondaryTextDark';
    if (isFailed) return 'error';

    return undefined;
  })();

  if (!latestMessage || !iconName || !iconColor) return null;
  if (unreadCounter) return <Badge testID="ChannelList-Item-Badge">{unreadCounter}</Badge>;

  return <MaterialIcon source={iconName} color={iconColor} testID={`ChannelList-Item-${iconName}`} size={14} />;
};

import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    flex: 1,
    flexDirection: 'row-reverse',
  },

  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  channel: {
    backgroundColor: theme.lightSecondaryBackground,
    flexDirection: 'column',
    flexGrow: 1,
  },
  channelWindow: {
    flexDirection: 'column',
  },
  channelContent: {
    flexDirection: 'column',
    width: '100%',
  },
}));

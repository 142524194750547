import React from 'react';
import { View } from 'react-native';

import { Link, Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { AppointmentInfoOut } from '@almond/api-types';

export type PatientVisitPreviewItemProps = {
  item: AppointmentInfoOut;
};

export const PatientVisitPreviewItem: React.FC<PatientVisitPreviewItemProps> = props => {
  const { item } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.itemPreview}>
      <Link url={item.rebookingUrl} size="s" numberOfLines={1}>
        {item.appointmentTime}
      </Link>
      <Text style={styles.itemTitle} size="s" numberOfLines={1}>
        {`${item.isTelehealth ? 'Video' : 'Office'} Visit with ${item.providerName}`}
      </Text>
      <Text style={styles.itemTitle} size="s" numberOfLines={1}>
        Visit Reason: {item.visitReasons.join(', ')}
      </Text>
    </View>
  );
};

const INACTIVE_STATUSES = ['incomplete', 'incomplete_expired'];

export const formatSubscriptionStatus = (status: string | undefined) => {
  if (!status) {
    return 'Unknown';
  }

  if (INACTIVE_STATUSES.includes(status)) {
    return 'Incomplete';
  }

  return status.charAt(0).toUpperCase() + status.slice(1);
};

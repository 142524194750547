import React from 'react';
import { View } from 'react-native';

import { Text, useBrowserType, useTheme } from '@almond/ui';

import { LocalImage } from '~/modules/ui';

import { themedStyles } from './styles';

export const NoConversation: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const browserType = useBrowserType();

  if (browserType === 'mobile') return null;

  return (
    <View style={styles.container}>
      <LocalImage source="plant2" resizeMode="contain" style={styles.image} />
      <Text style={styles.title} fontStyle="bold" size="xl">
        {'Search for a patient conversation by name or DOB.'}
      </Text>
      <Text style={styles.subtitle} fontStyle="medium" size="m">
        {'Join a conversation to send messages.\nLeave the conversation to be a view-only member.'}
      </Text>
    </View>
  );
};

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  container: {
    // 50% width children
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin: -sizes.SPACING_XS,
  },
  item: {
    width: '50%',
    padding: sizes.SPACING_XS,
  },
  button: {
    marginTop: sizes.SPACING_S,
  },

  // Inside modal
  items: {
    marginHorizontal: sizes.SPACING_L,
    marginTop: sizes.SPACING_XXL,
  },
}));

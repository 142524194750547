import type { Channel, ChannelFilters } from 'stream-chat';

export const streamChannelFilter = (channel: Channel | undefined, filters: ChannelFilters | undefined): boolean => {
  if (!channel || !filters) {
    return false;
  }

  const channelData = channel.data || {};

  return Object.entries(filters).every(([filterKey, filterValue]: [string, unknown]) => {
    let channelValue: unknown;

    if (filterKey === 'members') {
      channelValue = Object.keys(channel.state.members);
    } else if (filterKey in channelData) {
      channelValue = channelData[filterKey as keyof Channel];
    }

    if (filterKey === '$or' && Array.isArray(filterValue)) {
      return filterValue.some(f => streamChannelFilter(channel, f));
    }

    if (!filterValue || typeof filterValue !== 'object') {
      throw new Error(`Invalid filter value at key: ${filterKey}`);
    }

    if ('$eq' in filterValue && filterValue.$eq) {
      return channelValue === filterValue.$eq;
    }

    if ('$autocomplete' in filterValue && filterValue.$autocomplete && typeof filterValue.$autocomplete === 'string') {
      if (typeof channelValue !== 'string') {
        return false;
      }

      // This is guessing at the autocomplete method used under-the-hood by Stream.
      // It's not documented anywhere, but here is the behavior we've observed:
      // - Search string
      //   - Split into words on non-alphanumeric characters, except hyphen
      //   - For each word, check if any channel value word (see below) starts with the word
      //   - Words are matched case-insensitively
      // - Value string
      //   - Split into words on non-alphanumeric characters, INCLUDING hyphens
      //   - Also keep hyphens as an alternate string to match against
      //   - Words are matched case-insensitively

      const searchWords = filterValue.$autocomplete
        .toLowerCase()
        .replace(/[^a-zA-Z0-9-]/g, ' ') // Replace non-alphanumeric and non-hyphen characters with a space
        .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
        .split(' ');

      const valueWords = channelValue
        .toLowerCase()
        .replace(/[^a-zA-Z0-9-]/g, ' ') // Replace non-alphanumeric and non-hyphen characters with a space
        .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
        .split(' ')
        .flatMap(word => {
          // Allow searching against second part of hyphenated words
          if (word.includes('-')) {
            return [word, ...word.split('-')];
          }

          return [word];
        });

      return searchWords.every(searchWord => {
        return valueWords.some(valueWord => valueWord.startsWith(searchWord));
      });
    }

    if ('$in' in filterValue && filterValue.$in && Array.isArray(filterValue.$in)) {
      return filterValue.$in.some(v => (Array.isArray(channelValue) ? channelValue.includes(v) : channelValue === v));
    }

    throw new Error(`Not implemented yet: ${Object.keys(filterValue)}`);
  });
};

export const formatSmsStatus = (status: string | undefined, isSmsVerified: boolean) => {
  if (status === 'opted_out') {
    return 'Opted-Out';
  }

  if (status === 'opted_in' && isSmsVerified) {
    return 'Opted-In';
  }

  if (status === 'opted_in' && !isSmsVerified) {
    return 'Opted-In (unverified)';
  }

  return 'Not yet seen';
};

import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  container: {
    flex: 1,
  },

  hidden: {
    flex: 1,
    display: 'none',
  },
}));

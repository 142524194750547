import React, { useMemo } from 'react';
import { View } from 'react-native';

import { ActivityIndicator, Text, useBrowserTypeMap, useTheme } from '@almond/ui';
import { useToggle } from '@almond/utils';

import { useAlmondApiQuery } from '~/modules/api';
import { Error, LocalImage } from '~/modules/ui';
import { useCurrentUser } from '~/modules/user';

import { PatientVisitItem } from '../PatientVisitItem';
import { SeeMoreButton } from './SeeMoreButton';

import { themedStyles } from './styles';

export const PatientVisits: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const currentUser = useCurrentUser();
  const { data, error, isLoading } = useAlmondApiQuery(
    currentUser?.patientUuid
      ? `/patients/${currentUser?.patientUuid as '{patient_uuid}'}/appointments/future_appointments`
      : null
  );
  const [expanded, toggleExpanded] = useToggle();
  const { isDesktop } = useBrowserTypeMap();
  const content = useMemo(() => {
    const appointments = expanded ? data?.appointments : data?.appointments.slice(0, 1);

    if (isLoading) {
      return (
        <View style={styles.content}>
          <ActivityIndicator />
        </View>
      );
    }

    return (
      <>
        <View style={styles.content}>
          {appointments?.map(appointment => <PatientVisitItem key={appointment.rebookingUrl} item={appointment} />)}
        </View>
        {isDesktop && <LocalImage resizeMode="contain" source="plant2" style={styles.image} />}
        {data && data.appointments.length > 1 && <SeeMoreButton isExpanded={expanded} onPress={toggleExpanded} />}
      </>
    );
  }, [data, expanded, isDesktop, isLoading, styles.content, styles.image, toggleExpanded]);

  if (error) return <Error error={error} />;
  if (!data?.appointments.length) return null;

  return (
    <View style={styles.container}>
      <Text size="l" fontStyle="bold" style={styles.title}>
        {'Upcoming Visits'}
      </Text>
      {content}
    </View>
  );
};

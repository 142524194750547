export const formatPersonName = (userObject?: null | Record<string, unknown>): string | undefined => {
  if (!userObject) {
    return undefined;
  }

  const firstName = userObject.first_name;
  const lastName = userObject.last_name;

  if (typeof firstName !== 'string' || typeof lastName !== 'string') {
    if (typeof userObject.name !== 'string') {
      return undefined;
    }

    // Split apart name
    const [f, l] = userObject.name.split(' ');

    return f && l ? `${f} ${l.charAt(0)}` : userObject.name;
  }

  let calledName = firstName;

  if (userObject.preferred_first_name && typeof userObject.preferred_first_name === 'string') {
    calledName = userObject.preferred_first_name;
  }

  return `${calledName} ${lastName.charAt(0)}`;
};

import { View } from 'react-native';

import { MaterialIcon } from '@almond/ui';
import { combineUnstableStyles } from '@almond/utils';

import { unstable_styles as cssUnstableStyles } from './ErrorIcon.module.css';

import type { MaterialIconProps } from '@almond/ui';
import type { StyleProp, ViewStyle } from 'react-native';

type ErrorIconProps = {
  color: MaterialIconProps['color'];
  style?: StyleProp<ViewStyle>;
};

export const ErrorIcon = (props: ErrorIconProps) => {
  const { color, style } = props;

  return (
    <View style={combineUnstableStyles(cssUnstableStyles.wrapper, style)}>
      <MaterialIcon source="error" color={color} size={20} />
    </View>
  );
};

/* eslint-disable no-param-reassign */
import { useEffect } from 'react';

const updateHeight = (
  rootEle: HTMLElement,
  isKeyboardVisible: boolean,
  boundingClientRectHeight: number,
  visibleHeight: number
) => {
  if (isKeyboardVisible) {
    rootEle.style.height = `${visibleHeight}px`;
    rootEle.style.minHeight = `${visibleHeight}px`;
    rootEle.style.position = 'relative';
    rootEle.style.top = `${boundingClientRectHeight - visibleHeight}px`;
  } else {
    rootEle.style.height = '100%';
    rootEle.style.minHeight = '100%';
    rootEle.style.position = '';
    rootEle.style.top = '';
  }
};

export const useKeyboardVisible = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const windowVisualViewport = window.visualViewport;
    const rootEle = document.querySelector('#root');

    if (!windowVisualViewport || !(rootEle instanceof HTMLElement)) return;

    const resize = () => {
      const { height: visibleHeight } = windowVisualViewport || {};

      if (!visibleHeight) return;

      const { height: boundingClientRectHeight } = document.body.getBoundingClientRect();

      const isKeyboardVisible = Math.abs(visibleHeight - boundingClientRectHeight) > 100;

      updateHeight(rootEle, isKeyboardVisible, boundingClientRectHeight, visibleHeight);
    };

    windowVisualViewport.addEventListener('resize', resize);

    return () => {
      windowVisualViewport.removeEventListener('resize', resize);
    };
  }, []);
};

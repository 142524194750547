import { MOBILE_WIDTH_THRESHOLD, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  authorName: {
    color: theme.secondaryText,
  },

  sendButton: {
    backgroundColor: theme.primary,
    padding: sizes.SPACING_S,
  },
  sendButtonDisabled: {
    backgroundColor: theme.info,
  },

  editModalBackdrop: {
    backgroundColor: theme.backdrop,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editModal: {
    backgroundColor: theme.background,
    borderRadius: sizes.SPACING_S,
    padding: sizes.SPACING_S,
    maxWidth: MOBILE_WIDTH_THRESHOLD,
    width: '100%',
  },
}));

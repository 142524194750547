import { useEffect } from 'react';

import { WindowMessageReceiver } from '@almond/extension-utils';
import { useEvent } from '@almond/utils';

import type { z } from 'zod';

export function useExtensionMessageListener<T extends z.ZodRawShape>(
  schema: z.ZodObject<T>,
  onMessage: (data: z.infer<z.ZodObject<T>>) => void
): void {
  const listener = useEvent(async (data: unknown) => {
    const parsed = schema.safeParse(data);

    if (parsed.success) {
      return onMessage(parsed.data);
    }
  });

  useEffect(() => {
    if (window.parent === window) {
      // Not in an iframe, so don't listen to events
      return;
    }

    const messageReceiver = new WindowMessageReceiver(window, schema, { fromExtension: true });

    messageReceiver.addListener(listener);

    return () => {
      messageReceiver.removeListener();
    };
  }, [listener, schema]);
}

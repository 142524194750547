import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalSearchParams, useRouter, useSegments } from 'expo-router';

import { isAdmin } from '../services';

import type { UserDetail } from '@almond/api-types';

export const useForceRouteGroup = (data?: UserDetail) => {
  const router = useRouter();
  const { isAuthenticated, isLoading } = useAuth0();
  const segments = useSegments();
  const currentGroup = segments[0];
  const searchParams = useGlobalSearchParams<Record<string, any>>();

  useEffect(() => {
    if (!data) return;

    const userRole = isAdmin(data) ? 'admin' : 'patient';

    if (!currentGroup?.includes(userRole)) {
      // Pull out screen and params from params to restore. Not sure how
      // they're being set (params is the string '[object Object]'), but
      // we need to strip them out.
      const { screen, params, ...validSearchParams } = searchParams;

      // Trailing slash is important for params to get set correctly
      if (userRole === 'patient') {
        router.replace({ pathname: `/(patient)/`, params: validSearchParams });
      } else {
        router.replace({ pathname: `/(admin)/(conversations)/`, params: validSearchParams });
      }
    }
  }, [currentGroup, data, isAuthenticated, isLoading, router, searchParams]);
};

import React from 'react';

import { useLogout } from '~/modules/api';
import { SidebarButton } from '~/modules/ui';

import type { StyleProp, ViewStyle } from 'react-native';

export type LogoutButtonProps = {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
};

export const LogoutButton: React.FC<LogoutButtonProps> = props => {
  const logout = useLogout();

  const handlePress = () => {
    props.onPress();
    logout();
  };

  return (
    <SidebarButton testID="UserMenu-SignOut" onPress={handlePress} type="accent" style={props.style}>
      {'Sign Out'}
    </SidebarButton>
  );
};

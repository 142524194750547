import { useAuth0 } from '@auth0/auth0-react';
import useSWRMutation from 'swr/mutation';

import { env } from '~env';

import { printJestWarnings, processResponse, replacePathParams } from '../utils';
import { useGetHeaders } from './useGetHeaders';
import { useLogout } from './useLogout';

import type { AddPrefix, StripPrefix } from '../types';
import type {
  ApiRestEndpoint,
  Json,
  MimeTypes,
  paths,
  RequestBody,
  RequestPathParams,
  SuccessResponse,
} from '@almond/api-types';
import type { SWRMutationConfiguration, SWRMutationResponse } from 'swr/mutation';

type HttpMethod = 'post' | 'put' | 'patch' | 'delete';
type MutationOptionsArg<Path extends StripPrefix<keyof paths>, Method extends HttpMethod, MimeType extends MimeTypes> =
  | RequestBody<ApiRestEndpoint<AddPrefix<Path>, Method>, MimeType>
  | RequestPathParams<ApiRestEndpoint<AddPrefix<Path>, Method>>;

type MutationOptions<Path extends StripPrefix<keyof paths>, Method extends HttpMethod, MimeType extends MimeTypes> = {
  arg: MutationOptionsArg<Path, Method, MimeType>;
};

/**
 * Custom hook to call writable Almond APIs.
 * @param method API method to call
 * @param path API path to call
 * @param options SWR options
 * @returns SWRMutation Return object
 */
export const useAlmondApiMutation = <
  Path extends StripPrefix<keyof paths>,
  Method extends HttpMethod,
  MimeType extends MimeTypes = Json,
>(
  method: Method,
  path: Path | null,
  options: SWRMutationConfiguration<SuccessResponse<ApiRestEndpoint<AddPrefix<Path>, Method>>, any, [Method, Path]> = {}
): SWRMutationResponse<
  SuccessResponse<ApiRestEndpoint<AddPrefix<Path>, Method>>,
  any,
  [Method, Path],
  MutationOptionsArg<Path, Method, MimeType>
> => {
  const { isLoading, isAuthenticated } = useAuth0();
  const getHeaders = useGetHeaders();
  const logout = useLogout();
  const sendRequest = async (
    [thisMethod, url]: [Method, Path],
    mutationOptions: MutationOptions<Path, Method, MimeType>
  ) => {
    let fullUrl = `${env.API_BASE_URL}/api/v2${url}`;
    const { arg } = mutationOptions;
    const headers = await getHeaders(arg instanceof FormData ? undefined : 'application/json');
    const duplicateArg = arg instanceof FormData ? arg : { ...Object(arg) };

    if (duplicateArg) {
      fullUrl = replacePathParams(fullUrl, duplicateArg);
    }

    const res = await fetch(fullUrl, {
      headers,
      method: thisMethod,
      body: arg instanceof FormData ? arg : JSON.stringify(duplicateArg),
    });

    if (res.status === 401) {
      return logout();
    }

    return processResponse(res);
  };

  printJestWarnings(isLoading, isAuthenticated);

  return useSWRMutation(isLoading || !isAuthenticated || !path ? null : [method, path], sendRequest, options);
};

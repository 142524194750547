import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    borderRadius: sizes.SPACING_S,
    borderStyle: 'dashed',
    borderColor: theme.secondaryTextDark,
    backgroundColor: theme.lightPrimary,
    cursor: 'pointer',

    padding: sizes.SPACING_M,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    transition: 'box-shadow 100ms ease-in-out',
  },
  text: {
    textAlign: 'center',
    color: theme.secondaryTextDark,
  },
  underline: {
    textDecorationLine: 'underline',
  },
  containerActive: {
    ...getShadow(true),
  },
  containerReject: {
    backgroundColor: theme.lightError,
  },
}));

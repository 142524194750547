import { StyleSheet } from 'react-native';

import { getColorWithOpacity, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  modalContainer: {
    maxWidth: 800,
    minHeight: 620,
  },

  modalContainerMobile: {
    width: '100%',
    minHeight: '100%',
  },

  modalContentContainer: {
    justifyContent: 'center',
  },

  container: {
    width: '100%',
    height: '100%',
    marginTop: 56,
  },

  contentContainer: {
    flex: 1,
    justifyContent: 'center',
  },

  textInput: {
    height: 54,
    shadowOffset: { width: 0, height: 0 },
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: getColorWithOpacity(theme.primary, 0.5),
  },

  textInputContainer: {
    flex: 1,
  },

  row: {
    flexDirection: 'row',
    columnGap: sizes.SPACING_M,
  },

  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: sizes.SPACING_M,
    columnGap: sizes.SPACING_M,
    marginTop: 56,
  },

  button: {
    width: 226,
    height: sizes.SPACING_XXL,
  },

  error: {
    textAlign: 'center',
    color: theme.error,
    marginTop: sizes.SPACING_S,
  },
}));

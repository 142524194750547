import { StyleSheet } from 'react-native';

import { getColorWithOpacity, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    flexDirection: 'row',
    columnGap: sizes.SPACING_M,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: sizes.SPACING_M,
  },

  icon: {
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: theme.lightAccent,
    alignItems: 'center',
    justifyContent: 'center',
  },

  title: {
    flex: 1,
  },

  separator: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: getColorWithOpacity(theme.border, 0.3),
  },
}));

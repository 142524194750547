import { useCallback, useState } from 'react';

import { useChatContext } from 'stream-chat-react';

import { getChannelPatient } from '~/modules/messaging';
import { Error as ErrorUi } from '~/modules/ui';

import { useGetTodos, useMarkTodos, useTodoUpsert } from '../../hooks';
import { LiveTodoItem } from '../LiveTodoItem';
import { TodoDeleteConfirmation } from '../TodoDeleteConfirmation';
import { TodoFormModal } from '../TodoFormModal';
import { LoadingTodoList, TodoList } from '../TodoList';

import type { TodoDetailOut } from '@almond/api-types';

export const AdminTodoPage = () => {
  const { channel } = useChatContext();
  const patientUuid = getChannelPatient(channel)?.patient_uuid as string | undefined;
  const { active, archived, error, isLoading, modifyCache } = useGetTodos({ patientUuid });
  const [formModel, setFormModel] = useState<Partial<TodoDetailOut> | null>(null);
  const [deleteModal, setDeleteModal] = useState<TodoDetailOut | null>(null);
  const { markItem: markItemComplete } = useMarkTodos(patientUuid, 'complete');
  const { markItem: markItemArchive } = useMarkTodos(patientUuid, 'archive');
  const todoUpsert = useTodoUpsert(patientUuid, formModel);

  const onFormSubmitted = useCallback(
    (newTodo?: TodoDetailOut) => {
      setFormModel(null);

      if (!newTodo) {
        return;
      }

      modifyCache(formModel?.uuid ? 'edit' : 'create', newTodo);
    },
    [formModel?.uuid, modifyCache]
  );

  const onDeleteConfirm = useCallback(
    (todoToDelete?: TodoDetailOut) => {
      setDeleteModal(null);

      if (!todoToDelete) {
        return;
      }

      modifyCache('delete', todoToDelete);
    },
    [modifyCache]
  );

  if (error) {
    return (
      <ErrorUi
        error={
          new Error(
            // eslint-disable-next-line max-len
            `There was an error fetching the to do list. Let us know in #bugs`,
            { cause: error }
          )
        }
      />
    );
  }

  let content: React.JSX.Element;

  if (isLoading) {
    content = <LoadingTodoList />;
  } else {
    content = (
      <>
        <TodoList
          todos={active}
          archivedTodos={archived}
          showAllCategories
          onClickAddTodo={category => {
            setFormModel({ category });
          }}
          renderItem={({ item, ...props }) => (
            <LiveTodoItem
              {...props}
              item={item}
              onComplete={async isCompleted => {
                const result = await markItemComplete(item, isCompleted);

                if (result) {
                  modifyCache('archiveStatus', result);
                }
              }}
              ctaButtons={[
                {
                  onPress: async () => {
                    const result = await markItemArchive(item, !item.archivedAt);

                    if (result) {
                      modifyCache('archiveStatus', result);
                    }
                  },
                  label: item.archivedAt ? 'Unarchive' : 'Archive',
                },
                {
                  onPress: () => setFormModel(item),
                  label: 'Edit',
                },
                {
                  onPress: () => setDeleteModal(item),
                  label: 'Delete',
                },
              ]}
            />
          )}
        />
        <TodoFormModal isVisible={!!formModel} onRequestClose={onFormSubmitted} model={formModel} {...todoUpsert} />
        <TodoDeleteConfirmation
          isVisible={!!deleteModal}
          patientUuid={patientUuid}
          onRequestClose={onDeleteConfirm}
          item={deleteModal}
        />
      </>
    );
  }

  return content;
};
